import React from "react";

import {
    Badge
} from "reactstrap";
import {connect} from "react-redux";
// import Rating from "react-rating";
import axios from "../../../axios";


class TableRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayMatches: false,
            isFavourite: this.props.item.is_favorite
        };
    }

    handleFavouriteClicked = () => {
        let url = this.props.isService ? "/favorites/service/" + this.props.item.id + "/" : "/favorites/help-request/" + this.props.item.id + "/";
        this.setState({isFavourite: !this.state.isFavourite})
        axios
            .post(url)
            .then(resp => {
                console.log(resp);
            })
    };

    render() {
        // console.log(this.props.item);
        return (
            <tr key={this.props.item.id}>
                {!this.props.isUserService ?
                <td className="text-center clickable" onClick={this.handleFavouriteClicked}>
                    <i className={this.state.isFavourite ? "fa fa-heart active pl-2" : "fa fa-heart pl-2"}/>
                </td> : null }
                <td className="table-user clickable"
                    onClick={this.props.isUserService ? () => this.props.editServiceHandler(this.props.item.id) : () => this.props.toggleModal("serviceModal", this.props.item)}>
                    <div className={this.props.isUserService ? "pl-3" : null}>
                        <img
                            alt="..."
                            className={this.props.isUserService ? "avatar rounded-circle" : "avatar rounded-circle blurred-image"}
                            src={this.props.item.avatar}
                        />
                    </div>
                    {/*<div>*/}
                    {/*    <Rating*/}
                    {/*        onClick={this.handleRatingSelected}*/}
                    {/*        emptySymbol="fa fa-star btn-hopsti-pink-outline"*/}
                    {/*        fullSymbol="fa fa-star hopsti-pink"/>*/}
                    {/*</div>*/}
                </td>
                <td className="d-none d-md-table-cell clickable"
                    onClick={this.props.isUserService ? () => this.props.editServiceHandler(this.props.item.id) : () => this.props.toggleModal("serviceModal", this.props.item)}>
                    <span className="text-muted">{this.props.item.user.first_name}</span>
                </td>
                <td className="d-none d-xl-table-cell clickable"
                    onClick={this.props.isUserService ? () => this.props.editServiceHandler(this.props.item.id) : () => this.props.toggleModal("serviceModal", this.props.item)}>
                    <span className="text-muted">{this.props.item.created_at}</span>
                </td>
                <td className="clickable"
                    onClick={this.props.isUserService ? () => this.props.editServiceHandler(this.props.item.id) : () => this.props.toggleModal("serviceModal", this.props.item)}>
                    <span className="text-muted service-name">
                        {this.props.isService ? this.props.item.name : this.props.item.title}
                    </span>
                </td>
                <td className="col-service-info d-none d-lg-table-cell clickable"
                    onClick={this.props.isUserService ? () => this.props.editServiceHandler(this.props.item.id) : () => this.props.toggleModal("serviceModal", this.props.item)}>
                    <span className="text-muted">
                        {this.props.isService ? this.props.item.description_ee : this.props.item.info}
                    </span>
                </td>
                <td className="clickable" onClick={() => this.props.toggleModal("serviceModal", this.props.item)}>
                    {!this.props.isPublicView ?
                        <span className="text-muted">
                            {this.props.isService ? this.props.item.cost + "€/" + this.props.item.unit.unit : this.props.item.quantity + " " + this.props.item.unit.unit}
                        </span> : <span className="text-muted">
                            Logi sisse et näha hinda
                                </span>}
                </td>

                {!this.props.isUserService && !this.props.isPublicView ?
                    <td className="clickable"
                        onClick={this.props.isUserService ? () => this.props.editServiceHandler(this.props.item.id) : () => this.props.toggleModal("serviceModal", this.props.item)}>
                        <span className="text-muted">
                            {this.props.item.google_response.distance ? this.props.item.google_response.distance.distance.text : null}
                        </span>
                    </td> : null}
                {this.props.isOffer ?
                    <td className="clickable"
                        onClick={this.props.isUserService ? () => this.props.editServiceHandler(this.props.item.id) : () => this.props.toggleModal("serviceModal", this.props.item)}>
                        <span className="hopsti-pink">
                            {this.props.item.offer ? this.props.item.offer.status : "esitamata"}
                        </span>
                    </td> : null}
                <td className="table-actions d-none d-md-table-cell">
                    <div className="d-flex align-items-center">
                        {this.props.user && this.props.item && this.props.item.created_by === this.props.user.pk ?
                            <>
                                <i onClick={() => this.props.editServiceHandler(this.props.item.id)}
                                   className="fa fa-2x fa-edit hopsti-green pl-2 clickable" id="tooltip1"
                                   title="Muuda"
                                   aria-hidden="true"/>
                                <i onClick={() => this.props.removeHandler(this.props.item.id)}
                                   className="fa fa-2x fa-trash-alt hopsti-pink pl-2 clickable"
                                   id="tooltip2"
                                   title="Kustuta"
                                   aria-hidden="true"/>
                            </> :
                            <>
                                <i id="tooltip3" className="fa fa-2x fa-search hopsti-pink pl-2 pr-2 clickable"
                                   onClick={() => this.props.toggleModal("serviceModal", this.props.item)}
                                   title="Vaata detailvaadet"
                                   aria-hidden="true"/>
                                {/*{!this.props.disableContacts ?*/}
                                {/*    <>*/}
                                {/*        <a className="text-sm hopsti-pink" id="tooltip598568751"*/}
                                {/*           onClick={this.showButtons}*/}
                                {/*           href={`callto:${this.props.item.contacts ? this.props.item.contacts.phones : null}`}>*/}
                                {/*            <i className="fa fa-2x fa-phone-square hopsti-green pl-2 pr-2 clickable"*/}
                                {/*               aria-hidden="true"/>*/}
                                {/*        </a>*/}
                                {/*        <a className="text-sm hopsti-pink" target="_blank" rel="noopener noreferrer" id="tooltip4" onClick={this.showButtons}*/}
                                {/*           href={`mailto:${this.props.item.contacts ? this.props.item.contacts.emails : null}`}>*/}
                                {/*            <i className="fa fa-2x fa-envelope hopsti-green pl-2 pr-2 clickable"*/}
                                {/*               aria-hidden="true"/>*/}
                                {/*        </a>*/}
                                {/*    </> : null}*/}
                            </>
                        }
                        {this.props.item ?
                            <>
                                {this.props.item.matches_count ?
                                    <Badge onClick={() => this.props.handleMatchClick(this.props.item.id)}
                                           className="badge badge-md pill btn-hopsti-pink clickable ml-2">{this.state.displayMatches ? <>Peida</> : <>{this.props.item.matches_count} kinnitamata
                                        tellimust</>}</Badge> : null}
                            </>
                            : null}
                    </div>
                </td>
            </tr>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        user: state.auth.user
    }
};

export default connect(mapStateToProps)(TableRow);