import React from "react";
import HelpRequestItem from "../HelpRequestItem/HelpRequestItem";
import { connect } from "react-redux";

class EditHelpRequest extends React.Component {

    render() {
        return (
            <>
                <HelpRequestItem
                    {...this.props}
                    userId={this.props.user.pk}
                    isKovSession={this.props.isKovSession}
                    userIdFromKov={this.props.userIdFromKov} />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(EditHelpRequest);