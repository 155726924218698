import React from 'react'
import {
    LineChart, Line, XAxis, YAxis, Tooltip, Legend,
} from 'recharts';
import {Row, Col, FormGroup, Input, Container, InputGroup} from "reactstrap";
import classnames from "classnames";
import * as services from "../../../../utils/services";
import Select2 from "react-select2-wrapper";
import ReactDatetime from "react-datetime";
import axios from "../../../../axios";
import moment from "moment";

let dateTo = new Date();
let dateFrom = new Date();

class Statistics extends React.Component {
    state = {
        data: [],
        focusedServiceGroups: false,
        serviceGroups: [],
        serviceSubGroups: [],
        serviceGroup: 0,
        serviceSubGroup: 0,
        isAutomaticOffer: false,
        showJobs: false,
        dateTo: dateTo, // moment().format('YYYY-MM-DD'),
        dateFrom: dateFrom.setDate(dateFrom.getDate() - 30) // moment().subtract(30,'d').format('YYYY-MM-DD')
    };

    componentDidMount() {
        this.getStatistics(
            this.state.dateFrom,
            this.state.dateTo,
            this.state.serviceGroup,
            this.state.serviceSubGroup,
            true,
            true,
            this.state.isAutomaticOffer,
            this.state.showJobs
        ).then((response) => {
            // console.log(response);
            this.setState({data: response});
        });
        this.getServiceGroupsWithSubGroups();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.getServiceGroupsWithSubGroups();
        }

        if (this.state.dateFrom !== prevState.dateFrom ||
            this.state.dateTo !== prevState.dateTo ||
            this.state.serviceGroup !== prevState.serviceGroup ||
            this.state.serviceSubGroup !== prevState.serviceSubGroup ||
            this.state.isAutomaticOffer !== prevState.isAutomaticOffer ||
            this.state.showJobs !== prevState.showJobs
        ) {
            this.getStatistics(
                this.state.dateFrom,
                this.state.dateTo,
                this.state.serviceGroup,
                this.state.serviceSubGroup,
                true,
                true,
                this.state.isAutomaticOffer,
                this.state.showJobs
            ).then((response) => {
                console.log(response);
                this.setState({data: response});
            });

        }
    }

    getStatistics = async (fromDate = null, toDate = null, topId = 0, subId = 0, showHelpRequests = true, showServices = true, showAllOffers = false, showAgreedOffers = false) => {
        return axios
            .get(`/kov-statistics/${moment(fromDate).format('YYYY-MM-DD')}/${moment(toDate).format('YYYY-MM-DD')}/${topId}/${subId}/${showHelpRequests}/${showServices}/${showAllOffers}/${showAgreedOffers}/`)
            .then(response => {
                if (response.data) {
                    return response.data
                } else return []
            })
            .catch(err => {
                console.log("getAllServiceGroups error: ", err);
            });
    };

    //  serviceSubGroup: serviceSubGroups[0].id, serviceGroup: serviceGroups[0].id
    getServiceGroupsWithSubGroups = () => {
        services.getAllServiceGroupsForStatistics().then(serviceGroups => {
            // console.log(serviceGroups);
            // serviceGroups = [{id: 0, name: "Kõik kategooriad", is_valid: true, icon: ""}].concat(serviceGroups);
            this.setState({serviceGroups});
            services.getServiceSubGroupsForStatistics(serviceGroups[0].id).then(serviceSubGroups => {
                    // serviceSubGroups = [{id: 0, name: "Kõik alamkategooriad"}].concat(serviceSubGroups);
                    this.setState({
                        serviceSubGroups,
                    });
                }
            );
        });
    };

    handleReactDatetimeChange = (who, date) => {
        if (
            this.state.startDate &&
            who === "endDate" &&
            new Date(this.state.startDate._d + "") > new Date(date._d + "")
        ) {
            this.setState({
                startDate: date,
                endDate: date
            });
        } else if (
            this.state.endDate &&
            who === "startDate" &&
            new Date(this.state.endDate._d + "") < new Date(date._d + "")
        ) {
            this.setState({
                startDate: date,
                endDate: date
            });
        } else {
            this.setState({
                [who]: date
            });
        }
    };

    getClassNameReactDatetimeDays = date => {
        if (
            this.state.dateFrom &&
            this.state.dateTo &&
            this.state.dateFrom._d + "" !== this.state.dateTo._d + ""
        ) {
            if (
                new Date(this.state.dateTo._d + "") > new Date(date._d + "") &&
                new Date(this.state.dateFrom._d + "") < new Date(date._d + "")
            ) {
                return " middle-date";
            }
            if (this.state.dateTo._d + "" === date._d + "") {
                return " end-date";
            }
            if (this.state.dateFrom._d + "" === date._d + "") {
                return " start-date";
            }
        }
        return "";
    };

    handleChange = async event => {
        const {target} = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const {name} = target;
        await this.setState({
            [name]: value
        });
    };

    render() {
        return (
            <Container className="mt-5 mb-4">
                <Row className="pt-4">
                    <Col className="col-12 col-sm-4">
                        <Row>
                            <Col className="col-12">
                                <label className=" form-control-label hopsti-green">
                                    Vali ajaperiood
                                </label>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <ReactDatetime
                                        inputProps={{
                                            placeholder: "Alates"
                                        }}
                                        value={moment(this.state.dateFrom).format("DD.MM.YYYY")}
                                        timeFormat={false}
                                        onChange={e =>
                                            this.handleReactDatetimeChange("dateFrom", e)
                                        }
                                        renderDay={(props, currentDate, selectedDate) => {
                                            let classes = props.className;
                                            classes += this.getClassNameReactDatetimeDays(
                                                currentDate
                                            );
                                            return (
                                                <td {...props} className={classes}>
                                                    {currentDate.date()}
                                                </td>
                                            );
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <ReactDatetime
                                        inputProps={{
                                            placeholder: "Kuni"
                                        }}
                                        value={moment(this.state.dateTo).format("DD.MM.YYYY")}
                                        timeFormat={false}
                                        onChange={e =>
                                            this.handleReactDatetimeChange("dateTo", e)
                                        }
                                        renderDay={(props, currentDate, selectedDate) => {
                                            let classes = props.className;
                                            classes += this.getClassNameReactDatetimeDays(
                                                currentDate
                                            );
                                            return (
                                                <td {...props} className={classes}>
                                                    {currentDate.date()}
                                                </td>
                                            );
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col className="col-12">
                                <FormGroup className={classnames({focused: this.state.focusedServiceGroups})}>
                                    <label className="form-control-label hopsti-green">Kategooria</label>
                                    <Input
                                        bsSize="md"
                                        id="exampleFormControlSelect1"
                                        type="select"
                                        onChange={e => {
                                            this.setState({
                                                serviceGroup: e.target.value
                                            });
                                            services.getServiceSubGroupsForStatistics(e.target.value).then(serviceSubGroups => {
                                                this.setState({
                                                    serviceSubGroups, serviceSubGroup: serviceSubGroups[0].id
                                                });
                                            });
                                        }}
                                    >
                                        {this.state.serviceGroups.map(serviceGroup => {
                                            return (
                                                <option
                                                    key={serviceGroup.id}
                                                    value={serviceGroup.id}
                                                >
                                                    {serviceGroup.name}
                                                </option>
                                            );
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>

                            {/* Teenuse alamkategooria */}
                            <Col className="col-12">
                                <FormGroup className={classnames({focused: this.state.focusedServiceGroups})}>
                                    <label className="form-control-label hopsti-green">Alamkategooria</label>
                                    <Select2
                                        value={this.state.serviceSubGroup}
                                        className={"form-control form-control-md"}
                                        defaultValue="1"
                                        data={
                                            this.state.serviceSubGroups.map(
                                                serviceSubGroup => {
                                                    return {
                                                        id: serviceSubGroup.id,
                                                        text: serviceSubGroup.name
                                                    };
                                                }
                                            )
                                        }
                                        onSelect={e => {
                                            this.setState({
                                                serviceSubGroup: e.target.value
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col className="col-12">
                                <FormGroup
                                    className={classnames({
                                        focused: this.state.focusedAutomaticOffer
                                    })}
                                >
                                    <InputGroup className="input-group-merge">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                id="customCheck1"
                                                type="checkbox"
                                                name="isAutomaticOffer"
                                                value={this.state.isAutomaticOffer}
                                                onChange={e => {
                                                    this.handleChange(e);
                                                }}
                                            />
                                            <label
                                                className="custom-control-label hopsti-green font-weight-600"
                                                htmlFor="customCheck1"
                                            >
                                                Näita vastastikuseid pakkumisi
                                            </label>
                                        </div>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col className="col-12">
                                <FormGroup
                                    className={classnames({
                                        focused: this.state.focusedAutomaticOffer
                                    })}
                                >
                                    <InputGroup className="input-group-merge">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                id="customCheck2"
                                                type="checkbox"
                                                name="showJobs"
                                                value={this.state.showJobs}
                                                onChange={e => {
                                                    this.handleChange(e);
                                                }}
                                            />
                                            <label
                                                className="custom-control-label hopsti-green font-weight-600"
                                                htmlFor="customCheck2"
                                            >
                                                Näita kokkulepitud töid
                                            </label>
                                        </div>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-12 col-sm-8">
                        <LineChart width={700} height={400} data={this.state.data}
                                   margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                            <XAxis dataKey="date" interval="preserveStartEnd"/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend/>
                            <Line name="Abihõiked" type="monotone" dataKey="help_requests" stroke="#8884d8"
                                  activeDot={{r: 8}}/>
                            <Line name="Teenused" type="monotone" dataKey="services" stroke="#82ca9d"/>
                            <Line name="Kokkulepitud tööd" type="monotone" dataKey="agreement_offers" stroke="red"/>
                            <Line name="Vastastikused pakkumised" type="monotone" dataKey="all_offers" stroke="orange"/>
                        </LineChart>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Statistics;