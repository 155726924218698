import React from "react";
import classnames from "classnames";
import axios from "../../axios";
import NotificationAlert from "react-notification-alert";

import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroup,
    Row,
    Col,
} from "reactstrap";
import * as helpers from "../../utils/helpers";

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonDisabled: false,
            name: "",
            email: "",
            phone: "",
            organisation: "",
            message: "",
        };
        this.handleChange = this.handleChange.bind(this);
        console.log("hello")
    }

    handleChange = async event => {
        const { target } = event;
        const value = target.value;
        const { name } = target;
        await this.setState({ [name]: value });
    };

    handleSubmit = e => {
        e.preventDefault();

        this.setState({ buttonDisabled: true });

        axios
            .post("/send-contact-email/", {
                name: this.state.name,
                contact_email: this.state.email,
                phone: this.state.phone,
                organisation: this.state.organisation,
                message: this.state.message,
            }, {
                headers: {
                    'Authorization': null
                }
            })
            .then(() => {
                helpers.notify(
                    "success",
                    "Aitäh tagasiside eest!",
                    "",
                    this
                );

                this.setState({
                    name: "",
                    email: "",
                    phone: "",
                    organisation: "",
                    message: "",
                    buttonDisabled: false
                })
            })
            .catch(err => {
                console.log(err)
            });
    };

    render() {
        return (
            <>
                <Row className="justify-content-center">
                    <div className="rna-wrapper contactAlert">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Col className="col-10 col-md-6 col-lg-5 mt-4 mb-4">
                        <Card className="bg-secondary border-0 mb-0">
                            <CardBody className="px-lg-5 py-lg-5">
                                <Row>
                                    <Col md="12">
                                        <div>
                                            <h2 className="hopsti-green">Kontaktid</h2>
                                            <p className="text-sm">Kui sul on
                                                küsimusi, mõtteid või tagasisidet, siis anna meile sellest märku!
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Form role="form">
                                    <Row>
                                        <Col md="12">
                                            <label className="form-control-label small hopsti-green">Nimi</label>
                                            <FormGroup className={classnames({ focused: this.state.name })}>
                                                <InputGroup className="mb-3">
                                                    <Input
                                                        bsSize="sm"
                                                        placeholder=""
                                                        type="text"
                                                        name="name"
                                                        value={this.state.name}
                                                        onFocus={() => this.setState({ focusedName: true })}
                                                        onBlur={() => this.setState({ focusedName: false })}
                                                        onChange={e => {
                                                            this.handleChange(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <label className="form-control-label small hopsti-green">E-mail</label>
                                            <FormGroup className={classnames({ focused: this.state.email })}>
                                                <InputGroup className="mb-3">
                                                    <Input
                                                        bsSize="sm"
                                                        placeholder=""
                                                        type="text"
                                                        name="email"
                                                        value={this.state.email}
                                                        onFocus={() => this.setState({ focusedEmail: true })}
                                                        onBlur={() => this.setState({ focusedEmail: false })}
                                                        onChange={e => {
                                                            this.handleChange(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <label className="form-control-label small hopsti-green">Telefon</label>
                                            <FormGroup className={classnames({ focused: this.state.phone })}>
                                                <InputGroup className="mb-3">
                                                    <Input
                                                        bsSize="sm"
                                                        placeholder=""
                                                        type="text"
                                                        name="phone"
                                                        value={this.state.phone}
                                                        onFocus={() => this.setState({ focusedPhone: true })}
                                                        onBlur={() => this.setState({ focusedPhone: false })}
                                                        onChange={e => {
                                                            this.handleChange(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <label
                                                className="form-control-label small hopsti-green">Organisatsioon</label>
                                            <FormGroup className={classnames({ focused: this.state.organisation })}>
                                                <InputGroup className="mb-3">
                                                    <Input
                                                        bsSize="sm"
                                                        placeholder=""
                                                        type="text"
                                                        name="organisation"
                                                        value={this.state.organisation}
                                                        onFocus={() => this.setState({ focusedOrganisation: true })}
                                                        onBlur={() => this.setState({ focusedOrganisation: false })}
                                                        onChange={e => {
                                                            this.handleChange(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <label className="form-control-label small hopsti-green">Sõnum</label>
                                            <FormGroup className={classnames({ focused: this.state.message })}>
                                                <InputGroup className="mb-3">
                                                    <Input
                                                        bsSize="sm"
                                                        placeholder=""
                                                        type="textarea"
                                                        name="message"
                                                        value={this.state.message}
                                                        onFocus={() => this.setState({ focusedMessage: true })}
                                                        onBlur={() => this.setState({ focusedMessage: false })}
                                                        onChange={e => {
                                                            this.handleChange(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12 d-flex justify-content-center">
                                            <Button className="btn-hopsti-green"
                                                onClick={this.handleSubmit}>Saada</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default ContactForm;
