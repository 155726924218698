import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    address: null,
};

const setAddress = (state, action) => {
    return updateObject(state, {
        address: action.address
    })
};

const location = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ADDRESS:
            return setAddress(state, action);
        default:
            return state;
    }
};

export default location;