import React from "react";

export const renderAvatar = (cell) => {
    return (
        <img
            alt="..."
            className="avatar rounded-circle" // : "avatar rounded-circle blurred-image"}
            src={cell}
        />
    );
};

export const renderFavorite = (cell) => {
    return (
        <i className={cell ? "fa fa-heart active pl-2" : "fa fa-heart pl-2"}/>
    );
};

export const sortCaret = (order) => {
    if (!order) return (
        <span>&nbsp;<i className="fas fa-caret-down"/>/<i className="fas fa-caret-up"/></span>);
    else if (order === 'asc') return (<span>&nbsp;<i className="fas fa-caret-up"/></span>);
    else if (order === 'desc') return (<span>&nbsp;<i className="fas fa-caret-down"/></span>);
    return null;
};

export const NoDataIndication = (isLoading) => (
    <div className="spinner pt-4 pb-4">
        {isLoading ?
            <></>
            :
            <span className="hopsti-pink">Kirjed puuduvad</span>
        }
    </div>
);