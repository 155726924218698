import React from "react";
import {
    Button,
    Row,
    Col,
    ListGroup,
    ListGroupItem
} from "reactstrap";

class ImageUploader extends React.Component {
    handleNewImage = e => {
        if (e.target.files[0] !== undefined) {
            let fileUploaded = e.target.files[0];
            let newFile = {
                file: fileUploaded,
                name: fileUploaded.name,
                preView: URL.createObjectURL(e.target.files[0])
            };
            this.props.handleFileAdded(newFile, fileUploaded.size);
        }
    };

    handleDelete = (e, item) => {
        this.props.handleFileDeleted(item.id)
    };

    render() {
        return (
            <>
                <div className="custom-file">
                    <input
                        className="custom-file-input"
                        id="customFileLang"
                        lang="en"
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        name="newImage"
                        onChange={this.handleNewImage}
                    />
                    <label className="custom-file-label" htmlFor="customFileLang">
                        Vali pildi fail:
                    </label>
                </div>
                <ListGroup
                    className=""
                    flush
                >
                    {this.props.files.map(item => {
                        return (
                            <ListGroupItem className="image-list-group-item" key={item.id}>
                                <Row className="d-flex align-items-center">
                                    <Col className="col-auto image-column">
                                        <img
                                            alt="..."
                                            className="image-item"
                                            src={item.preView}
                                        />
                                    </Col>
                                    <Col className="col-auto ml-auto">
                                        <Button size="sm" color="danger" onClick={(e) => this.handleDelete(e, item)}>
                                            <i className="fas fa-trash"/>
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroupItem>)
                    })}
                </ListGroup>
            </>
        );
    }
}

export default ImageUploader;
