import React from "react";
import classnames from "classnames";
import ReactGA from 'react-ga';
import {
    Alert,
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.jsx";
import {connect} from "react-redux";
import * as actions from "../../store/actions/auth";
import axios from "../../axios";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import Parser from 'html-react-parser';

class HopstiRegisterUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSuccessMessage: false,
            showErrorMessage: false,
            showErrorOnTermsAndConditions: false,
            email: "",
            termsAndConditions: false,
            termsAndConditionsModal: false,
            isServiceProvider: this.props.isServiceProvider,
            validate: {
                emailState: ""
            },
            content: null
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize('UA-150997639-1');
        ReactGA.pageview('/register');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.token) {
            this.props.history.push("/")
        }
    }

    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const {validate} = this.state;
        if (emailRex.test(e.target.value)) {
            validate.emailState = "has-success";
        } else {
            validate.emailState = "has-danger";
        }
        this.setState({validate});
    }

    responseGoogleSuccess = async (response) => {
        await this.props.onGoogleAuth(response);
    };

    responseGoogleFailure = async (response) => {
        this.setState({
            showLoginError: true
        });
    };

    handleChange = async event => {
        const {target} = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const {name} = target;
        await this.setState({
            [name]: value
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        if (!this.state.termsAndConditions) {
            return this.setState({showErrorOnTermsAndConditions: true})
        } else {
            this.setState({showErrorOnTermsAndConditions: false})
        }

        const data = {
            email: this.state.email,
            is_service_provider: this.state.isServiceProvider,
            permissions: {terms_and_conditions: this.state.termsAndConditions}
        };

        if (this.state.isServiceProvider) {
            ReactGA.ga('send', {
                hitType: 'event',
                eventCategory: 'Registration',
                eventAction: 'registerStep1PK_OK',
                eventLabel: 'Submit registration form'
            });
        } else {
            ReactGA.ga('send', {
                hitType: 'event',
                eventCategory: 'Registration',
                eventAction: 'registerStep1AV_OK',
                eventLabel: 'Submit registration form'
            });
        }


        axios
            .post("/create-user/", data, {
                headers: {
                    'Authorization': null
                }
            })
            .then(response => {
                this.setState({
                    showSuccessMessage: true,
                    showErrorMessage: false
                });
            })
            .catch(err => {
                console.log("HopstiRegisterUser.js - " + err);
                this.setState({
                    showErrorMessage: true,
                    showSuccessMessage: false
                });
            });
        // this.props.onSignup(this.state.email)
    };

    responseFacebook = (response) => {
        this.props.onFacebookAuth(response);
        // TODO redirect to dashboard
        // this.props.history.push("/")
    };

    responseGoogle = (response) => {
        this.props.onGoogleAuth(response);
        // TODO redirect to dashboard
        // this.props.history.push("/")
    };

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    getPageContent = () => {
        axios
            .get("flatpages/privacy/", {
                headers: {
                    'Authorization': null
                }
            })
            .then(response => {
                if (response.data) {
                    console.log(response.data);
                    this.setState({
                        content: response.data.content
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    render() {
        let successMessage = null;
        let errorMessage = null;
        let termsAndConditionsMessage = null;

        if (this.state.showSuccessMessage) {
            successMessage = (
                <Alert color="success">
                    <strong>Super!</strong> Edasine info registreerimise kohta saadeti
                    meilile! Võid selle vahelehe sulgeda.
                </Alert>
            );
        }

        if (this.state.showErrorMessage) {
            errorMessage = (
                <Alert color="danger">
                    <strong>Viga!</strong> Sama email on juba meie süsteemis
                    registreeritud!
                </Alert>
            );
        }

        if (this.state.showErrorOnTermsAndConditions) {
            termsAndConditionsMessage = (
                <Alert color="danger">
                    <strong>Viga!</strong> Registreerimiseks pead sa tingimustega nõustuma.
                </Alert>
            );
        }
        let leadText = "";
        if (this.state.isServiceProvider) {
            leadText = "Registreeru teenusepakkujaks.";
        } else {
            leadText = "Registreeru abivajajaks.";
        }

        return (
            <>
                <AuthHeader title="Hei hopsti!" lead={leadText}/>
                <Container className="pb-5">
                    <Row className="justify-content-center">
                        <Col md="12">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-2">
                                    {successMessage}
                                    {errorMessage}
                                    {termsAndConditionsMessage}
                                    <Form role="form" onSubmit={this.handleSubmit}>
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.focusedName
                                            })}
                                        />
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.focusedEmail
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Email"
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    onFocus={() => this.setState({focusedEmail: true})}
                                                    onBlur={() => this.setState({focusedEmail: false})}
                                                    onChange={e => {
                                                        this.validateEmail(e);
                                                        this.handleChange(e);
                                                    }}
                                                />
                                            </InputGroup>
                                            <FormGroup
                                                className={classnames({
                                                    focused: this.state.focusedTermsAndConditions
                                                })}
                                            >
                                                <InputGroup className="input-group-merge mb-3">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            className="custom-control-input"
                                                            id="customCheck3"
                                                            type="checkbox"
                                                            name="termsAndConditions"
                                                            onChange={e => {
                                                                this.handleChange(e);
                                                            }}
                                                        />
                                                        <label
                                                            className="custom-control-label mr-2"
                                                            htmlFor="customCheck3"
                                                        >
                                                            Nõustun tingimustega
                                                        </label>
                                                        {/* eslint-disable-next-line  */}
                                                        <a href="#" className="hopsti-green"
                                                           onClick={() => {
                                                               this.toggleModal("termsAndConditionsModal");
                                                               this.getPageContent();
                                                           }}>
                                                            Vaata tingimusi
                                                        </a>
                                                    </div>

                                                </InputGroup>

                                                <Modal
                                                    size="lg"
                                                    isOpen={this.state.termsAndConditionsModal}
                                                    toggle={() => this.toggleModal("termsAndConditionsModal")}
                                                >
                                                    <div className="modal-header">
                                                        <button
                                                            aria-label="Close"
                                                            className="close"
                                                            data-dismiss="modal"
                                                            type="button"
                                                            onClick={() => this.toggleModal("termsAndConditionsModal")}
                                                        >
                                                            <span aria-hidden={true}>×</span>
                                                        </button>
                                                    </div>
                                                    <Container fluid>
                                                        <Row className="row-grid justify-content-center">
                                                            <Col lg="10">
                                                                {this.state.content ? Parser(this.state.content) : null}
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                    <Button className={"float-right"}
                                                            onClick={() => this.toggleModal("termsAndConditionsModal")}>
                                                        Sulge
                                                    </Button>
                                                </Modal>
                                            </FormGroup>
                                        </FormGroup>
                                        <div className="text-center mb-5">
                                            <Button
                                                onClick={this.handleSubmit}
                                                className={this.props.isServiceProvider ? "mt-4 btn-hopsti-green" : "mt-4 btn-hopsti-pink"}
                                                type="button"
                                            >
                                                Registreeru
                                            </Button>
                                        </div>

                                        <div className="d-flex justify-content-center pt-2">
                                            <FacebookLogin
                                                appId="432440380776606"
                                                icon="fab fa-facebook-f"
                                                cssClass="btn-facebook-hopsti clickable"
                                                textButton="&nbsp;&nbsp;Sign In with Facebook"
                                                fields="name,email,picture"
                                                callback={this.responseFacebook}
                                            />
                                            <GoogleLogin
                                                clientId="11496613599-npisk71l196ng96dfe414qt8eka7c4uh.apps.googleusercontent.com"
                                                onSuccess={this.responseGoogleSuccess}
                                                onFailure={this.responseGoogleFailure}
                                                style={{}}
                                                className="btn-google-plus"
                                                buttonText="Sign In with Google">
                                            </GoogleLogin>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignup: email => dispatch(actions.authSignupServiceProvider(email)),
        onFacebookAuth: (accessToken) => dispatch(actions.onFacebookAuth(accessToken)),
        onGoogleAuth: (accessToken) => dispatch(actions.onGoogleAuth(accessToken)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HopstiRegisterUser);
