import React from "react";
import classnames from "classnames";
import axios from "../../../axios";
import Select2 from "react-select2-wrapper";
import NotificationAlert from "react-notification-alert";
import * as helpers from "utils/helpers.js";
import * as services from "utils/services.js";
import {
    Alert,
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroup,
    Row,
    Col,
    Table,
    InputGroupAddon,
    InputGroupText, CardHeader
} from "reactstrap";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import ImageUploader from "components/ImageUploader/ImageUploader";
import LocationInput from '../../GooglePlacesAutocomplete/HopstiGooglePlacesAutocomplete';
import * as actions from "../../../store/actions/auth";

class ServiceItem extends React.Component {
    state = {
        id: null,
        showErrorMessage: false,
        name: "",
        cost: "",
        unit: "",
        units: [],
        estDescription: "",
        ruDescription: "",
        engDescription: "",
        serviceGroups: [],
        serviceGroup: "",
        serviceAdded: false,
        serviceSubGroups: [],
        serviceSubGroup: "",
        tabs: 1,
        isAutomaticOffer: false,
        extraServices: [],
        files: [],
        customStyles: {
            nameState: null,
            estDescriptionState: null,
            costState: null,
            unitState: null,
            serviceGroupState: null,
            serviceSubGroupState: null
        },
        canClick: true,
        address: "",
        latLnglat: "",
        latLnglng: "",
        county: "",
        municipality: "",
        showAddress: false
    };

    componentDidMount() {
        if (this.props.serviceId) {
            this.getServiceDetails(this.props.serviceId);
        } else {
            this.getServicGroupsWithSubGroupsAndUnits();
        }

        this.setState({address: this.props.sp_address ? this.props.sp_address : ""})

        if (!this.props.sp_address || this.props.sp_address === "") {
            this.setState({showAddress: true})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.getServicGroupsWithSubGroupsAndUnits();
        }
    }

    handleFileAdded = (file, size) => {
        if (size > 10485760) {
            helpers.notify(
                "warning",
                "Fail liiga suur!",
                "Pildi faili suurus peab olema alla 10MB!",
                this
            );
            return;
        }
        services.postFileToServer(file.file, this.props.userId).then(
            id => {
                let addedFile = {...file, id: id}
                this.setState({files: [...this.state.files, addedFile]});
            }
        );
    };

    handleFileDeleted = (id) => {
        services.deleteFileFromServer(id).then(
            id => {
                let oldFiles = [...this.state.files];
                oldFiles.splice(id, 1);
                this.setState({files: oldFiles});
            }
        );
    };

    getServicGroupsWithSubGroupsAndUnits = () => {
        services.getAllServiceGroups().then(serviceGroups => {
            this.setState({serviceGroups});
            // services.getServiceSubGroups(serviceGroups[0].id).then(serviceSubGroups => {
            //     this.setState({
            //         serviceSubGroups
            //     });
            // }
            // );
        });
        services.getUnits().then((units) => this.setState({units: units}));
    };

    handleChange = async event => {
        const {target} = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const {name} = target;
        await this.setState({
            [name]: value
        }, () => {
            this.validate()
        });
    };

    validate = () => {
        let allValid = true;

        let newState = {...this.state.customStyles};

        if (this.state.name === "" || this.state.name.length > 99) {
            newState.nameState = "invalid";
            allValid = false;
        } else {
            newState.nameState = "valid";
        }

        if (this.state.estDescription === "" || this.state.estDescription.length > 999) {
            newState.estDescriptionState = "invalid";
            allValid = false;
        } else {
            newState.estDescriptionState = "valid";
        }

        if (this.state.cost === "" || this.state.cost.length > 12) {
            newState.costState = "invalid";
            allValid = false;
        } else {
            newState.costState = "valid";
        }
        if (this.state.unit === "") {
            newState.unitState = "invalid";
            allValid = false;
        } else {
            newState.unitState = "valid";
        }
        if (this.state.serviceGroup === "") {
            newState.serviceGroupState = "invalid";
            allValid = false;
        } else {
            newState.serviceGroupState = "valid";
        }
        if (this.state.serviceSubGroup === "") {
            newState.serviceSubGroupState = "invalid";
            allValid = false;
        } else {
            newState.serviceSubGroupState = "valid";
        }

        if (this.state.address === "") {
            allValid = false;
        }
        this.setState({customStyles: newState});
        return allValid;
    };

    handleChangeInExtra = async (event, index) => {
        const {target} = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const {name} = target;
        let extraServices = [...this.state.extraServices];
        extraServices[index][name] = value;
        await this.setState({extraServices: extraServices});
    };

    handleExtraService = (e) => {
        e.preventDefault();
        let extraServices = [...this.state.extraServices];
        let newExtraService = {description: "", cost: "", unit: "1"};
        extraServices.push(newExtraService);
        this.setState({extraServices: extraServices});
    };

    handleExtraDelete = (e, index) => {
        e.preventDefault();
        let extraservices = [...this.state.extraServices];
        extraservices.splice(index, 1);
        this.setState({extraServices: extraservices});
    };

    clicked = false;

    checkAddress = (sp_address, addService) => {
        if (!sp_address || sp_address === "") {
            const user = {
                ...this.props.user, ...{
                    sp_address: this.state.address,
                    sp_longitude: this.state.latLnglng,
                    sp_latitude: this.state.latLnglat,
                    sp_county: this.state.county,
                    sp_municipality: this.state.municipality
                }
            };
            console.log(user);
            const data = {
                first_name: user.first_name,
                last_name: user.last_name,
                code: user.code,
                address: user.address,
                latitude: user.latitude,
                longitude: user.longitude,
                municipality: user.municipality,
                county: user.county,
                sp_address: user.sp_address,
                sp_latitude: user.sp_latitude,
                sp_longitude: user.sp_longitude,
                sp_municipality: user.sp_municipality,
                sp_county: user.sp_municipality,
                sp_name: user.sp_name,
                sp_email: user.sp_email,
                sp_phone: user.sp_phone,
                sp_radius: user.sp_radius
            };
            axios
                .put("/rest-auth/user/", data)
                .then(response => {
                    axios
                        .get("/rest-auth/user/")
                        .then(response => {
                            this.props.setUser(response.data);
                            addService();
                        })
                        .catch(err => {

                        });
                })
                .catch(err => {
                    helpers.notify(
                        "danger",
                        "Uuendamine ebõnnestus",
                        "Kontaktandmete uuendamine ebaõnnestus!",
                        this
                    );
                });
        } else {
            addService();
        }

    };

    handleSubmit = e => {
        e.preventDefault();
        if (this.validate() && !this.clicked) {
            this.clicked = true;
            let filesIds = this.state.files.map(file => file.id);

            const data = {
                name: this.state.name,
                cost: this.state.cost,
                unit: {unit: this.state.unit},
                description_ee: this.state.estDescription,
                description_ru: this.state.ruDescription,
                description_en: this.state.engDescription,
                sub_service_id: [this.state.serviceSubGroup],
                is_automatic_offer: this.state.isAutomaticOffer,
                extra: this.state.extraServices.length > 0 ? this.state.extraServices.map(extra => {
                    extra.unit = {unit: extra.unit};
                    return (extra)
                }) : [],
                file: filesIds
            };

            this.checkAddress(this.props.sp_address, () => {
                axios
                    .post("/services/", data)
                    .then(response => {
                        this.props.handleServiceAdded();
                    })
                    .catch(err => {
                        console.log("HopstiAddService.js - " + err);
                        this.setState({
                            showErrorMessage: true,
                            serviceAdded: false
                        })
                    }).finally(() => {
                    this.clicked = false;
                    this.setState({canClick: true});
                });
            });
        } else {
            this.clicked = false;
            this.setState({
                showErrorMessage: true,
                canClick: true
            });
        }
    };

    handleUpdate = e => {
        e.preventDefault();
        if (this.validate() && !this.clicked) {
            let filesIds = this.state.files.map(file => file.id);

            const data = {
                id: this.state.id,
                name: this.state.name,
                cost: this.state.cost,
                unit: {unit: this.state.unit},
                description_ee: this.state.estDescription,
                description_ru: this.state.ruDescription,
                description_en: this.state.engDescription,
                sub_service_id: [this.state.serviceSubGroup],
                is_automatic_offer: this.state.isAutomaticOffer,
                extra: this.state.extraServices.map(extra => {
                    extra.unit = {unit: extra.unit};
                    return (extra)
                }),
                file: filesIds
            };

            axios
                .put("/services/auth/update/" + this.props.serviceId + "/", data)
                .then(response => {
                    this.setState({
                        showErrorMessage: false,
                        serviceUpdated: true
                    });
                    this.props.handleEditService("UPDATE");
                })
                .catch(err => {
                    this.setState({
                        showErrorMessage: true,
                        serviceUpdated: false
                    });
                }).finally(
                () => {
                    this.clicked = false;
                    this.setState({canClick: true});
                }
            );
        } else {
            this.clicked = false;
            this.setState({
                showErrorMessage: true,
                canClick: true
            });
        }
    };

    getServiceDetails = serviceId => {
        axios
            .get("/services/auth/service/" + serviceId + "/")
            .then(response => {
                if (response.data) {
                    const selectedServiceDetails = response.data;
                    this.convertServiceToState(selectedServiceDetails);
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    };

    convertServiceToState = service => {
        this.setState({
            id: service.id,
            name: service.name,
            cost: service.cost,
            unit: service.unit.id,
            estDescription: service.description_ee,
            ruDescription: service.description_ru,
            engDescription: service.description_en,
            serviceGroup: service.sub_service_name[0].service_group.id,
            serviceSubGroup: service.sub_service_name[0].id,
            isAutomaticOffer: service.is_automatic_offer,
            extraServices: service.extra ? service.extra.map(extra => {
                let newExtra = {...extra};
                newExtra.unit = newExtra.unit.id;
                return newExtra;
            }) : [],
            files: service.files ? service.files.map(file => ({
                id: file.id,
                preView: file.original
            })) : []
        }, () => {
            services.getAllServiceGroups().then(serviceGroups => {
                services.getServiceSubGroups(service.sub_service_name[0].service_group.id).then(serviceSubGroups => {
                        this.setState({
                            serviceGroups,
                            serviceSubGroups,
                            serviceGroup: service.sub_service_name[0].service_group.id,
                            serviceSubGroup: service.sub_service_name[0].id,
                        });
                    }
                );
            });
            services.getUnits().then((units) => this.setState({units: units}));

        });
    };

    handleAddress = (latLnglat, latLnglng, address, adminLevel1, adminLevel2) => {
        this.setState({
            address: address,
            latLnglat: latLnglat,
            latLnglng: latLnglng,
            county: adminLevel1,
            municipality: adminLevel2
        });
    };

    render() {
        let errorMessage = null;
        let buttonSection = null;
        let disableCategorySelections = false;
        let onDisabledClick = () => {
        };
        let addressSection = null;

        if (this.state.showErrorMessage) {
            errorMessage = (
                <Alert color="danger">
                    <strong>Viga!</strong> Lisamine ebaõnnestus, palun kontrolli andmeid.
                </Alert>
            );
        }

        if (this.props.serviceId) {
            disableCategorySelections = true;
            onDisabledClick = () => {
                helpers.notify(
                    "warning",
                    "Muutmine pole lubatud",
                    "Kategooria muutmiseks loo uus teenus",
                    this
                );
            };
            buttonSection = (
                <div className="text-center mt-4">
                    <Button
                        onClick={(e) => {
                            this.setState({canClick: false});
                            this.handleUpdate(e)
                        }}
                        className="mt-4 btn-hopsti-green"
                        type="button"
                        disabled={!this.state.canClick}
                    >
                        {this.props.t('add-new-service.save-changes')}
                    </Button>
                    <Button onClick={this.props.closeModalHandler} className="mt-4 btn-hopsti-pink" type="button">
                        {this.props.t('add-new-service.close')}
                    </Button>
                </div>
            );
        } else {
            buttonSection = (
                <div className="text-center mt-4">
                    <Button
                        onClick={(e) => {
                            this.setState({canClick: false});
                            this.handleSubmit(e)
                        }}
                        className="mt-4 btn-hopsti-green"
                        type="button"
                        disabled={!this.state.canClick}
                    >
                        {this.props.t('add-new-service.add-new-service')}
                    </Button>
                    <Button onClick={this.props.closeModalHandler} className="mt-4 btn-hopsti-pink" type="button">
                        {this.props.t('add-new-service.close')}
                    </Button>
                </div>
            );
        }

        if (this.state.showAddress) {
            addressSection = (
                <FormGroup>
                    <label className="form-control-label hopsti-green">
                        {this.props.t('add-new-service.company-address')}
                    </label>
                    <LocationInput
                        address={this.state.address}
                        handleAddress={this.handleAddress}
                    />
                </FormGroup>
            );
        }

        return (
            <>
                <Row className="justify-content-center">
                    <div className="rna-wrapper contactAlert">
                        <NotificationAlert ref="notificationAlert"/>
                    </div>
                    <Col lg="12" md="12">
                        <CardHeader>
                            <Row>
                                <Col><h2 className="hopsti-green">{this.props.t('add-new-service.service-details')}</h2>
                                </Col>
                                <Col>
                                    <div className="text-right">
                                        <i className="fas fa-2x fa-times clickable"
                                           onClick={this.props.closeModalHandler}/>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Card className="bg-secondary border-0 mb-0">
                            <CardBody className="px-lg-4 py-lg-4">
                                {errorMessage}
                                <Form role="form">
                                    <Row>
                                        <Col md="6">
                                            {/* Teenuse nimi */}
                                            <label className="form-control-label small hopsti-green">
                                                {this.props.t('add-new-service.service-name')}
                                            </label>
                                            <FormGroup
                                                className={classnames({
                                                    focused: this.state.focusedName
                                                })}
                                            >
                                                <InputGroup className="mb-3">
                                                    <Input
                                                        bsSize="sm"
                                                        placeholder={this.props.t('add-new-service.name')}
                                                        type="text"
                                                        name="name"
                                                        value={this.state.name}
                                                        onFocus={() =>
                                                            this.setState({focusedName: true})
                                                        }
                                                        onBlur={() =>
                                                            this.setState({focusedName: false})
                                                        }
                                                        onChange={e => {
                                                            this.handleChange(e);
                                                        }}
                                                        valid={
                                                            this.state.customStyles.nameState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.customStyles.nameState ===
                                                            "invalid"
                                                        }
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                            <Row className="mt-2">
                                                {/* Teenuse kategooriad */}
                                                <Col md="6">
                                                    <FormGroup
                                                        className={classnames({
                                                            focused: this.state.focusedServiceGroups
                                                        })}
                                                        onClick={e => {
                                                            onDisabledClick();
                                                        }}
                                                    >
                                                        <label className="form-control-label hopsti-green">
                                                            {this.props.t('add-new-service.category')}
                                                        </label>
                                                        <Input
                                                            bsSize="sm"
                                                            id="exampleFormControlSelect1"
                                                            type="select"
                                                            disabled={disableCategorySelections}
                                                            value={this.state.serviceGroup}
                                                            onChange={e => {
                                                                this.setState({
                                                                    serviceGroup: e.target.value
                                                                }, () => this.validate());
                                                                services.getServiceSubGroups(e.target.value).then(serviceSubGroups => {
                                                                    this.setState({
                                                                        serviceSubGroups,
                                                                        serviceSubGroup: ""
                                                                    });
                                                                });

                                                            }}
                                                            valid={
                                                                this.state.customStyles.serviceGroupState === "valid"
                                                            }
                                                            invalid={
                                                                this.state.customStyles.serviceGroupState ===
                                                                "invalid"
                                                            }
                                                        >
                                                            <option key="aaa" selected value="" disabled> Vali
                                                                kategooria
                                                            </option>
                                                            {this.state.serviceGroups.map(serviceGroup => {
                                                                return (
                                                                    <option
                                                                        key={serviceGroup.id}
                                                                        value={serviceGroup.id}
                                                                    >
                                                                        {serviceGroup.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                {/* Teenuse alamkategooria */}
                                                <Col md="6">
                                                    <FormGroup
                                                        className={classnames({
                                                            focused: this.state.focusedServiceGroups
                                                        })}
                                                        onClick={e => {
                                                            onDisabledClick();
                                                        }}
                                                    >
                                                        <label className="form-control-label hopsti-green">
                                                            {this.props.t('add-new-service.sub-category')}
                                                        </label>
                                                        <div className={this.state.customStyles.serviceSubGroupState ===
                                                        "invalid" ? "hopsti-select2-invalid" : this.state.customStyles.serviceSubGroupState ===
                                                        "valid" ? "hopsti-select2-valid" : ""}>
                                                            <Select2
                                                                value={this.state.serviceSubGroup}
                                                                className={"form-control form-control-sm"}
                                                                disabled={disableCategorySelections}
                                                                options={{
                                                                    placeholder: "Vali alamkategooria"
                                                                }}
                                                                data={
                                                                    this.state.serviceSubGroups.map(
                                                                        serviceSubGroup => {
                                                                            return {
                                                                                id: serviceSubGroup.id,
                                                                                text: serviceSubGroup.name
                                                                            };
                                                                        }
                                                                    )
                                                                }
                                                                onSelect={e => {
                                                                    this.setState({
                                                                        serviceSubGroup: e.target.value
                                                                    }, () => this.validate());

                                                                }}
                                                            />
                                                        </div>

                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md="6">
                                                    <label
                                                        className="form-control-label hopsti-green">{this.props.t('add-new-service.price')}</label>
                                                    <FormGroup
                                                        className={classnames({
                                                            focused: this.state.focusedCost
                                                        })}
                                                    >
                                                        <InputGroup
                                                            className="mb-3">
                                                            <Input
                                                                bsSize="sm"
                                                                placeholder={this.props.t('add-new-service.service-price')}
                                                                type="number"
                                                                name="cost"
                                                                value={this.state.cost}
                                                                onFocus={() =>
                                                                    this.setState({focusedCost: true})
                                                                }
                                                                onBlur={() =>
                                                                    this.setState({focusedCost: false})
                                                                }
                                                                onChange={e => {
                                                                    this.handleChange(e);
                                                                }}
                                                                valid={
                                                                    this.state.customStyles.costState === "valid"
                                                                }
                                                                invalid={
                                                                    this.state.customStyles.costState ===
                                                                    "invalid"
                                                                }
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText>
                                                                    <small className="font-weight-bold">
                                                                        €
                                                                    </small>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    {/* Ühik */}
                                                    <FormGroup
                                                        className={classnames({
                                                            focused: this.state.focusedUnit
                                                        })}
                                                    >
                                                        <label
                                                            className="form-control-label hopsti-green">{this.props.t('add-new-service.unit')}</label>
                                                        <Input
                                                            bsSize="sm"
                                                            id="exampleFormControlSelect61"
                                                            type="select"
                                                            name="unit"
                                                            placeholder="Vali ühik"
                                                            selected={this.state.unit}
                                                            value={this.state.unit}
                                                            onChange={e => {
                                                                this.handleChange(e);
                                                            }}
                                                            valid={
                                                                this.state.customStyles.unitState === "valid"
                                                            }
                                                            invalid={
                                                                this.state.customStyles.unitState ===
                                                                "invalid"
                                                            }
                                                        >
                                                            <option key="aaa" selected value="" disabled>Vali ühik
                                                            </option>
                                                            {this.state.units.map(unit => {
                                                                return (
                                                                    <option key={unit.id} value={unit.id}>
                                                                        {unit.unit}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="6">
                                            <label className="form-control-label hopsti-green">
                                                {this.props.t('add-new-service.description')}
                                            </label>
                                            <FormGroup
                                                className={classnames({
                                                    focused: this.state.focusedDescInEstonian
                                                })}
                                            >
                                                <InputGroup className="">
                                                    <Input
                                                        placeholder={this.props.t('add-new-service.description-placeholder')}
                                                        className="tabHeight"
                                                        type="textarea"
                                                        name="estDescription"
                                                        value={this.state.estDescription}
                                                        onFocus={() =>
                                                            this.setState({
                                                                focusedDescInEstonian: true
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            this.setState({
                                                                focusedDescInEstonian: false
                                                            })
                                                        }
                                                        onChange={e => {
                                                            this.handleChange(e);
                                                        }}
                                                        valid={
                                                            this.state.customStyles.estDescriptionState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.customStyles.estDescriptionState === "invalid"
                                                        }
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                            {addressSection}
                                        </Col>
                                        <Col md="12">
                                            <label className="form-control-label hopsti-green">
                                                {this.props.t('add-new-service.additional-services')}
                                            </label>
                                            <Table className="align-items-center" responsive>
                                                <thead className="thead-ligh hopsti-greent">
                                                <tr className="hopsti-green">
                                                    <th scope="col">{this.props.t('add-new-service.description')}</th>
                                                    <th scope="col">{this.props.t('add-new-service.price')}</th>
                                                    <th scope="col">{this.props.t('add-new-service.unit')}</th>
                                                    <th scope="col"/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.extraServices.map((item, index) => {
                                                        let currentIndex = index;
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <Input
                                                                        bsSize="sm"
                                                                        type="text"
                                                                        value={item.extraDescription}
                                                                        name="extraDescription"
                                                                        onChange={e => {
                                                                            this.handleChangeInExtra(e, currentIndex);
                                                                        }}
                                                                        valid={
                                                                            this.state.customStyles.extraDescriptionState === "valid"
                                                                        }
                                                                        invalid={
                                                                            this.state.customStyles.extraDescriptionState === "invalid"
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <FormGroup className={"currencyInput"}>
                                                                        <InputGroup>
                                                                            <Input
                                                                                bsSize="sm"
                                                                                type="number"
                                                                                value={item.cost}
                                                                                name="cost"
                                                                                onChange={e => {
                                                                                    this.handleChangeInExtra(e, currentIndex);
                                                                                }}
                                                                            /><InputGroupAddon addonType="append">
                                                                            <InputGroupText>
                                                                                <small className="font-weight-bold">
                                                                                    €
                                                                                </small>
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        bsSize="sm"
                                                                        type="select"
                                                                        value={item.unit}
                                                                        name="unit"
                                                                        onChange={e =>
                                                                            this.handleChangeInExtra(e, currentIndex)
                                                                        }
                                                                    >
                                                                        {this.state.units.map(unit => {
                                                                            return (
                                                                                <option key={unit.id} value={unit.id}>
                                                                                    {unit.unit}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </Input>
                                                                </td>
                                                                <td>
                                                                    <Button className="btn-hopsti-green"
                                                                            onClick={(e) => this.handleExtraDelete(e, currentIndex)}><i
                                                                        className="fas fa-trash-alt"></i></Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                )}
                                                </tbody>
                                            </Table>
                                            <Button className="btn-icon btn-3 mt-2 btn-hopsti-green" type="button"
                                                    onClick={this.handleExtraService}>
                                                <i className="fas fa-plus"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col md="6">
                                            <ImageUploader files={this.state.files}
                                                           handleFileAdded={this.handleFileAdded}
                                                           handleFileDeleted={this.handleFileDeleted}/>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup
                                                className={classnames({
                                                    focused: this.state.focusedAutomaticOffer
                                                })}
                                            >
                                                <InputGroup className="input-group-merge mb-3 mt-2">
                                                    <div className="custom-control custom-checkbox">
                                                        <Input
                                                            className="custom-control-input"
                                                            id="customCheck1"
                                                            type="checkbox"
                                                            name="isAutomaticOffer"
                                                            value={this.state.isAutomaticOffer}
                                                            checked={this.state.isAutomaticOffer}
                                                            onChange={e => {
                                                                this.handleChange(e);
                                                            }}
                                                        />
                                                        <label
                                                            className="custom-control-label hopsti-green"
                                                            htmlFor="customCheck1"
                                                        >
                                                            {this.props.t('add-new-service.automatic-matching')}
                                                        </label>
                                                    </div>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {buttonSection}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token,
        user: state.auth.user,
        sp_address: state.auth.user ? (state.auth.user.sp_address ? state.auth.user.sp_address : null) : null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUser: (user) => dispatch(actions.setUser(user))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ServiceItem));
