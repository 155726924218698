import React from "react";
import {Modal} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import axios from "../../axios";
import {connect} from "react-redux";
import ReactGA from 'react-ga';

import EditService from "../Service/EditService/EditService";
import YourServices from "../Service/YourServices/YourServices";
import AllServices from "../Service/AllServices/AllServices";
import YourHelpRequests from "../HelpRequest/YourHelpRequests/YourHelpRequests";
import AllHelpRequests from "../HelpRequest/AllHelpRequests/AllHelpRequests";
import AddServiceContainer from "../Service/AddService/AddServiceContainer";
import HopstiAddHelpRequest from "components/HelpRequest/AddHelpRequest/HopstiAddHelpRequest";
import EditHelpRequest from "components/HelpRequest/EditHelpRequest/EditHelpRequest";
import CategorySelect from "components/CategorySelect/CategorySelect";

import * as services from "utils/services.js";
import * as kov from "components/KOV/Helpers/kov.jsx";
import MyJobs from "../MyJobs/MyJobs";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {withRouter} from "react-router";
import PersonsInNeed from "../KOV/Views/PersonsInNeed/PersonsInNeed";
import ServiceProviders from "../KOV/Views/ServiceProviders/ServiceProviders";
import Statistics from "../KOV/Views/Statistics/Statistics";
import * as serviceActions from "../../store/actions/services";
import * as helpRequestActions from "../../store/actions/helpRequests";
import * as offerActions from "../../store/actions/offers";


class HopstiDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedService: this.props.match.params.serviceId,
            kovHelpRequests: [],
            kovServices: [],
            editServiceModal: false,
            serviceDetailModal: false,
            addHelpRequestModal: false,
            editHelpRequesteModal: false,
            filterBy: [],
            helpRequestfilterBy: [],
            tabs: props.isServiceProvider ? 1 : 2,
            alert: null,
        };
    }

    componentDidMount() {
        ReactGA.initialize('UA-150997639-1');
        ReactGA.pageview('/töölaud');
        this.props.history.replace('/töölaud/', null);
        this.getCategory(this.props.match.params.category);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user && this.props !== prevProps) {

            if (this.props.role !== prevProps.role) {

                if (this.props.role === "TEENUSEPAKKUJA") {
                    services.getServiceProviderDashboard(this.props, this.state.helpRequestfilterBy, this.props.match.params.itemId, this.props.user.pk);
                }

                if (this.props.role === "ABIVAJAJA") {
                    services.getPersonInNeedDashboard(this.props, this.state.filterBy, this.props.match.params.itemId, this.props.user.pk);
                }
            }

            if (this.props.match.url === "/") {
                if (this.props.token) {
                    if (this.props.role) {
                        if (this.props.user.role === "KOV") this.props.history.push("/töölaud/meie-abivajajad/");
                        if (this.props.user.role === "TEENUSEPAKKUJA") this.props.history.push("/töölaud/minu-teenused");
                        if (this.props.user.role === "ABIVAJAJA") this.props.history.push("/töölaud/minu-abihõiked");
                    }
                }
            }

            this.getCategory(this.props.match.params.category);

            if (this.props.role === "KOV") {
                this.getKovDashboardContent();
            }
        }
    }

    getCategory = (category) => {
        if (this.props.role === "KOV") {
            if (category) {
                if (category === "meie-abivajajad") this.setState({tabs: 8});
                if (category === "meie-teenusepakkujad") this.setState({tabs: 9});
                if (category === "statistika") this.setState({tabs: 10});
            } else {
                this.setState({tabs: 8});
            }
        }

        if (this.props.role === "TEENUSEPAKKUJA") {
            if (category) {
                if (category === "minu-teenused") this.setState({tabs: 1});
                if (category === "abihõiked") this.setState({tabs: 4});
                if (category === "minu-tööd") this.setState({tabs: 7});
            } else {
                this.setState({tabs: 1});
            }
        }

        if (this.props.role === "ABIVAJAJA") {
            if (category) {
                if (category === "minu-abihõiked") {
                    this.setState({tabs: 2});

                    if(this.props.location.state && this.props.location.state.addHelpRequestModal) {
                        this.toggleModal("addHelpRequestModal");
                    }
                }
                if (category === "teenused") this.setState({tabs: 3});
                if (category === "minu-pakkumised") this.setState({tabs: 6});
            } else {
                this.setState({tabs: 2})
            }
        }
    };

    getKovDashboardContent = () => {
        if (this.props.match.params.category === "meie-abivajajad") {
            kov.getHelpRequests().then(resp => {
                this.setState({kovHelpRequests: resp});
            })
        }

        if (this.props.match.params.category === "meie-teenusepakkujad") {
            kov.getServices().then(resp => {
                this.setState({kovServices: resp});
            })
        }
    };

    getOffers = async (isServiceProvider, id) => {
        services.getOffers(isServiceProvider, id, this.props.user.pk).then(offers => {
            this.props.onGetOffers(offers)
        });
    };

    getServicesOnFilterChange = () => {
        services.getAllServices(this.state.filterBy, this.props.user.pk).then(resp => {
            this.props.onGetAllServices(resp);
        });
    };

    getHelpRequestsOnFilterChange = () => {
        services.getAllHelpRequests(this.state.helpRequestfilterBy).then(resp => {
            this.props.onGetAllHelpRequests(resp);
        });
    };

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    removeServiceHandler = serviceId => {
        axios
            .delete("/services/auth/delete/" + serviceId + "/")
            .then(response => {
                services.getUserServices().then(resp => {
                    this.props.onGetSPServices(resp);
                });
                this.getOffers(this.state.isServiceProvider);
                this.notify("success", "Teenus edukalt kustutatud!");
            })
            .catch(err => {
                console.log("HopstiServices.js - " + err);
                this.notify("danger", "Teenuse kustutamine ebaõnnestus!");
            });
    };

    editServiceHandler = serviceId => {
        this.setState({
            selectedService: serviceId
        });
        this.toggleModal("editServiceModal");
    };

    editHelpRequestHandler = helpRequestId => {
        this.setState({
            helpRequestId: helpRequestId
        });
        this.toggleModal("editHelpRequesteModal");
    };

    serviceDetailHandler = serviceId => {
        this.setState({
            selectedService: serviceId
        });
        this.toggleModal("serviceDetailModal");
    };

    addServiceHandler = () => {
        this.toggleModal("addServiceModal");
    };

    removeHelpRequestHandler = helpRequestId => {
        axios
            .delete("/help-requests/delete/" + helpRequestId + "/")
            .then(() => {
                services.getUserHelpRequests(this.props.user.pk).then(resp => {
                    this.props.onGetPINHelpRequests(resp);
                });
                this.getOffers(this.state.isServiceProvider);
                this.notify("success", "Abihõike kustutamine õnnestus");
            })
            .catch(err => {
                console.log(err);
                this.notify("danger", "Abihõike kustutamine ebaõnnestus");
            });
    };

    handleServiceAdded = event => {
        this.toggleModal("addServiceModal");

        ReactGA.ga('send', {
            hitType: 'event',
            eventCategory: 'Services',
            eventAction: 'insertS',
            eventLabel: 'Added service'
        });

        services.getUserServices().then(resp => {
            this.props.onGetSPServices(resp);
        });
        this.getOffers(this.state.isServiceProvider);
        this.notify("success", "Lisamine õnnestus", "Teenus on edukalt lisatud!");
    };

    handleHelpRequestAdded = () => {
        this.toggleModal("addHelpRequestModal");

        ReactGA.ga('send', {
            hitType: 'event',
            eventCategory: 'HelpRequests',
            eventAction: 'insertHR',
            eventLabel: 'Added help request'
        });

        services.getUserHelpRequests(this.props.user.pk).then(resp => {
            this.props.onGetPINHelpRequests(resp);
        });
        this.getOffers(this.state.isServiceProvider);
        this.notify("success", "Lisamine õnnestus", "Abipalve on edukalt lisatud!");
    };

    handleEditService = actionTaken => {
        this.toggleModal("editServiceModal");
        if (actionTaken === "UPDATE") {
            services.getUserServices().then(resp => {
                this.props.onGetSPServices(resp);
            });
            this.getOffers(this.state.isServiceProvider);
            this.notify(
                "success",
                "Uuendamine õnnestus",
                "Teenus on edukalt uuendatud!"
            );
        }

        if (actionTaken === "DELETE") {
            services.getUserServices().then(resp => {
                this.props.onGetSPServices(resp);
            });
            this.notify(
                "danger",
                "Teenus kustutatud",
                "Teenuse kustutamine õnnestus!"
            );
        }
    };

    handleEditHelpRequest = actionTaken => {
        this.toggleModal("editHelpRequesteModal");
        if (actionTaken === "UPDATE") {
            services.getUserHelpRequests(this.props.user.pk).then(resp => {
                this.props.onGetPINHelpRequests(resp);
            });
            this.getOffers(this.state.isServiceProvider);
            this.notify(
                "success",
                "Uuendamine õnnestus",
                "Abihõige on edukalt uuendatud!"
            );
        }

        if (actionTaken === "DELETE") {
            services.getUserHelpRequests(this.props.user.pk).then(resp => {
                this.props.onGetPINHelpRequests(resp);
            });
            this.notify(
                "danger",
                "Abihõige kustutatud",
                "Abihõike kustutamine õnnestus!"
            );
        }
    };

    handleMatchClick = item => {
        if (this.props.isServiceProvider) {
            this.setState({tabs: 7});
            this.props.history.push("/töölaud/minu-tööd/" + item);
        } else {
            this.setState({tabs: 6});
            this.props.history.push("/töölaud/minu-pakkumised/" + item)
        }
    };

    toggleNavs = (e, state, index) => {
        this.setState({
            [state]: index
        });
    };

    modalCloseButton = (elementToClose) => {
        return <div className="modal-header">
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal(elementToClose)}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>
    };

    hideDeleteAlert = () => {
        this.setState({
            alert: null
        });
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        {title}
                    </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    showDeleteAlert = (id, removeHandler, confirmationMSG) => {
        this.setState({
            alert: (<ReactBSAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title={confirmationMSG}
                onConfirm={() => {
                    removeHandler(id);
                    this.hideDeleteAlert();
                }}
                onCancel={() => this.hideDeleteAlert()}
                showCancel
                confirmBtnBsStyle="danger"
                confirmBtnText="Jah, kustuta!"
                cancelBtnBsStyle="secondary"
                cancelBtnText="Loobu"
                btnSize=""
            >
            </ReactBSAlert>)
        });
    };

    offerStatusNotificationHandler = (id) => {
        let statusText = "";
        switch (id) {
            case 1:
                statusText = "Pakkumine saadetud";
                break;
            case 2:
                statusText = "Pakkumine kinnitatud";
                break;
            case 3:
                statusText = "Pakkumine kinnitatud";
                break;
            case 4:
                statusText = "Teenus osutatud";
                break;
            case 5:
                statusText = "Tühistatud";
                break;
            case 6:
                statusText = "Arhiveeritud";
                break;

            default:
                break;
        }
        return statusText !== "" ?
            this.notify(
                "success",
                statusText,
                ""
            ) : null
    };

    render() {
        return (
            <>
                {this.state.alert}
                <div className="container-fluid mt-5 pb-5">
                    <div className="rna-wrapper">
                        <NotificationAlert ref="notificationAlert"/>
                    </div>

                    <CategorySelect
                        role={this.props.role}
                        toggleNavs={this.toggleNavs}
                        tabs={this.state.tabs}
                        offers={this.props.offers}
                    />

                    {this.state.tabs === 1 ?
                        <YourServices
                            yourServices={this.props.spServices} // yourServices={this.state.userServices}
                            handleMatchClick={this.handleMatchClick}
                            editServiceHandler={this.editServiceHandler}
                            removeServiceHandler={(id) => {
                                this.showDeleteAlert(id, this.removeServiceHandler, "Kustuta teenus jäädavalt?")
                            }}
                            addServiceHandler={() =>
                                this.toggleModal("addServiceModal")
                            }
                        /> : null}

                    {this.state.tabs === 2 ?
                        <YourHelpRequests
                            yourHelpRequests={this.props.pinHelpRequests} //yourHelpRequests={this.state.userHelpRequests}
                            handleMatchClick={this.handleMatchClick}
                            editHelpRequestHandler={this.editHelpRequestHandler}
                            removeHelpRequestHandler={(id) => {
                                this.showDeleteAlert(id, this.removeHelpRequestHandler, "kas soovid abihõike jäädavalt kustutada?")
                            }}
                            addHelpRequestHandler={() =>
                                this.toggleModal("addHelpRequestModal")
                            }
                        /> : null}

                    {this.state.tabs === 3 ?
                        <AllServices
                            services={this.props.allServices} // services={this.state.services}
                            filterBy={this.state.filterBy}
                            getAllServicesWithSubFilter={this.getAllServicesWithSubFilter}
                            onFilterChange={e => {
                                this.setState({filterBy: e}, this.getServicesOnFilterChange);
                            }}
                        /> : null}

                    {this.state.tabs === 4 ?
                        <AllHelpRequests
                            helpRequests={this.props.allHelpRequests} //helpRequests={this.state.helpRequests}
                            filterBy={this.state.helpRequestfilterBy}
                            getAllHelpRequestsWithSubFilter={this.getAllHelpRequestsWithSubFilter}
                            onFilterChange={e => {
                                this.setState(
                                    {helpRequestfilterBy: e},
                                    this.getHelpRequestsOnFilterChange
                                );
                            }}
                        /> : null}

                    {this.state.tabs === 7 || this.state.tabs === 6 ?
                        <MyJobs
                            {...this.props}
                            key={this.props.location.key}
                            getOffers={this.getOffers}
                            offers={this.props.offers}
                            offerStatusNotificationHandler={this.offerStatusNotificationHandler}
                        /> : null}

                    {this.state.tabs === 8 ?
                        <PersonsInNeed ourPersonsInNeed={this.state.kovHelpRequests}/> : null}

                    {this.state.tabs === 9 ?
                        <ServiceProviders ourServiceProviders={this.state.kovServices}/> : null}

                    {this.state.tabs === 10 ?
                        <Statistics/> : null}

                    <Modal
                        size="lg"
                        className="modal-dialog-centered"
                        isOpen={this.state.editServiceModal}
                        toggle={() => this.toggleModal("editServiceModal")}
                    >
                        {/*{this.modalCloseButton("editServiceModal")}*/}
                        <EditService
                            serviceId={this.state.selectedService}
                            handleEditService={this.handleEditService}
                            closeModalHandler={() => this.toggleModal("editServiceModal")}
                        />
                    </Modal>

                    <Modal
                        size="lg"
                        className="modal-dialog-centered"
                        isOpen={this.state.editHelpRequesteModal}
                        toggle={() => this.toggleModal("editHelpRequesteModal")}
                    >
                        <EditHelpRequest
                            helpRequestId={this.state.helpRequestId}
                            handleEditHelpRequest={this.handleEditHelpRequest}
                            handleCloseModal={() => this.toggleModal("editHelpRequesteModal")}
                        />
                    </Modal>

                    <Modal
                        size="lg"
                        className="modal-dialog-centered"
                        isOpen={this.state.addServiceModal}
                        toggle={() => this.toggleModal("addServiceModal")}
                    >
                        <AddServiceContainer
                            handleServiceAdded={this.handleServiceAdded}
                            closeModalHandler={() => this.toggleModal("addServiceModal")}
                        />
                    </Modal>

                    <Modal
                        size="lg"
                        className="modal-dialog-centered"
                        isOpen={this.state.addHelpRequestModal}
                        toggle={() => this.toggleModal("addHelpRequestModal")}
                    >
                        <HopstiAddHelpRequest
                            handleHelpRequestAdded={this.handleHelpRequestAdded}
                            handleCloseModal={() => this.toggleModal("addHelpRequestModal")}
                            serviceCategory={this.props.location.state ? this.props.location.state.serviceCategory : null}
                            serviceSubCategory={this.props.location.state ? this.props.location.state.serviceSubCategory : null}
                        />
                    </Modal>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        spServices: state.services.spServices,
        allServices: state.services.allServices,
        pinHelpRequests: state.helpRequests.pinHelpRequests,
        allHelpRequests: state.helpRequests.allHelpRequests,
        offers: state.offers.offers,

        token: state.auth.token,
        user: state.auth.user,
        role: state.auth.user ? state.auth.user.role : null,
        isServiceProvider: state.auth.user
            ? state.auth.user.is_service_provider
            : false
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetSPServices: (services) => dispatch(serviceActions.onGetSPServices(services)),
        onGetAllServices: (services) => dispatch(serviceActions.onGetAllServices(services)),
        onGetPINHelpRequests: (helpRequests) => dispatch(helpRequestActions.onGetPINHelpRequests(helpRequests)),
        onGetAllHelpRequests: (helpRequests) => dispatch(helpRequestActions.onGetAllHelpRequests(helpRequests)),
        onGetOffers: (offers) => dispatch(offerActions.onGetOffers(offers)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HopstiDashboard));
