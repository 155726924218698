import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const registerStart = () => {
    return {
        type: actionTypes.REGISTER_START
    }
};

export const registerSuccess = () => {
    return {
        type: actionTypes.REGISTER_SUCCESS
    }
};

export const registerFail = err => {
    return {
        type: actionTypes.REGISTER_FAIL,
        error: err
    }
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const fbAuthStart = () => {
    return {
        type: actionTypes.FB_AUTH_START
    }
};

export const googleAuthStart = () => {
    return {
        type: actionTypes.FB_AUTH_START
    }
};

export const authSuccess = token => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token
    }
};

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};


export const updateIsServiceProvider = (user, isServiceProvider) => {
    return dispatch => {
        user.is_service_provider = isServiceProvider;
        if (isServiceProvider) user.role = "TEENUSEPAKKUJA"; else user.role = "ABIVAJAJA";
        dispatch(setUser(user));
    }
};

export const onRegister = token => {
    // console.log("Token value is: " + token);
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 1);
    localStorage.removeItem('token');
    localStorage.setItem('token', token);
    localStorage.setItem('expirationDate', expirationDate);
};


export const updateUserInfo = (userID, token) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = `Token ${token}`;
        axios
            .get("rest-auth/user/", {options: {name: userID}})
            .then(response => {
                const user = response.data;
                dispatch(setUser(user));
            })
            .catch(err => console.log(err));
    }

};


export const logout = () => {
    axios
        .post('/rest-auth/logout/')
        .then(res => {
            axios.defaults.headers.common['Authorization'] = null;
            localStorage.removeItem('token');
            localStorage.removeItem('expirationDate');
        })
        .catch(err => {
            console.log(err);
            console.log('Auth.js - logout failed!');
            localStorage.removeItem('token');
            localStorage.removeItem('expirationDate');
        });

    return {
        type: actionTypes.AUTH_LOGOUT,
    }
};

export const checkAuthTimeout = expirationDate => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationDate * 1000)
    }
};


export const setUser = user => {
    return {
        type: actionTypes.SET_USER,
        user: user
    }
};

export const addUserTokenToLocalStorage = (dispatch, response) => {
    const token = response.data.key;
    // console.log("Token value is: " + token);
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 24);
    localStorage.removeItem('token');
    localStorage.setItem('token', token);
    localStorage.setItem('expirationDate', expirationDate);
    dispatch(authSuccess(token));
    dispatch(checkAuthTimeout(3600));
    // console.log('Auth.js - login finished!');
};

export const getTokenFromLocalStorage = async () => {
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    return token
};

// Authenticate user, add token to localstorage
export const authLogin = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        axios.defaults.headers.common['Authorization'] = null;
        axios
            .post('login/', {
                "email": email,
                "password": password
            })
            .then(res => {
                const user = res.data;
                addUserTokenToLocalStorage(dispatch, res);
                dispatch(setUser(user));
            })
            .catch(err => {
                console.log(err);
                dispatch(authFail(err));
                console.log('Auth.js - login failed!');
            })
    }
};

// Authenticate user, add token to localstorage
export const onFacebookAuth = (response) => {
    return dispatch => {
        dispatch(fbAuthStart());
        axios.defaults.headers.common['Authorization'] = null;

        const data = {
            "access_token": response.accessToken
        };

        axios
            .post("/rest-auth/facebook/", data)
            .then(res => {
                addUserTokenToLocalStorage(dispatch, res);

                axios
                    .get("rest-auth/user/")
                    .then(response => {
                        const user = response.data;
                        dispatch(setUser(user));
                    })
                    .catch(err => console.log(err));
            })
            .catch(err => {
                console.log(err);
                dispatch(authFail(err));
                console.log('Auth.js - facebook login failed!');
            })
    }
};

// Authenticate user, add token to localstorage
export const onGoogleAuth = (response) => {
    return dispatch => {
        dispatch(googleAuthStart());
        axios.defaults.headers.common['Authorization'] = null;

        const data = {
            "access_token": response.accessToken
        };

        axios
            .post("/rest-auth/google/", data)
            .then(res => {
                addUserTokenToLocalStorage(dispatch, res);

                axios
                    .get("rest-auth/user/")
                    .then(response => {
                        const user = response.data;
                        dispatch(setUser(user));
                    })
                    .catch(err => console.log(err));
            })
            .catch(err => {
                console.log(err);
                dispatch(authFail(err));
                console.log('Auth.js - Google login failed!');
            })
    }
};

// Signing up new service provider
// Service provider is automatically logged in after registration
export const authSignupServiceProvider = (email) => {
    return dispatch => {
        dispatch(authStart());
        axios
            .post('api/v1/create-sp/', {
                "email": email
            })
            .then(res => {
                // console.log(res);
                const token = res.data.key;
                // console.log(token);
                const expirationDate = new Date();
                expirationDate.setHours(expirationDate.getHours() + 1);
                localStorage.setItem('token', token);
                localStorage.setItem('expirationDate', expirationDate);
                dispatch(authSuccess(token));
                dispatch(checkAuthTimeout(3600));
            })
            .catch(err => {
                console.log(err);
                dispatch(authFail(err))
            })
    }
};

export const authCheckState = () => {
    return dispatch => {
        getTokenFromLocalStorage().then((token) => {
            console.log("Checking auth state");
            if (token) {
                if (token === undefined) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('expirationDate');
                    dispatch(logout())
                } else {
                    const expirationDate = new Date(localStorage.getItem('expirationDate'));
                    if (expirationDate <= new Date()) {
                        localStorage.removeItem('token');
                        localStorage.removeItem('expirationDate');
                        dispatch(logout())
                    } else {
                        dispatch(authSuccess(token));
                        axios
                            .get("rest-auth/user/", {token: token})
                            .then(response => {
                                const user = response.data;
                                dispatch(setUser(user));
                            })
                            .catch(err => {
                                console.log("Error ", err);
                                console.log("Logging out... ");
                                localStorage.removeItem('token');
                                localStorage.removeItem('expirationDate');
                            });
                        dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
                    }
                }
            }
        })
    }
};

export const checkAuthState = () => {
    return dispatch => {
        getTokenFromLocalStorage().then((token) => {
            // console.log(token);
            if (token) {
                const expirationDate = new Date(localStorage.getItem('expirationDate'));
                // console.log(expirationDate);
                // console.log(new Date());
                if (expirationDate <= new Date()) {
                    // console.log("Token is expired. Logging out...");
                    dispatch(logout());
                } else {
                    // console.log("Token exists and is not expired.");
                    dispatch(authSuccess(token));
                    axios
                        .get("rest-auth/user/", {token: token})
                        .then(response => {
                            const user = response.data;
                            dispatch(setUser(user));
                            // console.log("All good. Continue using hopsti.")
                        })
                        .catch(err => {
                            // console.log("Got unauthorized response. Logging out... ");
                            dispatch(logout());
                        });
                }
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        })
    }
};