import React from "react";
import {withRouter} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
} from "reactstrap";
import axios from "../../axios";
import Parser from 'html-react-parser';


class ContentPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loginModal: false,
            registerModal: false,
            flatPageUrl: this.props.url,
            content: null
        };
    }

    componentDidMount() {
        this.getPageContent()
    }

    getPageContent = () => {

        let headers = null;

        if (this.state.flatPageUrl === "meist") {
            headers = {
                headers: {
                    'Authorization': null
                }
            }
        }
        axios
            .get("flatpages/" + this.state.flatPageUrl + "/", headers)
            .then(response => {
                if (response.data) {
                    console.log(response.data);
                    this.setState({
                        content: response.data.content
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    render() {
        return (
            <>
                <div className="main-content">
                    <section className="section section-lg">
                        <div className="pt-6 pb-4">
                            <Container fluid>
                                <Row className="row-grid justify-content-center">
                                    <Col lg="10">
                                        {this.state.content ? Parser(this.state.content) : null}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default withRouter(ContentPage);