/* eslint-disable */
import React from "react";
import TableContainer from "../../Table/TableContainer/TableContainer";
import ServiceGroupsFilter from "../../Filters/ServiceGroupsFilter";
import {Typeahead} from "react-bootstrap-typeahead";
import {Col, Row} from "reactstrap";
import * as services from "utils/services.js";
import AllServicesTable from "../../Table/TableContainer/AllServicesTable";

class AllServices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: [],
            searchTerm: "",
        };
    }

    render() {
        return <TableContainer>

            <ServiceGroupsFilter
                filterBy={this.props.filterBy}
                onFilterChange={this.props.onFilterChange}
            />

            {/*<Row className="justify-content-center pt-2 pb-4">*/}
            {/*    <Col className="text-center" lg="4">*/}
            {/*        <Typeahead*/}
            {/*            id="hopsti-typehead"*/}
            {/*            minLength={2}*/}
            {/*            className="search-container"*/}
            {/*            highlightOnlyResult*/}
            {/*            labelKey={option => `${option.name} (${option.found_services} vastet)`}*/}
            {/*            filterBy={["name", "synonyms"]}*/}
            {/*            emptyLabel="Ei leidnud vastet"*/}
            {/*            onInputChange={(searchTerm) => {*/}
            {/*                services.handleSearchSubGroup(searchTerm).then((options => {*/}
            {/*                    this.setState({options: options})*/}
            {/*                }))*/}
            {/*            }}*/}
            {/*            onChange={(selectedResult) => this.props.getAllServicesWithSubFilter(selectedResult[0])}*/}
            {/*            options={this.state.options}*/}
            {/*            placeholder="Sisesta märksõna..."*/}
            {/*        />*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <AllServicesTable items={this.props.services}/>
        </TableContainer>;
    }
}

export default AllServices;