import React from "react";
import {withRouter} from 'react-router-dom';
import ReactGA from 'react-ga';
import {
    Container,
    Row,
    Col,
    Button, Modal, CardFooter, CardBody, Card
} from "reactstrap";
import {withTranslation, Trans} from 'react-i18next';

import IndexHeader from "components/Headers/IndexHeader.jsx";
// import YouTube from "components/YouTube/YouTube.jsx";
// import {Link} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import axios from "../../axios";
import HopstiLogin from "../Login/HopstiLogin";
import HopstiRegisterUser from "../Register/HopstiRegisterUser";
import ServiceGroupsFilter from "../Filters/ServiceGroupsFilter";
import * as services from "../../utils/services";
import PublicServicesTable from "../Table/TableContainer/PublicServicesTable";


class Landing extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loginModal: false,
            registerModal: this.props.isServiceProvider !== undefined ? true : null,
            isServiceProvider: this.props.isServiceProvider,
            searchTerm: "",
            email: "",
            options: [],
            newsletterSuccessMessage: false,
            newsletterErrorMessage: false,
            filterBy: [],
            validate: {
                emailState: 'has-danger',
            },
            services: []
        };
    }

    componentDidMount() {
        ReactGA.initialize('UA-150997639-1');
        ReactGA.pageview('/landing');

        ReactGA.ga('send', {
            hitType: 'event',
            eventCategory: 'Landing',
            eventAction: 'landingOpen',
            eventLabel: 'Open landing page'
        });
        this.getPublicServices();
    }

    getPublicServices = () => {
        services.getPublicServices(this.state.filterBy).then(services => {
            this.setState({
                services: services
            })
        });
    };

    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const {validate} = this.state;
        if (emailRex.test(e.target.value)) {
            validate.emailState = 'has-success'
        } else {
            validate.emailState = 'has-danger'
        }
        this.setState({validate})
    }

    handleChange = async event => {
        const {target} = event;
        const value = target.value;
        {
            const {name} = target;
            await this.setState({
                [name]: value
            });
        }
    };

    handleNewsletterSubmit = e => {
        e.preventDefault();

        if (this.state.validate.emailState === 'has-success') {

            ReactGA.ga('send', {
                hitType: 'event',
                eventCategory: 'Newsletter',
                eventAction: 'joinNewsletter',
                eventLabel: 'Joined newsletter'
            });

            axios
                .post("/newsletter/" + this.state.email + "/", {}, {
                        headers: {
                            'Authorization': null
                        }
                    }
                )
                .then(response => {
                    this.setState({
                        email: "",
                        newsletterSuccessMessage: true,
                        newsletterErrorMessage: false
                    })
                })
                .catch(err => {
                    console.log(err)
                });
        } else {
            this.setState({
                newsletterErrorMessage: true
            })
        }
    };

    registerUserHandler = isServiceProvider => {
        this.setState({
            isServiceProvider: isServiceProvider
        });

        if (isServiceProvider) {
            ReactGA.ga('send', {
                hitType: 'event',
                eventCategory: 'Registration',
                eventAction: 'registerStep1TP',
                eventLabel: 'Service provider registration'
            });
        } else {
            ReactGA.ga('send', {
                hitType: 'event',
                eventCategory: 'Registration',
                eventAction: 'registerStep1AV',
                eventLabel: 'Person in need registration'
            });
        }

        this.setState({registerModal: !this.state.registerModal})
    };

    cookieConsentAccept = () => {
        console.log("User accepted cookie consent.")
    };

    render() {
        let newsletterSuccessMessage = <p
            className="newsLetterSignUpMesg">{this.props.t('landing.newsletter-success')}.</p>;
        let newsletterErrorMessage = <p
            className="newsLetterSignUpMesg">{this.props.t('landing.newsletter-error')}!</p>;
        return (
            <>
                <div className="main-content">
                    <IndexHeader handleRegisterClick={() => this.setState({registerModal: !this.state.registerModal})}/>

                    <section className="section section-lg pb-5 pt-6">
                        <Container fluid>
                            <Row className="justify-content-center text-center pb-4">
                                <Col md="4" xs="8">
                                    <h3 className="display-3 hopsti-green pb-2">
                                        {this.props.t('landing.check-out-services')}
                                    </h3>
                                </Col>
                            </Row>
                            <Row className="row-grid d-flex justify-content-center pb-0">
                                <Col className="col-sm-12 landing-icon-section">
                                    <ServiceGroupsFilter
                                        filterBy={this.state.filterBy}
                                        onFilterChange={e => {
                                            this.setState({filterBy: e}, this.getPublicServices);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="row-grid d-flex justify-content-center mt-0">
                                <Col sm={10}>
                                    <PublicServicesTable items={this.state.services}
                                                         toggle={() => this.setState({loginModal: !this.state.loginModal})}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className="section section-lg">
                        <div className="pb-5">
                            <Container fluid>
                                <Row className="row-grid justify-content-center">
                                    <Col className="text-center" lg={10}>
                                        <h2 className="display-3 hopsti-green mb-5">
                                            {this.props.t('landing.hopsti-users')}
                                        </h2>
                                        <Row>
                                            <Col xl={3} lg={6}  md={6} sm={12} className="video-container pt-2">
                                                <Card>
                                                    <img
                                                        alt="Kodu"
                                                        className="card-img-top"
                                                        src={require("assets/img/theme/teenusepakkuja.jpg")}
                                                    />
                                                    <CardBody className="pb-0">
                                                        <Trans>
                                                            <div className="h2 mb-0 card-title">Abivajaja</div>
                                                            <p className="h3 card-text">Otsid abi või abilist?</p>
                                                            <p className="mt-4 card-text">Liitu ja kasuta <strong
                                                                className="font-weight-900">TASUTA!</strong></p>
                                                            <ul className="text-left mb-0">
                                                                <li>Valikus 130+ teenuste kategooriat.</li>
                                                                <li>Sisesta oma abivajaduse kirjeldus ehk abihõige.</li>
                                                                <li>Vali sulle tehtud pakkumiste seast sobiv.</li>
                                                                <li>Anna tegijale tagasisidet.</li>
                                                            </ul>
                                                        </Trans>
                                                    </CardBody>
                                                    <CardFooter>
                                                        <Button
                                                            size="md"
                                                            className="btn btn-hopsti-pink clickable"
                                                            onClick={() => this.registerUserHandler(false)}
                                                        >
                                                            {this.props.t('buttons.join-now')}
                                                        </Button>
                                                    </CardFooter>
                                                </Card>
                                            </Col>
                                            <Col xl={3} lg={6}  md={6} sm={12} className="video-container pt-2">
                                                <Card>
                                                    <img
                                                        alt="Kodu"
                                                        className="card-img-top"
                                                        src={require("assets/img/theme/abivajaja.jpg")}
                                                    />
                                                    <CardBody className="pb-0">
                                                        <Trans>
                                                            <div className="h2 mb-0 card-title">Abivajaja</div>
                                                            <p className="h3 card-text">Hoolitsed abivajaja eest?</p>
                                                            <p className="mt-4 card-text">Liitu ja kasuta <strong
                                                                className="font-weight-900">TASUTA!</strong></p>
                                                            <ul className="text-left mb-0">
                                                                <li>Valikus 130+ teenuste kategooriat.</li>
                                                                <li>Sisesta oma abivajaduse kirjeldus ehk abihõige.</li>
                                                                <li>Vali sulle tehtud pakkumiste seast sobiv.</li>
                                                                <li>Anna tegijale tagasisidet.</li>
                                                                <li>Teavita omavalitsust oma abivajadusest</li>
                                                            </ul>
                                                        </Trans>
                                                    </CardBody>
                                                    <CardFooter>
                                                        <Button
                                                            size="md"
                                                            className="btn btn-hopsti-pink"
                                                            onClick={() => this.registerUserHandler(false)}
                                                        >
                                                            {this.props.t('buttons.join-now')}
                                                        </Button>
                                                    </CardFooter>
                                                </Card>
                                            </Col>
                                            <Col xl={3} lg={6}  md={6} sm={12} className="video-container pt-2">
                                                <Card>
                                                    <img
                                                        alt="Kodu"
                                                        className="card-img-top"
                                                        src={require("assets/img/theme/teenusepakkuja.png")}
                                                    />
                                                    <CardBody className="pb-0">
                                                        <Trans>
                                                            <div className="h2 mb-0 card-title">Teenusepakkuja</div>
                                                            <p className="h3 card-text">Soovid pakkuda abi ja
                                                                lahendusi?</p>
                                                            <p className="mt-4 card-text">Liitu <strong
                                                                className="font-weight-900">TASUTA</strong> aasta
                                                                lõpuni!</p>
                                                            <ul className="text-left mb-0">
                                                                <li>Valikus 130+ teenuste kategooriat.</li>
                                                                <li>Sisesta oma teenus koos hinna ja tingimustega.</li>
                                                                <li>Tutvu abihõigetega ja korralda teenuse osutamine.
                                                                </li>
                                                                <li>Leia uusi kliente.</li>
                                                            </ul>
                                                        </Trans>
                                                    </CardBody>
                                                    <CardFooter>
                                                        <Button
                                                            size="md"
                                                            className="btn btn-hopsti-green"
                                                            onClick={() => this.registerUserHandler(true)}
                                                        >
                                                            {this.props.t('buttons.join-now')}
                                                        </Button>
                                                    </CardFooter>
                                                </Card>
                                                {/*<Card className="border-0">*/}
                                                {/*    <YouTube*/}
                                                {/*        videoId="Q07j5AGWKGs"*/}
                                                {/*    />*/}
                                                {/*</Card>*/}
                                                {/*<span className="heading text-muted">Teenuspakkuja</span>*/}
                                            </Col>
                                            <Col xl={3} lg={6} md={6} sm={12} className="video-container pt-2">
                                                <Card>
                                                    <img
                                                        alt="Kodu"
                                                        className="card-img-top"
                                                        src={require("assets/img/theme/kov.jpg")}
                                                    />
                                                    <CardBody className="pb-0">
                                                        <Trans>
                                                            <div className="h2 mb-0 card-title">Kohalik omavalitsus
                                                            </div>
                                                            <p className="h3 card-text">Halda oma piirkonna
                                                                abivajadusi!</p>
                                                            <ul className="text-left mb-0">
                                                                <li>Mugav tööriist abivajaduste menetlemiseks.</li>
                                                                <li>Sinu piirkonna abivajajate loetelu.</li>
                                                                <li>Sinu piirkonna teenusepakkujate loetelu.</li>
                                                                <li>Statistika.</li>
                                                            </ul>
                                                        </Trans>
                                                    </CardBody>
                                                    <CardFooter>
                                                        <Button
                                                            size="md"
                                                            className="btn btn-hopsti-green"
                                                            href="/kontaktid"
                                                        >
                                                            {this.props.t('buttons.ask-more')}
                                                        </Button>
                                                    </CardFooter>
                                                </Card>
                                                {/*<Card className="border-0">*/}
                                                {/*    <YouTube*/}
                                                {/*        videoId="sBws8MSXN7A"*/}
                                                {/*    />*/}
                                                {/*</Card>*/}
                                                {/*<span className="heading text-muted">Kohalik omavalitsus</span>*/}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                    <section className="pt-5 pb-5 bg-hopsti-pink">
                        <Container fluid>
                            <Row className="justify-content-center text-center">
                                <Col md="12">
                                    <h1 className="display-3 text-white hopsti-pink pb-2">
                                        {this.props.t('landing.subscribe-newsletter')}
                                    </h1>
                                    <h2 className="font-weight-light text-white pb-3">
                                        {this.props.t('landing.newsletter-heading')}
                                    </h2>


                                    <Row className="justify-content-center text-center">
                                        <Col md="3" xs="8">
                                            {this.state.newsletterSuccessMessage ? newsletterSuccessMessage : null}
                                            {this.state.newsletterErrorMessage ? newsletterErrorMessage : null}
                                            <div className="input-group">
                                                <input
                                                    placeholder={this.props.t('landing.insert-email')}
                                                    value={this.state.email}
                                                    type="text"
                                                    name="email"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        this.validateEmail(e);
                                                        this.handleChange(e);
                                                    }}
                                                />
                                                <span className="input-group-btn">
                                                    <Button onClick={this.handleNewsletterSubmit}
                                                            className="btn btn-hopsti-green newsletter-btn"
                                                            type="button">{this.props.t('buttons.join-newsletter')}</Button>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
                <Modal
                    size="md"
                    className="modal-dialog-centered"
                    isOpen={this.state.loginModal}
                    toggle={() => this.setState({loginModal: !this.state.loginModal})}
                >
                    <HopstiLogin/>
                </Modal>
                <Modal
                    size="md"
                    className="modal-dialog-centered"
                    isOpen={this.state.registerModal}
                    toggle={() => this.setState({registerModal: !this.state.registerModal})}
                >
                    <HopstiRegisterUser
                        isServiceProvider={this.state.isServiceProvider}
                    />
                </Modal>
                <CookieConsent
                    location="bottom"
                    buttonText="Nõustun"
                    cookieName="myAwesomeCookieName2"
                    style={{background: "#2B373B"}}
                    buttonStyle={{color: "white", background: "#3DBDB4", fontSize: "13px"}}
                    expires={150}
                    onAccept={() => this.cookieConsentAccept()}
                >
                    See veebileht kasutab parima võimaliku kogemuse kasutamiseks küpsiseid.{" "}
                    <span style={{fontSize: "10px"}}>
                        Jätkates kodulehe kasutamist, nõustute küpsistega vastavalt küpsiste (<a target="_blank"
                                                                                                 href="https://hopsti.ee/media/pdfs/Kupsiste_kasutamise_tingimused.pdf"
                                                                                                 rel="noopener noreferrer">kasutuspoliitikale</a>).
                    </span>
                </CookieConsent>
            </>
        );
    }
}

export default withRouter((withTranslation('common')(Landing)));