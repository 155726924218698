import React from "react";
import classnames from "classnames";
import axios from "../../../axios";
import Select2 from "react-select2-wrapper";
import * as helpers from "utils/helpers.js";
import * as services from "utils/services.js";
import NotificationAlert from "react-notification-alert";
import {
    Alert,
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroup,
    Row,
    Col, CardHeader
} from "reactstrap";
import ImageUploader from "components/ImageUploader/ImageUploader";

import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import LocationInput from '../../GooglePlacesAutocomplete/HopstiGooglePlacesAutocomplete';
import * as actions from "../../../store/actions/auth";

class HelpRequestItem extends React.Component {
    state = {
        showSuccessMessage: false,
        showErrorMessage: false,
        title: "",
        quantity: "",
        unit: "",
        units: [],
        info: "",
        when: "",
        acceptVolunteers: false,
        isActive: true,
        serviceGroups: [],
        serviceGroup: this.props.serviceCategory ? this.props.serviceCategory : "",
        helpRequestAdded: false,
        serviceSubGroups: [],
        serviceSubGroup: this.props.serviceSubCategory ? this.props.serviceSubCategory : "1",
        files: [],
        customStyles: {},
        canClick: true,
        isKovAllowed: false,
        showKovSection: false,
        address: "",
        latitude: "",
        longitude: "",
        municipality: "",
        county: "",
        socialSecurityCode: "",
        showAddress: false,
        showCode: false
    };

    componentDidMount() {
        if (this.props.helpRequestId) {
            this.getHelpRequestDetails(this.props.helpRequestId);
        } else {
            this.getServicGroupsWithSubGroupsAndUnits();
        }
        if (!this.props.isKovSession) {
            this.setState({ address: this.props.address ? this.props.address : "" })

            if (!this.props.address || this.props.address === "") {
                this.setState({ showAddress: true })
            }

            if (!this.props.user.code || this.props.user.code === "") {
                this.setState({ showCode: true })
            }

            if (this.props.user.municipality && this.props.user.municipality !== "") {
                this.getKovList(this.props.user.municipality);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.getServicGroupsWithSubGroupsAndUnits();
        }
    }

    handleFileAdded = (file, size) => {
        if (size > 10485760) {
            helpers.notify(
                "warning",
                "Fail liiga suur!",
                "Pildi faili suurus peab olema alla 10MB!",
                this
            );
            return;
        }
        services.postFileToServer(file.file, this.props.userId).then(
            id => {
                let addedFile = { ...file, id: id }
                this.setState({ files: [...this.state.files, addedFile] });
            }
        );
    }

    handleFileDeleted = (id) => {
        services.deleteFileFromServer(id).then(
            id => {
                let oldFiles = [...this.state.files];
                oldFiles.splice(id, 1);
                this.setState({ files: oldFiles });
            }
        );
    }

    getKovList = (adminLevel2) => {
        axios
            .get("/municipalities/")
            .then(response => {
                if (response.data) {
                    if (response.data.includes(adminLevel2)) {
                        this.setState({ showKovSection: true })
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    handleChange = async event => {
        const { target } = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [name]: value
        }, () => {
            this.validate()
        });
    };

    validate = () => {
        let allValid = true;

        let newState = { ...this.state.customStyles };

        if (this.state.title === "" || this.state.title.length > 50) {
            newState.titleState = "invalid";
            allValid = false;
        } else {
            newState.titleState = "valid";
        }
        if (this.state.info === "") {
            newState.infoState = "invalid";
            allValid = false;
        } else {
            newState.infoState = "valid";
        }
        if (this.state.quantity === "") {
            newState.quantityState = "invalid";
            allValid = false;
        } else {
            newState.quantityState = "valid";
        }
        if (this.state.unit === "") {
            newState.unitState = "invalid";
            allValid = false;
        } else {
            newState.unitState = "valid";
        }
        if (this.state.serviceGroup === "") {
            newState.serviceGroupState = "invalid";
            allValid = false;
        } else {
            newState.serviceGroupState = "valid";
        }
        if (this.state.serviceSubGroup === "") {
            newState.serviceSubGroupState = "invalid";
            allValid = false;
        } else {
            newState.serviceSubGroupState = "valid";
        }
        if (!this.props.isKovSession) {
            if (this.state.address === "") {
                allValid = false;
            }
            if (this.state.isKovAllowed && this.state.showCode && this.state.socialSecurityCode === "") {
                newState.socialSecurityCodeState = "invalid";
                allValid = false;
            } else {
                newState.socialSecurityCodeState = "valid";
            }
        }

        this.setState({ customStyles: newState })
        return allValid;
    }

    clicked = false;

    checkAddress = (address, addHelpRequest) => {
        if ((!address || address === "") && !this.props.isKovSession) {
            const user = {
                ...this.props.user, ...{
                    address: this.state.address,
                    longitude: this.state.longitude,
                    latitude: this.state.latitude,
                    county: this.state.county,
                    municipality: this.state.municipality
                }
            };

            const data = {
                first_name: user.first_name,
                last_name: user.last_name,
                code: user.code,
                address: user.address,
                latitude: user.latitude,
                longitude: user.longitude,
                municipality: user.municipality,
                county: user.county,
                sp_address: user.sp_address,
                sp_latitude: user.sp_latitude,
                sp_longitude: user.sp_longitude,
                sp_municipality: user.sp_municipality,
                sp_county: user.sp_municipality,
                sp_name: user.sp_name,
                sp_email: user.sp_email,
                sp_phone: user.sp_phone,
                sp_radius: user.sp_radius
            };
            axios
                .put("/rest-auth/user/", data)
                .then(response => {
                    axios
                        .get("/rest-auth/user/")
                        .then(response => {
                            this.props.setUser(response.data);
                            addHelpRequest();
                        })
                        .catch(err => {

                        });
                })
                .catch(err => {
                    helpers.notify(
                        "danger",
                        "Uuendamine ebõnnestus",
                        "Kontaktandmete uuendamine ebaõnnestus!",
                        this
                    );
                });
        } else {
            addHelpRequest();
        }

    }

    //Move to add hr
    handleSubmit = e => {
        e.preventDefault();
        if (this.validate() && !this.clicked) {
            this.clicked = true;
            let filesIds = this.state.files.map(file => file.id);
            const data = {
                title: this.state.title,
                quantity: this.state.quantity,
                unit: Number.parseInt(this.state.unit),
                info: this.state.info,
                accept_volunteers: this.state.acceptVolunteers,
                is_valid: this.state.isActive,
                sub_service_id: [Number.parseInt(this.state.serviceSubGroup)],
                file: filesIds,
                is_kov_allowed: this.props.isKovSession ? true : this.state.isKovAllowed
            };
            if (this.state.showCode && this.state.socialSecurityCode !== "") {
                axios
                    .put("/update-code/", { code: this.state.socialSecurityCode })
                    .then(response => {
                    })
                    .catch(err => {
                        console.log("HopstiAddService.js - " + err);
                    }
                    );
            }
            this.checkAddress(this.props.address, () => {
                const userIdToUrl = this.props.isKovSession ? this.props.userIdFromKov : this.props.user.pk;
                axios
                    .post("help-requests/post/" + userIdToUrl + "/", data)
                    .then(response => {
                        this.setState({
                            showSuccessMessage: true,
                            showErrorMessage: false,
                            helpRequestAdded: true
                        }, this.props.handleHelpRequestAdded());
                    })
                    .catch(err => {
                        console.log("HopstiAddService.js - " + err);
                        this.setState({
                            showErrorMessage: true,
                            showSuccessMessage: false,
                            helpRequestAdded: false
                        });
                    }).finally(() => {
                        this.clicked = false;
                        this.setState({ canClick: true });
                    }
                    );
                ;
            });
        } else {
            this.clicked = false;
            this.setState({
                showErrorMessage: true,
                canClick: true
            });
        }
    };

    //Move to update
    handleUpdateSubmit = e => {
        e.preventDefault();
        if (this.validate() && !this.clicked) {
            let filesIds = this.state.files.map(file => file.id);
            const data = {
                id: this.props.helpRequestId,
                title: this.state.title,
                quantity: this.state.quantity,
                unit: Number.parseInt(this.state.unit),
                info: this.state.info,
                accept_volunteers: this.state.acceptVolunteers,
                is_valid: this.state.isActive,
                sub_service_id: [Number.parseInt(this.state.serviceSubGroup)],
                file: filesIds,
                is_kov_allowed: this.state.isKovAllowed
            };

            axios
                .put("/help-requests/update/" + this.props.helpRequestId + "/", data)
                .then(response => {
                    this.setState({
                        showErrorMessage: false,
                        showSuccessMessage: true
                    });
                    this.props.handleEditHelpRequest("UPDATE");
                })
                .catch(err => {
                    this.setState({
                        showErrorMessage: true,
                        showSuccessMessage: false
                    });
                }).finally(() => {
                    this.clicked = false;
                    this.setState({ canClick: true });
                });
        } else {
            this.clicked = false;
            this.setState({
                showErrorMessage: true,
                canClick: true
            });
        }
    };

    getServicGroupsWithSubGroupsAndUnits = () => {
        services.getAllServiceGroups().then(serviceGroups => {
            this.setState({ serviceGroups });
            if (this.props.serviceCategory) {
                services.getServiceSubGroups(this.props.serviceCategory ? this.props.serviceCategory : serviceGroups[0].id).then(serviceSubGroups => {
                    this.setState({
                        serviceSubGroups,
                        serviceGroup: this.props.serviceCategory ? this.props.serviceCategory : serviceGroups[0].id,
                        serviceSubGroup: this.props.serviceSubCategory ? this.props.serviceSubCategory : serviceSubGroups[0].id
                    });
                }
                );
            }
        });
        services.getUnits().then((units) => this.setState({ units: units }));
    };

    // ToDo Move to update
    getHelpRequestDetails = () => {
        axios
            .get("/help-requests/" + this.props.helpRequestId)
            .then(response => {
                if (response.data) {
                    const responseHelpRequest = response.data;
                    this.converthelprequestToState(responseHelpRequest);
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    // ToDo Move to update
    converthelprequestToState = (responseHelpRequest) => {
        this.setState({
            title: responseHelpRequest.title,
            quantity: responseHelpRequest.quantity,
            unit: responseHelpRequest.unit.id,
            info: responseHelpRequest.info,
            when: responseHelpRequest.when,
            acceptVolunteers: responseHelpRequest.accept_volunteers,
            isKovAllowed: responseHelpRequest.is_kov_allowed,
            files: responseHelpRequest.files ? responseHelpRequest.files.map(file => ({
                id: file.id,
                preView: file.original
            })) : []
        }, () => {
            services.getAllServiceGroups().then(serviceGroups => {
                services.getServiceSubGroups(responseHelpRequest.sub_service_name[0].service_group.id).then(serviceSubGroups => {
                    console.log(responseHelpRequest.sub_service_name[0].service_group.id);
                    this.setState({
                        serviceGroups,
                        serviceSubGroups,
                        serviceGroup: responseHelpRequest.sub_service_name[0].service_group.id,
                        serviceSubGroup: responseHelpRequest.sub_service_name[0].id,
                    });
                }
                );
            });
            services.getUnits().then((units) => this.setState({ units: units }));

        });
    };

    handleAddress = (latLnglat, latLnglng, address, adminLevel1, adminLevel2) => {
        this.setState({
            address: address,
            latitude: latLnglat,
            longitude: latLnglng,
            municipality: adminLevel2,
            county: adminLevel1
        });

        if (adminLevel2 && adminLevel2 !== "") {
            this.getKovList(adminLevel2);
        }
    };

    render() {
        let successMessage = null;
        let errorMessage = null;
        let buttonSection = null;
        let disableCategorySelections = false;
        let onDisabledClick = () => {
        };
        let kovSection = null;
        let addressSection = null;
        let socialSecurityCodeSection = null;

        if (this.state.showSuccessMessage) {
            successMessage = (
                <Alert color="success">
                    <strong>Super!</strong> Abihõige lisatud!
                </Alert>
            );
        }

        if (this.state.showErrorMessage) {
            errorMessage = (
                <Alert color="danger">
                    <strong>Viga!</strong> Lisamine ebaõnnestus, palun kontrolli andmeid.
                </Alert>
            );
        }

        if (this.props.helpRequestId) {
            disableCategorySelections = true;
            onDisabledClick = () => {
                helpers.notify(
                    "warning",
                    this.props.t('add-new-help-request.edit-not-allowed'),
                    this.props.t('add-new-help-request.add-new-hr-to-change-category'),
                    this
                );
            };
            buttonSection = (
                <div className="text-center mt-4">
                    <Button
                        onClick={(e) => {
                            this.setState({ canClick: false });
                            this.handleUpdateSubmit(e)
                        }}
                        className="mt-4 btn-hopsti-green"
                        type="button"
                        disabled={!this.state.canClick}
                    >
                        {this.props.t('add-new-help-request.save-changes')}
                    </Button>
                    <Button onClick={this.props.handleCloseModal} className="mt-4 btn-hopsti-pink" type="button">
                        {this.props.t('add-new-help-request.close')}
                    </Button>
                </div>
            );
        } else {
            buttonSection = (
                <div className="text-center mt-4">
                    <Button
                        onClick={(e) => {
                            this.setState({ canClick: false });
                            this.handleSubmit(e)
                        }}
                        className="mt-4 btn-hopsti-green"
                        type="button"
                        disabled={!this.state.canClick}
                    >
                        {this.props.t('add-new-help-request.add-help-request')}
                    </Button>
                    <Button onClick={this.props.handleCloseModal} className="mt-4 btn-hopsti-pink" type="button">
                        {this.props.t('add-new-help-request.close')}
                    </Button>
                </div>
            );
        }

        if (this.state.showAddress) {
            addressSection = (
                <FormGroup>
                    <label className="form-control-label hopsti-green">
                        {this.props.t('add-new-help-request.address')}
                    </label>

                    <LocationInput
                        address={this.state.address}
                        handleAddress={this.handleAddress}
                    />

                </FormGroup>
            );
        }
        if (this.state.showKovSection) {
            kovSection = (
                <FormGroup
                    className={classnames({
                        focused: this.state.focusedIsKovAllowed
                    })}
                >
                    <InputGroup className="input-group-merge mb-3">
                        <div className="custom-control custom-checkbox">
                            <Input
                                className="custom-control-input"
                                id="customCheck4"
                                type="checkbox"
                                name="isKovAllowed"
                                value={this.state.isKovAllowed}
                                checked={this.state.isKovAllowed}
                                onChange={e => {
                                    this.handleChange(e);
                                }}
                            />
                            <label
                                className="custom-control-label hopsti-green"
                                htmlFor="customCheck4"
                            >
                                {this.props.t('add-new-help-request.kov-checkbox')}
                            </label>
                        </div>
                    </InputGroup>
                </FormGroup>
            );
            if (this.state.isKovAllowed && this.state.showCode) {
                socialSecurityCodeSection = (
                    <FormGroup
                        className={classnames({
                            focused: this.state.focusedCode
                        })}
                    >
                        <label className="form-control-label">
                            {this.props.t('add-new-help-request.ssid')}
                        </label>
                        <InputGroup className="mb-3">
                            <Input
                                placeholder={this.props.t('add-new-help-request.ssid')}
                                type="text"
                                value={this.state.socialSecurityCode}
                                name="socialSecurityCode"
                                onFocus={() => this.setState({ focusedCode: true })}
                                onBlur={() => this.setState({ focusedCode: false })}
                                onChange={this.handleChange}
                                valid={
                                    this.state.customStyles.socialSecurityCodeState === "valid"
                                }
                                invalid={
                                    this.state.customStyles.socialSecurityCodeState ===
                                    "invalid"
                                }
                            />
                        </InputGroup>
                    </FormGroup>
                );

            }
        }

        return (
            <>
                {/*<Container className="">*/}
                <Row className="justify-content-center">
                    <div className="rna-wrapper contactAlert">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Col lg="12" md="12">
                        <CardHeader>
                            <Row>
                                <Col><h2 className="hopsti-green">{this.props.t('add-new-help-request.help-request-details')}</h2></Col>
                                <Col>
                                    <div className="text-right">
                                        <i className="fas fa-2x fa-times clickable"
                                            onClick={this.props.handleCloseModal} />
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Card className="bg-secondary border-0 mb-0">
                            <CardBody className="px-lg-4 py-lg-4">
                                {successMessage}
                                {errorMessage}
                                <Form role="form">
                                    <Row className="justify-content-center">
                                        <Col lg="6" md="6">
                                            <label className="form-control-label hopsti-green">{this.props.t('add-new-help-request.title')}</label>
                                            <FormGroup
                                                className={classnames({
                                                    focused: this.state.focusedTitle
                                                })}
                                            >
                                                <InputGroup
                                                    className="mb-3">
                                                    <Input
                                                        placeholder={this.props.t('add-new-help-request.title-placeholder')}
                                                        type="text"
                                                        name="title"
                                                        value={this.state.title}
                                                        onFocus={() => this.setState({ focusedTitle: true })}
                                                        onBlur={() => this.setState({ focusedTitle: false })}
                                                        onChange={e => {
                                                            this.handleChange(e);
                                                        }}
                                                        valid={
                                                            this.state.customStyles.titleState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.customStyles.titleState ===
                                                            "invalid"
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        {this.props.t('add-new-help-request.title-error')}
                                                    </div>
                                                </InputGroup>
                                            </FormGroup>
                                            {/* Teenuse kategooriad */}
                                            <FormGroup
                                                className={classnames({
                                                    focused: this.state.focusedServiceGroups
                                                })}
                                                onClick={e => {
                                                    onDisabledClick();
                                                }}
                                            >
                                                <label className="form-control-label hopsti-green">
                                                    {this.props.t('add-new-help-request.category')}
                                                </label>
                                                <Input
                                                    id="exampleFormControlSelect3"
                                                    type="select"
                                                    disabled={disableCategorySelections}
                                                    value={this.state.serviceGroup}
                                                    onChange={e => {
                                                        this.setState({ serviceGroup: e.target.value }, () => services.getServiceSubGroups(this.state.serviceGroup).then(serviceSubGroups => {
                                                            this.setState({ serviceSubGroups, serviceSubGroup: "" }, () => this.validate());
                                                        }));
                                                    }}
                                                    valid={
                                                        this.state.customStyles.serviceGroupState === "valid"
                                                    }
                                                    invalid={
                                                        this.state.customStyles.serviceGroupState ===
                                                        "invalid"
                                                    }
                                                >
                                                    <option key="aaa" selected value="" disabled> Vali kategooria </option>
                                                    {this.state.serviceGroups.map(serviceGroup => {
                                                        return (
                                                            <option
                                                                key={serviceGroup.id}
                                                                value={serviceGroup.id}
                                                            >
                                                                {serviceGroup.name}
                                                            </option>
                                                        );
                                                    })}
                                                </Input>
                                            </FormGroup>

                                            <label className="form-control-label hopsti-green">{this.props.t('add-new-help-request.description')}</label>
                                            <FormGroup
                                                className={classnames({
                                                    focused: this.state.focusedInfo
                                                })}
                                            >
                                                <InputGroup>
                                                    <Input
                                                        placeholder={this.props.t('add-new-help-request.description')}
                                                        type="textarea"
                                                        name="info"
                                                        value={this.state.info}
                                                        onFocus={() => this.setState({ focusedInfo: true })}
                                                        onBlur={() => this.setState({ focusedInfo: false })}
                                                        onChange={e => {
                                                            this.handleChange(e);
                                                        }}
                                                        valid={
                                                            this.state.customStyles.infoState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.customStyles.infoState ===
                                                            "invalid"
                                                        }
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                            <div className="ml-0">
                                                <label className="form-control-label hopsti-green">{this.props.t('add-new-help-request.pictures')}</label>
                                                <ImageUploader className="ml-0" files={this.state.files}
                                                    handleFileAdded={this.handleFileAdded}
                                                    handleFileDeleted={this.handleFileDeleted} />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <Row>
                                                <Col md="6">
                                                    <label className="form-control-label hopsti-green">{this.props.t('add-new-help-request.quantity')}</label>
                                                    <FormGroup
                                                        className={classnames({
                                                            focused: this.state.focusedQuantity
                                                        })}
                                                    >
                                                        <InputGroup
                                                            className="mb-3">
                                                            <Input
                                                                placeholder={this.props.t('add-new-help-request.quantity')}
                                                                type="number"
                                                                name="quantity"
                                                                value={this.state.quantity}
                                                                onFocus={() =>
                                                                    this.setState({ focusedQuantity: true })
                                                                }
                                                                onBlur={() =>
                                                                    this.setState({ focusedQuantity: false })
                                                                }
                                                                onChange={e => {
                                                                    this.handleChange(e);
                                                                }}
                                                                valid={
                                                                    this.state.customStyles.quantityState === "valid"
                                                                }
                                                                invalid={
                                                                    this.state.customStyles.quantityState ===
                                                                    "invalid"
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    {/* Ühik */}
                                                    <FormGroup
                                                        className={classnames({
                                                            focused: this.state.focusedUnit
                                                        })}
                                                    >
                                                        <label
                                                            className="form-control-label hopsti-green">{this.props.t('add-new-help-request.unit')}</label>
                                                        <Input
                                                            bsSize="md"
                                                            id="exampleFormControlSelect8"
                                                            type="select"
                                                            name="unit"
                                                            selected={this.state.unit}
                                                            value={this.state.unit}
                                                            onChange={e => {
                                                                this.handleChange(e);
                                                            }}
                                                            valid={
                                                                this.state.customStyles.unitState === "valid"
                                                            }
                                                            invalid={
                                                                this.state.customStyles.unitState ===
                                                                "invalid"
                                                            }
                                                        >
                                                            <option key="aaa" selected value="" disabled> Vali ühik </option>
                                                            {this.state.units.map(unit => {
                                                                return (
                                                                    <option key={unit.id} value={unit.id}>
                                                                        {unit.unit}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <FormGroup
                                                className={classnames({
                                                    focused: this.state.focusedServiceSubGroups
                                                })}
                                                onClick={e => {
                                                    onDisabledClick();
                                                }}
                                            >
                                                <label className="form-control-label hopsti-green">
                                                    {this.props.t('add-new-help-request.sub-category')}
                                                </label>
                                                <div className={this.state.customStyles.serviceSubGroupState ===
                                                    "invalid" ? "hopsti-select2-invalid" : this.state.customStyles.serviceSubGroupState ===
                                                        "valid" ? "hopsti-select2-valid" : ""}>
                                                    <Select2
                                                        value={this.state.serviceSubGroup}
                                                        className="form-control"
                                                        defaultValue={this.state.serviceSubGroup ? this.state.serviceSubGroup : "1"}
                                                        disabled={disableCategorySelections}
                                                        options={{
                                                            placeholder: "Vali alamkategooria"
                                                        }}
                                                        data={this.state.serviceSubGroups.map(
                                                            serviceSubGroup => {
                                                                return {
                                                                    id: serviceSubGroup.id,
                                                                    text: serviceSubGroup.name
                                                                };
                                                            }
                                                        )}
                                                        onSelect={e => {
                                                            this.setState({ serviceSubGroup: e.target.value }, () => this.validate());
                                                        }}
                                                        valid={
                                                            this.state.customStyles.serviceSubGroupState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.customStyles.serviceSubGroupState ===
                                                            "invalid"
                                                        }
                                                    />
                                                </div>
                                            </FormGroup>
                                            <FormGroup
                                                className={classnames({
                                                    focused: this.state.focusedAllowVolunteers
                                                })}
                                            >
                                                <InputGroup className="input-group-merge mb-3">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            className="custom-control-input"
                                                            id="customCheck1"
                                                            type="checkbox"
                                                            name="acceptVolunteers"
                                                            value={this.state.acceptVolunteers}
                                                            checked={this.state.acceptVolunteers}
                                                            onChange={e => {
                                                                this.handleChange(e);
                                                            }}
                                                        />
                                                        <label
                                                            className="custom-control-label hopsti-green"
                                                            htmlFor="customCheck1"
                                                        >
                                                            {this.props.t('add-new-help-request.visible-to-volunteers')}
                                                        </label>
                                                    </div>
                                                </InputGroup>
                                            </FormGroup>
                                            {addressSection}
                                            {kovSection}
                                            {socialSecurityCodeSection}
                                        </Col>
                                    </Row>
                                    {buttonSection}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/*</Container>*/}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        address: state.auth.user ? (state.auth.user.address ? state.auth.user.address : null) : null,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setUser: (user) => dispatch(actions.setUser(user))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(HelpRequestItem));
