/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from 'react-i18next';
// reactstrap components
import {
    UncontrolledCollapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    Button,
    Modal
} from "reactstrap";
import HopstiLogin from "../Login/HopstiLogin";
import axios from "../../axios";
import * as actions from "../../store/actions/auth";
import {connect} from "react-redux";
import HopstiContact from "components/Contact/HopstiContact";

class IndexNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loginModal: false,
            contactsModal: false
        };
    }

    componentDidMount() {
        if (this.props.user) {
            this.setState({isServiceProvider: this.props.user.is_service_provider});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user && prevProps.user !== this.props.user) {
            this.setState({isServiceProvider: this.props.user.is_service_provider});
        }
    }

    handleRoleChange(event) {
        axios.defaults.headers.common[
            "Authorization"
            ] = `Token ${this.props.token}`;
        axios
            .put("user-is-service-provider/")
            .then(response => {
                this.props.updateIsServiceProvider(this.props.user, response.data);
                this.setState({isServiceProvider: response.data});
                response.data ? this.props.history.push("/töölaud/minu-teenused") : this.props.history.push("/töölaud/minu-abihõiked");
            })
            .catch(err => {
                console.log("user-is-service-provider error - " + err);
            });
    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    handleServiceAdded = e => {
        this.toggleModal("addServiceModal");
    };

    render() {
        // console.log(this.props.role);
        let signInBtn = null;
        let signOutBtn = null;
        let contactInfoBtn = null;
        let esfLogo = null;
        let isServiceProviderToggle = null;
        let links = null;

        if (!this.props.token) {
            signInBtn = (
                <NavItem className="d-lg-block">
                    <NavLink
                        // to="/login"
                        // tag={Link}
                        onClick={() => this.toggleModal("loginModal")}
                    >
                        <Button className="btn-hopsti-green">{this.props.t('buttons.log-in')}</Button>
                    </NavLink>
                    <Modal
                        size="md"
                        isOpen={this.state.loginModal}
                        toggle={() => this.toggleModal("loginModal")}
                    >
                        <HopstiLogin closeHandler={() => this.toggleModal("loginModal")}/>
                    </Modal>
                </NavItem>
            );

            esfLogo = (
                <NavItem>
                    <NavLink
                    >
                        <img
                            className="el-fund-logo mt-2 mt-sm-0"
                            alt="..."
                            src={require("assets/img/brand/el_sotsiaalfond_horisontaalne.png")}
                        />
                    </NavLink>
                </NavItem>
            );
        }

        if (this.props.token && this.props.user) {
            signOutBtn = (
                <NavItem className=""> {/*d-none d-lg-block*/}
                    <div className="text-center">
                        <NavLink to="/töölaud/kontakt" className="pb-0" tag={Link}
                                 onClick={() => this.toggleModal("contactsModal")}>
                            {this.props.user && this.props.role === "ABIVAJAJA" ?
                                <span className="contact-link"><i
                                    className="fas fa-user hopsti-green"/>{this.props.user.first_name}</span>
                                : this.props.user && this.props.role === "TEENUSEPAKKUJA" ?
                                    <span><i className="fas fa-user hopsti-green"/>{this.props.user.sp_name ? this.props.user.sp_name : this.props.user.first_name}</span> :
                                    this.props.user && this.props.role === "KOV" ?
                                        <span className="contact-link"><i
                                            className="fas fa-user hopsti-green"/>{this.props.user.first_name} ({this.props.user.municipality})</span> : null
                            }
                        </NavLink>
                        <NavLink onClick={this.props.logout} className="pt-2" to="/" tag={Link}>
                            <Button className="btn btn-sm btn-hopsti-green">{this.props.t('buttons.log-out')}</Button>
                        </NavLink>
                    </div>
                </NavItem>
            );

            contactInfoBtn = (
                <NavItem className="ml-lg-4 contact-container">{/*d-none d-lg-block*/}
                    {/*      <NavLink to="/dashboard/contact-info" tag={Link} onClick={() => this.toggleModal("contactsModal")}>*/}
                    {/*          {this.props.user && !this.state.isServiceProvider ? (*/}
                    {/*              <span>*/}
                    {/*  {this.props.user.first_name + " " + this.props.user.last_name}*/}
                    {/*</span>*/}
                    {/*          ) : null}*/}
                    {/*          {this.props.user && this.state.isServiceProvider ? (*/}
                    {/*              <span>{this.props.user.sp_name}</span>*/}
                    {/*          ) : null}*/}
                    {/*          {this.props.user &&*/}
                    {/*          this.state.isServiceProvider &&*/}
                    {/*          !this.props.user.sp_name ? (*/}
                    {/*              <span>*/}
                    {/*    {this.props.user.first_name + " " + this.props.user.last_name}*/}
                    {/*  </span>*/}
                    {/*          ) : null}*/}
                    {/*      </NavLink>*/}
                    <Modal
                        size={this.state.isServiceProvider ? "lg" : "md"}
                        className="mt-6"
                        isOpen={this.state.contactsModal}
                        toggle={() => this.toggleModal("contactsModal")}
                    >
                        {/*<div className="modal-header">*/}
                        {/*    <button*/}
                        {/*        aria-label="Close"*/}
                        {/*        className="close"*/}
                        {/*        data-dismiss="modal"*/}
                        {/*        type="button"*/}
                        {/*        onClick={() => this.toggleModal("contactsModal")}*/}
                        {/*    >*/}
                        {/*        <span aria-hidden={true}>×</span>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        <HopstiContact
                            handleContactUpdated={() => this.toggleModal("contactsModal")}
                        />
                    </Modal>
                </NavItem>
            );

            if (this.props.role !== "KOV") {
                isServiceProviderToggle = (
                    <NavItem className="ml-lg-4 toggle-user-role hopsti-green"> {/*d-none d-lg-block*/}
                        <div className="text-center toggle-container">
                            <NavLink to="#" className="pb-0">
                                <span>{this.props.role === "TEENUSEPAKKUJA" ? this.props.t('user-role.change-role.person-in-need') : this.props.role === "ABIVAJAJA" ? this.props.t('user-role.change-role.service-provider') : null}</span>
                            </NavLink>
                            <NavLink className="pt-2 toggle-label">
                                <label
                                    className={this.state.isServiceProvider ? "hopsti-role-toggle custom-toggle navToggle isChecked" : "hopsti-role-toggle custom-toggle navToggle isNotChecked"}>
                                    {this.state.isServiceProvider ? (
                                        <input
                                            defaultChecked
                                            type="checkbox"
                                            value={this.state.isServiceProvider}
                                        />
                                    ) : (
                                        <input type="checkbox" value={this.state.isServiceProvider}/>
                                    )}
                                    <span
                                        className="custom-toggle-slider rounded-circle hopsti-green"
                                        data-label-off="Olen abivajaja"
                                        data-label-on="Olen teenusepakkuja"
                                        onClick={e => {
                                            this.handleRoleChange(e);
                                        }}
                                    />
                                </label>
                            </NavLink>
                        </div>
                    </NavItem>
                );
            }
        } else {
            links = (
                <>
                    <NavItem className="d-flex justify-content-center">
                        <NavLink to="/meist/" className="ml-lg-5" tag={Link}>
                            {this.props.t('links.about-us')}
                            {/*<span className="nav-link-inner--text ml-4">MEIST</span>*/}
                        </NavLink>
                    </NavItem>
                    <NavItem className="d-flex justify-content-center">
                        <NavLink href="https://blog.hopsti.ee">
                            {this.props.t('links.blog')}
                            {/*<span className="nav-link-inner--text ml-4">MEIST</span>*/}
                        </NavLink>
                    </NavItem>
                    {/*<NavItem>*/}
                    {/*  <NavLink to="/auth/pricing" tag={Link}>*/}
                    {/*    <span className="nav-link-inner--text">E-ÕPE</span>*/}
                    {/*  </NavLink>*/}
                    {/*</NavItem>*/}
                    {/*<NavItem>*/}
                    {/*    <NavLink href="https://blog.hopsti.ee" target="_blank" rel="noopener noreferrer">*/}
                    {/*        <span className="nav-link-inner--text">BLOGI</span>*/}
                    {/*    </NavLink>*/}
                    {/*</NavItem>*/}
                </>
            );
        }

        return (
            <>
                <Navbar
                    className="navbar-horizontal navbar-main navbar-light bg-white"
                    expand="lg"
                    id="navbar-main"
                >
                    <Container>
                        <NavbarBrand to="/" tag={Link}>
                            <img
                                className="hopsti-logo"
                                alt="..."
                                src={require("assets/img/brand/hopsti_logo-01.svg")}
                            />
                        </NavbarBrand>
                        <button
                            aria-controls="navbar-collapse"
                            aria-expanded={false}
                            aria-label="Toggle navigation"
                            className="navbar-toggler"
                            data-target="#navbar-collapse"
                            data-toggle="collapse"
                            id="navbar-collapse"
                            type="button"
                        >
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <UncontrolledCollapse
                            className="navbar-custom-collapse"
                            navbar
                            toggler="#navbar-collapse"
                        >
                            <div className="navbar-collapse-header">
                                <Row>
                                    <Col className="collapse-brand" xs="6">
                                        <Link to="/">
                                            <img
                                                alt="..."
                                                src={require("assets/img/brand/hopsti_logo-01.svg")}
                                            />
                                        </Link>
                                    </Col>
                                    <Col className="collapse-close" xs="6">
                                        <button
                                            aria-controls="navbar-collapse"
                                            aria-expanded={false}
                                            aria-label="Toggle navigation"
                                            className="navbar-toggler"
                                            data-target="#navbar-collapse"
                                            data-toggle="collapse"
                                            id="navbar-collapse"
                                            type="button"
                                        >
                                            <span/>
                                            <span/>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <Nav className="mr-auto" navbar>
                                {links}
                            </Nav>
                            <hr className="d-none d-lg-none"/>
                            <Nav className="align-items-center ml-lg-auto" navbar>
                                {contactInfoBtn}
                                <NavItem>
                                    <NavLink
                                        className="nav-link-icon"
                                        href="https://www.facebook.com/Hopsti.ee"
                                        id="tooltip601201423"
                                        target="_blank"
                                    >
                                        <i className="fab fa-facebook-square"/>
                                    </NavLink>
                                    <UncontrolledTooltip delay={0} target="tooltip601201423">
                                        Jälgi meid Facebookis
                                    </UncontrolledTooltip>
                                </NavItem>
                                {isServiceProviderToggle}
                                {signOutBtn}
                                {/*            <NavItem>*/}
                                {/*                <NavLink*/}
                                {/*                    className="nav-link-icon"*/}
                                {/*                    href="https://www.instagram.com/creativetimofficial?ref=creative-tim"*/}
                                {/*                    id="tooltip871243015"*/}
                                {/*                    target="_blank"*/}
                                {/*                >*/}
                                {/*                    <i className="fab fa-instagram"/>*/}
                                {/*                    <span className="nav-link-inner--text d-lg-none">*/}
                                {/*                        Instagram*/}
                                {/*</span>*/}
                                {/*                </NavLink>*/}
                                {/*                <UncontrolledTooltip delay={0} target="tooltip871243015">*/}
                                {/*                    Follow us on Instagram*/}
                                {/*                </UncontrolledTooltip>*/}
                                {/*            </NavItem>*/}
                                {signInBtn}
                                {esfLogo}
                            </Nav>
                        </UncontrolledCollapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout()),
        updateIsServiceProvider: (user, isServiceProvider) =>
            dispatch(actions.updateIsServiceProvider(user, isServiceProvider))
    };
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        token: state.auth.token,
        role: state.auth.user ? state.auth.user.role : null
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(IndexNavbar)));
