import * as actionTypes from './actionTypes';

export const onGetPINHelpRequests = (helpRequests) => {
    return {
        type: actionTypes.GET_PIN_HELP_REQUESTS,
        helpRequests: helpRequests
    }
};

export const onGetAllHelpRequests = (helpRequests) => {
    return {
        type: actionTypes.GET_ALL_HELP_REQUESTS,
        helpRequests: helpRequests
    }
};