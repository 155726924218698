import React from "react";
import { connect } from "react-redux";
import ServiceItem from "../ServiceItem/ServiceItem";

class AddServiceContainer extends React.Component {
    render() {
        return (
            <ServiceItem
                handleServiceAdded={this.props.handleServiceAdded}
                closeModalHandler={this.props.closeModalHandler}
                userId={this.props.user.pk}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(AddServiceContainer);