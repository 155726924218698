import React from "react";
import classnames from "classnames";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";
import {withTranslation} from 'react-i18next';

import * as actions from "../../store/actions/auth";
import {connect} from "react-redux";
import LocationInput from '../GooglePlacesAutocomplete/HopstiGooglePlacesAutocomplete';
import axios from "../../axios";
import * as helpers from "utils/helpers.js";
import NotificationAlert from "react-notification-alert";
import Avatar from "components/Avatar/Avatar";
import {Link} from 'react-router-dom';
import Slider from "nouislider";

class HopstiContact extends React.Component {
    state = {
        phoneUpdated: false,
        contactsUpdated: false,
        firstName: "",
        lastName: "",
        phone: "",
        code: "",
        address: "",
        latitude: "",
        longitude: "",
        municipality: "",
        county: "",
        sp_address: "",
        sp_latitude: "",
        sp_longitude: "",
        sp_municipality: "",
        sp_county: "",
        sp_name: "",
        sp_email: "",
        sp_phone: "",
        sp_radius: 20,
        sp_avatar: "",
        updatedUserData: null,
        avatarModal: false,
        sliderInitialized: false
    };

    componentDidMount() {
        if (this.props.user) {
            this.setPropsToState();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps && this.props && prevProps.user !== this.props.user) {
            this.setPropsToState();
        }

        if (this.state.phoneUpdated && this.state.contactsUpdated) {
            this.setState({
                phoneUpdated: false,
                contactsUpdated: false
            });

            this.props.setUser(this.state.updatedUserData);
            if (this.props.handleContactUpdated) {
                this.props.handleContactUpdated();
            }
        }
    }

    handleAddressForSP = (latLnglat, latLnglng, address, adminLevel1, adminLevel2) => {
        this.setState({
            sp_address: address,
            sp_latitude: latLnglat,
            sp_longitude: latLnglng,
            sp_county: adminLevel1,
            sp_municipality: adminLevel2
        })
    };

    handleAddress = (latLnglat, latLnglng, address, adminLevel1, adminLevel2) => {
        this.setState({
            address: address,
            latitude: latLnglat,
            longitude: latLnglng,
            county: adminLevel1,
            municipality: adminLevel2
        });
    };


    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };


    setPropsToState = () => {
        this.setState({
            firstName: this.props.user.first_name,
            lastName: this.props.user.last_name,
            phone: this.props.user.phones ? this.props.user.phones[0] : "",
            address: this.props.user.address !== null ? this.props.user.address : "",
            code: this.props.user.code !== null ? this.props.user.code : "",
            latitude: this.props.user.latitude,
            longitude: this.props.user.longitude,
            municipality: this.props.user.municipality,
            county: this.props.user.county,
            sp_address: this.props.user.sp_address !== null ? this.props.user.sp_address : "",
            sp_latitude: this.props.user.sp_latitude,
            sp_longitude: this.props.user.sp_longitude,
            sp_municipality: this.props.user.sp_municipality,
            sp_county: this.props.user.sp_county,
            sp_name: this.props.user.sp_name ? this.props.user.sp_name : "",
            sp_email: this.props.user.sp_email,
            sp_phone: this.props.user.sp_phone,
            sp_radius: this.props.user.sp_radius,
            sp_avatar: this.props.user.sp_avatar
        }, () => {
            if (this.props.isServiceProvider && !this.state.sliderInitialized) {
                var slider1 = this.refs.slider1;
                Slider.create(slider1, {
                    start: [this.state.sp_radius],
                    connect: [true, false],
                    step: 1,
                    range: {min: 1, max: 500}
                }).on(
                    "update",
                    function (values, handle) {
                        this.setState({
                            sp_radius: Number.parseInt(values[0])
                        });
                    }.bind(this)
                );
                ;
                this.setState({sliderInitialized: true});
            }
        })
    };

    handleChange = async event => {
        const {target} = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        {
            const {name} = target;
            await this.setState({
                [name]: value
            });
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        if (this.props.user.role !== "KOV") {
            if (this.props.checkForAadress && this.state.address === "") {
                this.props.checkForAadress();
                return;
            }

            if (this.props.user.phones[0] !== this.state.phone) {
                axios
                    .get("/contacts/" + this.props.user.pk + "/")
                    .then(response => {
                        let contactItem = response.data.find(element => {
                            return element.type === "PHONE"
                        });
                        if (contactItem !== undefined) {
                            const data = {
                                type: contactItem.type,
                                value: this.state.phone
                            };
                            axios
                                .put("/contacts/update/" + contactItem.id + "/", data)
                                .then(response => {
                                    this.setState({phoneUpdated: true});
                                })
                                .catch(err => {
                                    helpers.notify(
                                        "danger",
                                        "Uuendamine ebõnnestus",
                                        "Kontaktandmete uuendamine ebaõnnestus!",
                                        this
                                    );
                                });
                        } else {
                            const data = {
                                type: "PHONE",
                                value: this.state.phone
                            };
                            axios
                                .post("/contacts/", data)
                                .then(response => {
                                    this.setState({phoneUpdated: true});
                                })
                                .catch(err => {
                                    helpers.notify(
                                        "danger",
                                        "Uuendamine ebõnnestus",
                                        "Kontaktandmete uuendamine ebaõnnestus!",
                                        this
                                    );
                                });
                        }

                    })
                    .catch(err => {

                    });

            } else {
                this.setState({phoneUpdated: true});
            }
        } else {
            this.setState({phoneUpdated: true});
        }


        const data = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            code: this.state.code,
            address: this.state.address ? this.state.address : "",
            latitude: this.state.latitude ? this.state.latitude : "",
            longitude: this.state.longitude ? this.state.longitude : "",
            municipality: this.state.municipality,
            county: this.state.municipality,
            sp_address: this.state.sp_address,
            sp_latitude: this.state.sp_latitude,
            sp_longitude: this.state.sp_longitude,
            sp_municipality: this.state.sp_municipality,
            sp_county: this.state.sp_municipality,
            sp_name: this.state.sp_name,
            sp_email: this.state.sp_email,
            sp_phone: this.state.sp_phone,
            sp_radius: this.state.sp_radius
        };
        axios
            .put("/rest-auth/user/", data)
            .then(response => {
                axios
                    .get("/rest-auth/user/")
                    .then(response => {
                        this.setState({
                            updatedUserData: response.data
                        });
                    }).then(() => this.setState({contactsUpdated: true}))
                    .catch(err => {

                    });
            })
            .catch(err => {
                helpers.notify(
                    "danger",
                    "Uuendamine ebõnnestus",
                    "Kontaktandmete uuendamine ebaõnnestus!",
                    this
                );
            });
    };

    updateuserPicture = (path) => {
        console.log("path to set ", path)
        let oldUser = {...this.props.user}
        oldUser.sp_avatar = path;
        this.props.setUser(oldUser);
    }

    render() {
        const searchOptions = {
            componentRestrictions: {country: "ee"}

        };
        let serviceProviderContacts = null;
        let avatarSection = null;
        if (this.props.isServiceProvider) {
            serviceProviderContacts =
                <Col className="pl-0 pr-0" lg="6" md="8">
                    <div>
                        <h2>{this.props.t('contact-form.service-provider-header')}</h2>
                    </div>
                    <FormGroup
                        className={classnames({
                            focused: this.state.focusedCompanyName
                        })}
                    >
                        <label className="form-control-label">
                            {this.props.t('contact-form.company-name')}
                        </label>
                        <Input
                            placeholder={this.props.t('contact-form.insert-company-name')}
                            name="sp_name"
                            type="text"
                            value={this.state.sp_name}
                            onFocus={() => this.setState({focusedCompanyName: true})}
                            onBlur={() => this.setState({focusedCompanyName: false})}
                            onChange={(e) => {
                                this.handleChange(e);
                            }}
                        />
                    </FormGroup>

                    <FormGroup
                        className={classnames({
                            focused: this.state.focusedAddress
                        })}
                    >
                        <label className="form-control-label">
                            {this.props.t('contact-form.service-address')}
                        </label>
                        <InputGroup className="input-group-alternative">
                            <LocationInput
                                searchOptions={searchOptions} address={this.state.sp_address}
                                handleAddress={this.handleAddressForSP}
                            />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup
                        className={classnames({
                            focused: this.state.focusedRadius
                        })}
                    >
                        <label className="form-control-label">
                            {this.props.t('contact-form.service-provider-radius')}
                        </label>
                        <div className="input-slider-container">
                            <div className="input-slider" ref="slider1"/>
                            <Row className="mt-3">
                                <Col xs="6">
                                    <span className="range-slider-value">
                                        {this.state.sp_radius} km
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </FormGroup>

                    <FormGroup
                        className={classnames({
                            focused: this.state.focusedCompanyEmail
                        })}
                    >
                        <label className="form-control-label">
                            {this.props.t('contact-form.company-email')}
                        </label>
                        <Input
                            placeholder={this.props.t('contact-form.insert-company-email')}
                            name="sp_email"
                            type="text"
                            value={this.state.sp_email}
                            onFocus={() => this.setState({focusedCompanyEmail: true})}
                            onBlur={() => this.setState({focusedCompanyEmail: false})}
                            onChange={(e) => {
                                this.handleChange(e);
                            }}
                        />
                    </FormGroup>

                    <FormGroup
                        className={classnames({
                            focused: this.state.focusedCompanyTel
                        })}
                    ><label className="form-control-label">
                        {this.props.t('contact-form.company-phone')}
                    </label>
                        <Input
                            placeholder={this.props.t('contact-form.insert-company-phone')}
                            name="sp_phone"
                            type="text"
                            value={this.state.sp_phone}
                            onFocus={() => this.setState({focusedCompanyTel: true})}
                            onBlur={() => this.setState({focusedCompanyTel: false})}
                            onChange={(e) => {
                                this.handleChange(e);
                            }}
                        />
                    </FormGroup>
                </Col>;

            avatarSection = <Row className="justify-content-center">
                <Col className="order-lg-2 pb-6" lg="3">
                    <div className="card-profile-image">
                        {/* eslint-disable-next-line */}
                        <a onClick={e => {
                            e.preventDefault();
                            this.toggleModal("avatarModal")
                        }}>
                            <img
                                alt="..."
                                className="rounded-circle"
                                src={this.props.user ? this.props.user.sp_avatar : ""}
                            />
                        </a>
                    </div>
                </Col>
            </Row>;
        }

        return (
            <>
                <Container className="pl-0 pr-0">
                    <div className="rna-wrapper">
                        <NotificationAlert ref="notificationAlert"/>
                    </div>
                    <Card className="bg-secondary border-0 mr-0">
                        <div className="text-right mr-5 mt-3">
                            <i className="fas fa-2x fa-times clickable position-absolute"
                               onClick={this.props.handleContactUpdated}/>
                        </div>
                        {avatarSection}
                        <CardBody className="px-lg-5 py-lg-3">
                            <Form role="form">
                                <Row className="justify-content-center contact-form-row">
                                    <Col className={this.props.isServiceProvider ? "col-lg-6 col-md-8" : "col-12"}>
                                        <div>
                                            <h2>{this.props.t('contact-form.header')}</h2>
                                        </div>
                                        <FormGroup className={classnames({ focused: this.state.focusedFirstName})} >
                                            <label className="form-control-label">
                                                {this.props.t('contact-form.first-name')}
                                            </label>
                                            <InputGroup className="input-group-merge input-group-alternative mb-1">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-user"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder={this.props.t('contact-form.first-name')}
                                                    name="firstName"
                                                    type="text"
                                                    value={this.state.firstName}
                                                    onFocus={() => this.setState({focusedFirstName: true})}
                                                    onBlur={() => this.setState({focusedFirstName: false})}
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.focusedLastName
                                            })}
                                        >
                                            <label className="form-control-label">
                                                {this.props.t('contact-form.last-name')}
                                            </label>
                                            <InputGroup className="input-group-merge input-group-alternative mb-1">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-user"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder={this.props.t('contact-form.last-name')}
                                                    name="lastName"
                                                    type="text"
                                                    value={this.state.lastName}
                                                    onFocus={() => this.setState({focusedLastName: true})}
                                                    onBlur={() => this.setState({focusedLastName: false})}
                                                    onChange={this.handleChange}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        {this.props.user.role !== "KOV" ?
                                            <>
                                                <FormGroup
                                                    className={classnames({
                                                        focused: this.state.focusedPhone1
                                                    })}
                                                >
                                                    <label className="form-control-label">
                                                        {this.props.t('contact-form.phone')}
                                                    </label>
                                                    <InputGroup
                                                        className="input-group-merge input-group-alternative mb-1">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="fa fa-mobile"/>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            placeholder={this.props.t('contact-form.phone')}
                                                            type="text"
                                                            value={this.state.phone}
                                                            name="phone"
                                                            onFocus={() => this.setState({focusedPhone1: true})}
                                                            onBlur={() => this.setState({focusedPhone1: false})}
                                                            onChange={this.handleChange}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup
                                                    className={classnames({
                                                        focused: this.state.focusedCode
                                                    })}
                                                >
                                                    <label className="form-control-label">
                                                        {this.props.t('contact-form.ssid')}
                                                    </label>
                                                    <InputGroup
                                                        className="input-group-merge input-group-alternative mb-1">
                                                        <Input
                                                            placeholder={this.props.t('contact-form.ssid')}
                                                            type="text"
                                                            value={this.state.code}
                                                            name="code"
                                                            onFocus={() => this.setState({focusedCode: true})}
                                                            onBlur={() => this.setState({focusedCode: false})}
                                                            onChange={this.handleChange}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup>
                                                    <label className="form-control-label">
                                                        {this.props.t('contact-form.address')}
                                                    </label>
                                                    <InputGroup className="input-group-alternative">
                                                        <LocationInput
                                                            searchOptions={searchOptions} address={this.state.address}
                                                            handleAddress={this.handleAddress}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </> : null}
                                        <div className="pb-4">
                                            <Link to="/change-password" className="hopsti-green"
                                                  onClick={this.props.handleContactUpdated}>{this.props.t('contact-form.change-password')}</Link>
                                        </div>
                                    </Col>
                                    {serviceProviderContacts}
                                </Row>
                                <Row className="justify-content-center">
                                    <div className="text-center">
                                        <Button
                                            onClick={this.handleSubmit}
                                            className="mt-4 btn-hopsti-green"
                                            type="button"
                                        >
                                            {this.props.t('contact-form.save')}
                                        </Button>
                                        <Button
                                            onClick={this.handleSubmit}
                                            className="mt-4 btn-hopsti-pink"
                                            type="button"
                                        >
                                            {this.props.t('contact-form.close')}
                                        </Button>
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
                <Modal
                    size="md"
                    className="modal-dialog-centered"
                    isOpen={this.state.avatarModal}
                    toggle={() => this.toggleModal("avatarModal")}
                >
                    <Avatar currentAvatar={this.props.user ? this.props.user.sp_avatar : ""} toggleHandler={(path) => {
                        this.toggleModal("avatarModal");
                        this.updateuserPicture(path)
                    }}/>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        user: state.auth.user,
        isServiceProvider: state.auth.user
            ? state.auth.user.is_service_provider
            : false
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.authLogin(email, password)),
        setUser: (user) => dispatch(actions.setUser(user))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(HopstiContact));
