import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    token: null,
    error: null,
    loading: false,
    user: null
};

const registerStart = (state, action) => {
    return updateObject(state, {
        errorMessage: null,
        successMessage: null,
        loading: true
    })
};

const registerSuccess = (state, action) => {
    return updateObject(state, {
        successMessage: "User successfully registered!",
        loading: false
    })
};

const registerFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.error,
        loading: false
    })
};

const fbAuthStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
};

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
};

const fbAuthSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        error: null,
        loading: false
    })
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        error: null,
        loading: false
    })
};

const setUser = (state, action) => {
    return updateObject(state, {
        user: action.user
    })
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
};

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        user: null
    })
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REGISTER_START:
            return registerStart(state, action);
        case actionTypes.REGISTER_SUCCESS:
            return registerSuccess(state, action);
        case actionTypes.REGISTER_FAIL:
            return registerFail(state, action);
        case actionTypes.FB_AUTH_START:
            return fbAuthStart(state, action);
        case actionTypes.FB_AUTH_SUCCESS:
            return fbAuthSuccess(state, action);
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.SET_USER:
            return setUser(state, action);
        default:
            return state;
    }
};

export default auth;