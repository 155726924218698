import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    offers: null,
};

const setOffers = (state, action) => {
    return updateObject(state, {
        offers: action.offers
    })
};

const offers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_OFFERS:
            return setOffers(state, action);
        default:
            return state;
    }
};

export default offers;