import React from "react";
import {
    Col, Container,
    Row,
} from "reactstrap";
import Rating from "react-rating";
import ImageGallery from 'react-image-gallery';
import {withRouter} from "react-router";
import {withTranslation} from 'react-i18next';

import {Link} from "react-router-dom";

class DetailView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayMatches: false,
            matchedHelpRequests: [],
            emailModal: false,
            phoneModal: false,
            serviceModal: false,
            quantity: 1,
        };
    }

    handleChange = async event => {
        const {target} = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        if (target.value !== "") {
            const {name} = target;
            await this.setState({
                [name]: value
            });
        }
    };

    render() {
        if (this.props.service) {
            // console.log(this.props.service);
            return (
                <Container>
                    <Row className="ml-0 mr-0">
                        <Col className="col-auto text-center mt-5 mb-5">
                            {/*eslint-disable-next-line */}
                            <a className="avatar rounded-circle service-detail-img">
                                <img className="service-detail-img blurred-image" alt="..."
                                     src={this.props.service.avatar}/>
                            </a>
                            <h4 className="mb-0 hopsti-pink">
                                {this.props.service.user ? this.props.service.user.first_name : null}
                            </h4>
                            <small
                                className="hopsti-green">{this.props.service.created_at}</small>
                            {this.props.isService ?
                                <>
                                    <div>
                                        <Rating
                                            readonly={true}
                                            fractions={2}
                                            initialRating={this.props.service.rating ? this.props.service.rating.average_rating : null}
                                            onClick={this.handleRatingSelected}
                                            emptySymbol="fa fa-star btn-hopsti-pink-outline"
                                            fullSymbol="fa fa-star hopsti-pink"/>
                                    </div>
                                    <div className="pb-4">
                                        <small><strong
                                            className="">{this.props.service.rating ? this.props.service.rating.average_rating : null}</strong>/5
                                            ({this.props.service.rating ? this.props.service.rating.rating_count : null} {this.props.service.rating && this.props.service.rating.rating_count === 1 ? this.props.t('detail-view.rating') : this.props.t('detail-view.ratings')})</small>
                                    </div>
                                </> : null}
                        </Col>
                        <Col className="col pl-3 mt-5 mb-5">
                            <h4 className="mb-0 hopsti-pink">
                                {this.props.isService ? this.props.service.name : this.props.service.title}
                            </h4>
                            <br/>
                            <div>
                                <small className="">
                                    {this.props.t('detail-view.category')}: <strong>{this.props.service.sub_service_name[0].service_group.name}</strong>
                                </small>
                            </div>
                            <div>
                                <small className="">
                                    {this.props.t('detail-view.sub-category')}: <strong>{this.props.service.sub_service_name[0].name}</strong>
                                </small>
                            </div>
                            <div>
                                <small className="">
                                    {this.props.t('detail-view.distance-from-you')}: <strong>{this.props.service.google_response.distance ? this.props.service.google_response.distance.distance.text : null}</strong>
                                </small>
                            </div>
                            {this.props.isService ?
                                <div className="mb-0">
                                    <small className="">
                                        {this.props.t('detail-view.price')}: <strong>{this.props.service && this.props.service.cost && this.props.service.unit ? this.props.service.cost + "€/" + this.props.service.unit.unit :
                                        <small className="text-muted">{this.props.t('detail-view.log-in-to-see-price')}</small>}</strong></small>
                                </div> :
                                <div className="">
                                    <small className="">
                                        {this.props.t('detail-view.need')}: <strong>{this.props.service && this.props.service.quantity && this.props.service.unit ? this.props.service.quantity + " " + this.props.service.unit.unit : null}</strong>
                                    </small>
                                </div>
                            }
                            <br/>
                            <div>
                                <small>{this.props.t('detail-view.description')}: </small>
                                <small className="">
                                    {this.props.isService ? this.props.service.description_ee : this.props.service.info}
                                </small>
                            </div>
                            <br/>
                            {this.props.isService && !this.props.isUserService ?
                                <div>
                                    <Link className="btn btn-hopsti-pink" to={{
                                        pathname: '/töölaud/minu-abihõiked/',
                                        state: {
                                            addHelpRequestModal: true,
                                            serviceCategory: this.props.service.sub_service_name[0].service_group.id,
                                            serviceSubCategory: this.props.service.sub_service_name[0].id
                                        }
                                    }}>{this.props.t('detail-view.ask-offer')}</Link>
                                </div> : null}
                            {
                                this.props.service.kov_status_value && this.props.service.kov_status_value !== "" ?
                                    <div>
                                        <small>
                                            {this.props.t('detail-view.judgement')}: {this.props.service.kov_status_value}
                                        </small>
                                    </div> : null
                            }
                        </Col>
                        {this.props.service.files && this.props.service.files.length > 0 ?
                            <Col className="col-sm-12 col-lg-5 ml-auto mt-5 mb-5">
                                <div className="galleryFix">
                                    <ImageGallery showPlayButton={false} showThumbnails={true}
                                                  items={this.props.service.files}/>
                                </div>
                            </Col> : null}
                    </Row>
                </Container>
            );
        } else return (
            <></>
        )
    }
}

export default withRouter((withTranslation('common')(DetailView)));
