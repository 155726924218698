import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/auth';
import HopstiNavbar from "../Navbars/IndexNavbar.jsx";
import AuthFooter from "../Footers/AuthFooter.jsx";


class CustomLayout extends React.Component {
    render() {
        return (
            <div>
                <HopstiNavbar/>
                {this.props.children}
                <AuthFooter/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout())
    }
};

export default withRouter(connect(null, mapDispatchToProps)(CustomLayout));