import React from "react";
import {
    Badge,
    Col,
    Modal, Row,
} from "reactstrap";
import DetailView from "../../DetailView/DetailView";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {sortCaret, renderAvatar} from "./Helpers/TableUtil"
// import overlayFactory from "react-bootstrap-table2-overlay";
import {withTranslation} from 'react-i18next';


class MyHelpRequestsTable extends React.Component {

    state = {
        serviceModal: false,
        data: []
    };

    componentDidMount() {
        this.setState({
            data: this.props.items ? this.props.items : []
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.items !== this.props.items) {
            this.setState({
                data: this.props.items ? this.props.items : []
            })
        }
    }

    toggleModal = (state, service) => {
        this.setState({
            [state]: !this.state.serviceModal,
            selectedService: service
        });
    };

    render() {
        const renderButtons = (cell, row) => {
            return (
                <div className="d-flex align-items-center">
                    <i className="fa fa-2x fa-search hopsti-pink pl-2 clickable"
                       onClick={() => this.toggleModal("serviceModal", row)}
                       title="Vaata detailvaadet"
                       aria-hidden="true"/>
                    <i onClick={() => this.props.editServiceHandler(row.id)}
                       className="fa fa-2x fa-edit hopsti-green pl-2 clickable" id="tooltip1"
                       title="Muuda"
                       aria-hidden="true"/>
                    <i onClick={() => this.props.removeHandler(row.id)}
                       className="fa fa-2x fa-trash-alt hopsti-pink pl-2 clickable"
                       id="tooltip2"
                       title="Kustuta"
                       aria-hidden="true"/>
                    <Badge onClick={() => this.props.handleMatchClick(row.id)}
                           className="badge badge-md pill btn-hopsti-pink clickable ml-2">{row.matches_count === 1 ? <>{row.matches_count} {this.props.t('help-request-table.match')}</> : row.matches_count > 0 ? <>{row.matches_count} {this.props.t('help-request-table.matches')}</> : null}</Badge>
                </div>
            );
        };

        const renderNeed = (cell, row) => {
            return (
                row.quantity + " " + row.unit.unit
            );
        };

        const columns = [{
            dataField: 'avatar',
            text: '',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            },
            formatter: renderAvatar,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            headerStyle: (column, colIndex) => {
                return {width: '65px', textAlign: 'center'};
            }
        }, {
            dataField: 'created_at',
            text: this.props.t('help-request-table.headers.added-date'),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            headerStyle: (column, colIndex) => {
                return {width: '100px'};
            },
            sort: true,
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'title',
            text: this.props.t('help-request-table.headers.title'),
            sort: true,
            classes: 'clickable',
            sortCaret: (order, column) => sortCaret(order, column),
            headerStyle: (column, colIndex) => {
                return {width: '200px'};
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'info',
            text: this.props.t('help-request-table.headers.description'),
            sort: true,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            },
        }, {
            dataField: 'unit.id',
            text: this.props.t('help-request-table.headers.need'),
            sort: true,
            sortCaret: (order) => sortCaret(order),
            headerStyle: (column, colIndex) => {
                return {width: '90px'};
            },
            formatter: renderNeed,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: '',
            text: this.props.t('help-request-table.headers.buttons'),
            formatter: renderButtons,
            headerStyle: (column, colIndex) => {
                return {width: '200px'};
            },
        }];

        const NoDataIndication = () =>
            <div className="spinner pt-4 pb-4">
                {!this.props.items ? <i className="fas fa-3x fa-spinner fa-pulse hopsti-green"/> :
                <span className="hopsti-pink">{this.props.t('help-request-table.no-data-pin')}</span>}
            </div>;

        return (
            <>
                <Row className="justify-content-center pt-2 pb-2">
                    <Col className="col-12">
                        <BootstrapTable
                            striped
                            hover
                            bordered={false}
                            keyField='id'
                            wrapperClasses="table-responsive"
                            data={this.state.data}
                            columns={columns}
                            pagination={paginationFactory({hideSizePerPage: true})}
                            noDataIndication={() => <NoDataIndication/>}
                            loading={!this.props.items}
                            // overlay={overlayFactory()}
                        />
                    </Col>
                </Row>
                <Modal
                    size="lg"
                    className={this.state.selectedService && this.state.selectedService.files && this.state.selectedService.files.length > 0 ? "modal-dialog-centered matches-modal" : "modal-dialog-centered matches-modal-sm"}
                    isOpen={!!(this.state.serviceModal && this.state.selectedService)}
                    toggle={() => this.toggleModal("serviceModal")}
                >
                    <DetailView isService={false} service={this.state.selectedService}/>
                </Modal>
            </>
        );
    }
}

export default withTranslation('common')(MyHelpRequestsTable);