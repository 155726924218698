import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import ReactGA from 'react-ga';
// reactstrap components
import axios from '../../axios';
import * as actions from "../../store/actions/auth";
import {connect} from "react-redux";

import {
    Alert,
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";

class HopstiRegister extends React.Component {
    state = {};

    constructor(props) {
        super(props);
        this.state = {
            customStyles: {
                firstName: '',
                firstNameState: null,
                lastName: '',
                lastNameState: null,
                password: '',
                passwordState: null,
                confirmPassword: '',
                confirmPasswordState: null
            },
            uid: this.props.match.params.uidb64,
            token: this.props.match.params.token,
            isRegistered: false,
            user: null,
            showErrorMessage: false
        };
    }

    validateCustomStylesForm = () => {
        let allValid = true;
        let newState = this.state.customStyles;
        if (newState.firstName === "") {
            newState.firstNameState = "invalid";
            allValid = false;
        } else {
            newState.firstNameState = "valid";
        }
        if (newState.lastName === "") {
            newState.lastNameState = "invalid";
            allValid = false;
        } else {
            newState.lastNameState = "valid";
        }
        if (newState.password === "" || newState.password.length < 8) {
            newState.passwordState = "invalid";
            allValid = false;
        } else {
            newState.passwordState = "valid";
        }
        if (newState.confirmPassword === "") {
            newState.confirmPasswordState = "invalid";
            allValid = false;
        } else {
            newState.confirmPasswordState = "valid";
        }
        this.setState({
            customStyles: newState,
            showErrorMessage: false
        });
        if (allValid) {
            const {password, confirmPassword} = this.state.customStyles;

            if (password !== confirmPassword) {
                this.setState({showErrorMessage: true});
            } else {
                const data = {
                    "first_name": this.state.customStyles.firstName,
                    "last_name": this.state.customStyles.lastName,
                    "password": this.state.customStyles.password
                };

                if (this.state.user && this.state.user.role === "TEENUSEPAKKUJA") {
                    ReactGA.ga('send', {
                        hitType: 'event',
                        eventCategory: 'Registration',
                        eventAction: 'registerStep2PK_OK',
                        eventLabel: 'Submit user activation form'
                    });
                } else if (this.state.user && this.state.user.role === "ABIVAJAJA") {
                    ReactGA.ga('send', {
                        hitType: 'event',
                        eventCategory: 'Registration',
                        eventAction: 'registerStep2AV_OK',
                        eventLabel: 'Submit user activation form'
                    });
                }

                axios.defaults.headers.common['Authorization'] = `Token ${this.state.token}`;
                axios
                    .put('/complete-user-registration/', data)
                    .then(response => {
                        this.setState({isRegistered: true});
                        this.props.updateUserInfo(this.state.user.pk, this.state.token)
                    })
                    .catch(err => {
                        console.log("/register-sp/ " + err);

                    })
            }

        }
    };
    customStylesForm = (e, stateName) => {
        let newState = this.state.customStyles;
        newState[stateName] = e.target.value;

        if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
        } else {
            newState[stateName + "State"] = "valid";
        }

        this.setState({
            customStyles: newState
        });
    };

    componentDidMount() {
        ReactGA.initialize('UA-150997639-1');
        ReactGA.pageview('/register-activate');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.user !== prevState.user) {
            console.log(this.state.user);

            if (this.state.user && this.state.user.role === "TEENUSEPAKKUJA") {
                ReactGA.ga('send', {
                    hitType: 'event',
                    eventCategory: 'Registration',
                    eventAction: 'registerStep2PK',
                    eventLabel: 'Opened user activation form'
                });
            } else if (this.state.user && this.state.user.role === "ABIVAJAJA") {
                ReactGA.ga('send', {
                    hitType: 'event',
                    eventCategory: 'Registration',
                    eventAction: 'registerStep2AV',
                    eventLabel: 'Opened user activation form'
                });
            }
        }

        if (this.state.isRegistered) {
            this.props.history.push('/')
        }
    }

    componentWillMount() {
        const headers = {
            'Content-Type': 'application/json'
        };
        axios
            .get('/activate/' + this.state.uid + '/' + this.state.token, {
                headers: headers
            })
            .then(response => {
                this.setState({token: response.data.key});
                axios.defaults.headers.common['Authorization'] = `Token ${this.state.token}`;
                axios
                    .get("rest-auth/user/")
                    .then(response => {
                        const user = response.data;
                        this.setState({user: user});
                        this.props.setUser(user);
                        this.props.authSuccess(this.state.token);
                        this.props.onRegister(this.state.token);
                    })
                    .catch(err => console.log(err));
            })
            .catch(err => {
                console.log("Activate error ", err);
            })
    }

    render() {
        let errorMessage = null;

        if (this.state.showErrorMessage) {
            errorMessage = (
                <Alert className="" color="danger">
                    Viga sisestatud paroolid ei kattu!
                </Alert>
            );
        }

        return (
            <>
                <AuthHeader
                    title="Kasutajaks registreerimine"
                    lead="Täida alljärgnevad väljad"
                />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="6" md="8">
                            <Card className="bg-secondary border-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <Form onSubmit={(e) => this.handleSubmit(e)} role="form"
                                          className="needs-validation" noValidate>
                                        {errorMessage}
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.focusedName
                                            })}
                                        >
                                        </FormGroup>
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.focusedFirstName
                                            })}
                                        > <label className="form-control-label">Eesnimi</label>
                                            <InputGroup className="input-group-merge input-group-alternative mb-3">

                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-user"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Eesnimi"
                                                    name="firstName"
                                                    type="text"
                                                    id="firstName"
                                                    onFocus={() => this.setState({focusedFirstName: true})}
                                                    onBlur={() => this.setState({focusedFirstName: false})}
                                                    valid={
                                                        this.state.customStyles.firstNameState === "valid"
                                                    }
                                                    invalid={
                                                        this.state.customStyles.firstNameState ===
                                                        "invalid"
                                                    }
                                                    onChange={e =>
                                                        this.customStylesForm(e, "firstName")
                                                    }
                                                />
                                                <div className="invalid-feedback">
                                                    Palun sisesta oma eesnimi.
                                                </div>
                                            </InputGroup>
                                            <label className="form-control-label">Perekonnanimi</label>
                                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-user"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Perekonnanimi"
                                                    type="text"
                                                    name="lastName"
                                                    onFocus={() => this.setState({focusedLastName: true})}
                                                    onBlur={() => this.setState({focusedLastName: false})}
                                                    valid={
                                                        this.state.customStyles.lastNameState === "valid"
                                                    }
                                                    invalid={
                                                        this.state.customStyles.lastNameState ===
                                                        "invalid"
                                                    }
                                                    onChange={e => this.customStylesForm(e, "lastName")}
                                                />
                                                <div className="invalid-feedback">
                                                    Palun sisesta oma perekonnanimi.
                                                </div>
                                            </InputGroup>
                                        </FormGroup>
                                        <label className="form-control-label">Salasõna</label>
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.focusedPassword
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Salasõna"
                                                    type="password"
                                                    name="password"
                                                    onFocus={() =>
                                                        this.setState({focusedPassword: true})
                                                    }
                                                    onBlur={() =>
                                                        this.setState({focusedPassword: false})
                                                    }
                                                    valid={
                                                        this.state.customStyles.passwordState === "valid"
                                                    }
                                                    invalid={
                                                        this.state.customStyles.passwordState ===
                                                        "invalid"
                                                    }
                                                    onChange={e => this.customStylesForm(e, "password")}
                                                />
                                                <div className="invalid-feedback">
                                                    Palun sisesta salasõna. Pikkus vähemalt 8 märki!
                                                </div>
                                            </InputGroup>
                                        </FormGroup>
                                        <label className="form-control-label">Korda salasõna</label>
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.focusedConfirmPassword
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Korda salasõna"
                                                    type="Password"
                                                    name="confirmPassword"
                                                    onFocus={() =>
                                                        this.setState({focusedConfirmPassword: true})
                                                    }
                                                    onBlur={() =>
                                                        this.setState({focusedConfirmPassword: false})
                                                    }
                                                    valid={
                                                        this.state.customStyles.confirmPasswordState === "valid"
                                                    }
                                                    invalid={
                                                        this.state.customStyles.confirmPasswordState ===
                                                        "invalid"
                                                    }
                                                    onChange={e => this.customStylesForm(e, "confirmPassword")}
                                                />
                                                <div className="invalid-feedback">
                                                    Palun korda Salasõna.
                                                </div>
                                            </InputGroup>
                                        </FormGroup>
                                        <div className="text-center">
                                            <Button onClick={this.validateCustomStylesForm}
                                                    className="mt-4 btn-hopsti-green"
                                                    type="button">
                                                Registreeru
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token,
        user: state.auth.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setUser: (user) => dispatch(actions.setUser(user)),
        authSuccess: (token) => dispatch(actions.authSuccess(token)),
        onRegister: (token) => dispatch(actions.onRegister(token)),
        updateUserInfo: (userID, token) => dispatch(actions.updateUserInfo(userID, token)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HopstiRegister);