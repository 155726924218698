import React from "react";
import classnames from "classnames";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col, Alert
} from "reactstrap";

import AuthHeader from "components/Headers/AuthHeader.jsx"
import * as actions from "../../store/actions/auth";
import {connect} from "react-redux";
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import {withRouter} from "react-router";
import {Link} from 'react-router-dom';


class HopstiLogin extends React.Component {
    state = {
        showLoginError: false,
        email: '',
        password: '',
        validate: {
            emailState: '',
        }
    };

    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const {validate} = this.state;
        if (emailRex.test(e.target.value)) {
            validate.emailState = 'has-success'
        } else {
            validate.emailState = 'has-danger'
        }
        this.setState({validate})
    }

    handleChange = async (event) => {
        const {target} = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const {name} = target;
        await this.setState({
            [name]: value,
        });
    };

    async logIn(e) {
        e.preventDefault();

        if (!this.state.email || !this.state.password) {
            this.setState({showLoginError: true});
            return;
        }

        try {
            await this.props.onAuth(this.state.email, this.state.password);
        } catch (err) {
            console.log("login auth ", err);
            this.setState({
                showLoginError: true
            });

            if (err && err.status === 400) {
                console.log("ERROR");
            } else {
                console.log("ERROR");
            }
        }
    }

    responseFacebook = async (response) => {
        await this.props.onFacebookAuth(response);

    };

    responseGoogleSuccess = async (response) => {
        await this.props.onGoogleAuth(response);
    };

    responseGoogleFailure = async (response) => {
        console.log("Google error", response);
        this.setState({
            showLoginError: true
        });
    };


    render() {
        let toViewDetails = null;
        let loginError = null;


        if (this.state.showLoginError || this.props.loginError) {
            loginError = <Alert className="bg-hopsti-pink">
                <strong>Sisselogimine ebaõnnestus!</strong> Palun kontrolli emaili ja parooli ning proovi uuesti
            </Alert>;
        }

        if (this.props.selectedServiceID || this.props.selectedHRid) {
            toViewDetails = "Detailide vaatamiseks logige sisse";
        } else {
            toViewDetails = "Tere tulemast!";
        }

        return (
            <>
                <AuthHeader
                    title={toViewDetails}
                    lead="Registreeri abivajajaks või teenusepakkujaks"
                />
                <Container className="pb-5">
                    <Row className="justify-content-center">
                        <Col lg="12" md="12">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-2">
                                    {loginError}
                                    <Form role="form">
                                        <FormGroup
                                            className={classnames("mb-3", {
                                                focused: this.state.focusedEmail
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="E-mail"
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    onFocus={() => this.setState({focusedEmail: true})}
                                                    onBlur={() => this.setState({focusedEmail: false})}
                                                    onChange={(e) => {
                                                        this.validateEmail(e);
                                                        this.handleChange(e);
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.focusedPassword
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Parool"
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    onFocus={() =>
                                                        this.setState({focusedPassword: true})
                                                    }
                                                    onBlur={() =>
                                                        this.setState({focusedPassword: false})
                                                    }
                                                    onChange={(e) => {
                                                        this.validateEmail(e);
                                                        this.handleChange(e);
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <div className="text-center">
                                            <Button onClick={(event) => this.logIn(event)}
                                                    className="mt--1 my-4 btn-hopsti-green"
                                                    type="submit">
                                                Logi sisse
                                            </Button>
                                        </div>
                                    </Form>
                                    <div className="text-center pb-5">
                                        <Link to="/forgot-password" className="hopsti-green"
                                              onClick={this.props.closeHandler}>Unustasid salasõna?</Link>
                                    </div>
                                    <div className="d-flex justify-content-center pt-2">
                                        <FacebookLogin
                                            appId="432440380776606"
                                            icon="fab fa-facebook-f"
                                            cssClass="btn-facebook-hopsti clickable"
                                            textButton="&nbsp;&nbsp;Sign In with Facebook"
                                            fields="name,email,picture"
                                            disableMobileRedirect={true}
                                            callback={this.responseFacebook}
                                        />
                                        <GoogleLogin
                                            clientId="11496613599-npisk71l196ng96dfe414qt8eka7c4uh.apps.googleusercontent.com"
                                            onSuccess={this.responseGoogleSuccess}
                                            onFailure={this.responseGoogleFailure}
                                            style={{}}
                                            className="btn-google-plus"
                                            buttonText="Sign In with Google">
                                        </GoogleLogin>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        user: state.auth.user,
        role: state.auth.user ? state.auth.user.role : null,
        loginError: state.auth.error
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.authLogin(email, password)),
        onFacebookAuth: (accessToken) => dispatch(actions.onFacebookAuth(accessToken)),
        onGoogleAuth: (accessToken) => dispatch(actions.onGoogleAuth(accessToken)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HopstiLogin));