import React from "react";
import {Row, Col} from "reactstrap";
import classnames from "classnames";
import {withTranslation} from 'react-i18next';


class ServiceGroupsFilter extends React.Component {

    handleFilterClick = filterID => {
        let filterBy = [...this.props.filterBy];
        var idx = filterBy.indexOf(filterID);
        if (idx >= 0) {
            filterBy.splice(idx, 1);
        } else {
            filterBy.push(filterID);
        }

        this.props.onFilterChange(filterBy);
    };

    render() {
        return (
            <Row className="row-grid justify-content-center pb-3">
                <Col className="text-center" md={12} lg={12}>
                    <div className="text-center">
                        <Row className="d-flex justify-content-center">
                            <Col xs={"auto"} className="my-2">
                                <div
                                    className="icon-dashboard-container"
                                    rel="noopener noreferrer"
                                    type="href"
                                    onClick={() => {
                                        this.handleFilterClick(1);
                                    }}
                                >
                                    <svg
                                        className={classnames("hopsti-dashboard-icon", {active: this.props.filterBy.indexOf(1) >= 0 ? true : false})}
                                        x="0px" y="0px" viewBox="0 0 480 480">
                                        <polyline className="st0" points="413.42,193.69 413.42,448.79 283.47,448.79 283.47,323.38 196.83,323.38 196.83,448.79 66.88,448.79
		66.88,193.69 	"/>
                                        <polyline className="st0"
                                                  points="31.16,218.92 240.15,31.21 448.53,218.92 	"/>
                                        <rect x="196.83" y="166.96" className="st0" width="86.64" height="78.49"/>
                                        <polyline className="st0"
                                                  points="66.88,193.69 66.88,75.95 121.36,75.95 121.36,140.79 	"/>
                                    </svg>
                                    <span className="text-xs hopsti-green">{this.props.t('icons.home')}</span>
                                </div>
                            </Col>
                            <Col xs={"auto"} className="my-2 ">
                                <div
                                    className="icon-dashboard-container"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        this.handleFilterClick(2);
                                    }}
                                >
                                    <svg
                                        className={classnames("hopsti-dashboard-icon", {active: this.props.filterBy.indexOf(2) >= 0 ? true : false})}
                                        x="0px" y="0px" viewBox="0 0 480 480">

                                        <path className="st0" d="M385.69,196.16c2.74-7.79,4.31-16.14,4.31-24.87c0-41.46-33.61-75.08-75.08-75.08c-1.78,0-3.52,0.14-5.27,0.27
		c0.12-1.75,0.27-3.49,0.27-5.27c0-41.46-33.61-75.08-75.08-75.08s-75.08,33.61-75.08,75.08c0,1.77,0.14,3.5,0.26,5.24
		c-39.24,2.46-70.34,34.97-70.34,74.84c0,8.73,1.57,17.08,4.31,24.87c-14.92,13.72-24.33,33.34-24.33,55.21
		c0,41.46,33.61,75.08,75.08,75.08c5.14,0,185.05,0,190.19,0c41.46,0,75.08-33.61,75.08-75.08
		C410.02,229.5,400.61,209.88,385.69,196.16z"/>
                                        <line className="st0" x1="130.39" y1="447.87" x2="346.26" y2="447.87"/>
                                        <line className="st0" x1="238.32" y1="178.03" x2="238.32" y2="447.87"/>
                                        <line className="st0" x1="157.37" y1="232" x2="238.32" y2="299.46"/>
                                        <line className="st0" x1="319.27" y1="178.03" x2="238.32" y2="245.49"/>
                                    </svg>
                                    <span className="hopsti-green text-xs">{this.props.t('icons.garden')}</span>
                                </div>
                            </Col>
                            <Col xs={"auto"} className="my-2">
                                <div
                                    className="icon-dashboard-container"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        this.handleFilterClick(3);
                                    }}
                                >
                                    <svg
                                        className={classnames("hopsti-dashboard-icon", {active: this.props.filterBy.indexOf(3) >= 0 ? true : false})}
                                        x="0px" y="0px" viewBox="0 0 480 480">

                                        <polyline className="st0" points="75.25,358.35 24.41,358.35 24.41,90.74 291.81,90.74 308.75,174.37 409.1,174.37 459.28,274.73
		459.28,358.35 425.83,358.35 	"/>
                                        <line className="st0" x1="308.75" y1="358.35" x2="191.67" y2="358.35"/>
                                        <path className="st0" d="M133.13,425.26L133.13,425.26c-32.33,0-58.54-26.21-58.54-58.54v0c0-32.33,26.21-58.54,58.54-58.54h0
		c32.33,0,58.54,26.21,58.54,58.54v0C191.67,399.05,165.46,425.26,133.13,425.26z"/>
                                        <path className="st0" d="M367.29,425.26L367.29,425.26c-32.33,0-58.54-26.21-58.54-58.54v0c0-32.33,26.21-58.54,58.54-58.54h0
		c32.33,0,58.54,26.21,58.54,58.54v0C425.83,399.05,399.62,425.26,367.29,425.26z"/>
                                        <line className="st0" x1="308.75" y1="174.37" x2="308.75" y2="274.73"/>
                                    </svg>
                                    <span className="hopsti-green text-xs">{this.props.t('icons.transport')}</span>
                                </div>
                            </Col>
                            <Col xs={"auto"} className="my-2 ">
                                <div
                                    className="icon-dashboard-container"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        this.handleFilterClick(4);
                                    }}
                                >
                                    <svg
                                        className={classnames("hopsti-dashboard-icon", {active: this.props.filterBy.indexOf(4) >= 0 ? true : false})}
                                        x="0px" y="0px" viewBox="0 0 480 480">
                                        <path className="st0" d="M429.92,98.51L429.92,98.51c-39.53-39.53-103.63-39.53-143.16,0l-46.91,46.91l-46.91-46.91
	c-39.53-39.53-103.63-39.53-143.16,0v0c-39.53,39.53-39.53,103.63,0,143.16l190.07,189.48l190.07-189.48
	C469.45,202.13,469.45,138.04,429.92,98.51z"/>
                                    </svg>
                                    <span className="hopsti-green text-xs">{this.props.t('icons.health')}</span>
                                </div>
                            </Col>
                            <Col xs={"auto"} className="my-2 ">
                                <div
                                    className="icon-dashboard-container"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        this.handleFilterClick(5);
                                    }}
                                >
                                    <svg
                                        className={classnames("hopsti-dashboard-icon", {active: this.props.filterBy.indexOf(5) >= 0 ? true : false})}
                                        x="0px" y="0px" viewBox="0 0 480 480">
                                        <path className="st0" d="M240.84,275.04L240.84,275.04c-67.73,0-122.64-54.91-122.64-122.64v0c0-67.73,54.91-122.64,122.64-122.64h0
		c67.73,0,122.64,54.91,122.64,122.64v0C363.48,220.13,308.58,275.04,240.84,275.04z"/>
                                        <polyline className="st0"
                                                  points="303.94,257.52 459.84,362.64 459.84,450.24 21.84,450.24 21.84,362.64 177.67,257.52 	"/>
                                        <line className="st0" x1="280.26" y1="380.16" x2="367.86" y2="380.16"/>
                                        <path className="st0"
                                              d="M118.53,161c118.59,8.6,144.21-43.64,144.21-43.64s17.52,35.04,105.12,35.04"/>
                                    </svg>
                                    <span className="hopsti-green text-xs">{this.props.t('icons.assistant')}</span>
                                </div>
                            </Col>
                            <Col xs={"auto"} className="my-2 ">
                                <div
                                    className="icon-dashboard-container"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        this.handleFilterClick(6);
                                    }}
                                >
                                    <svg
                                        className={classnames("hopsti-dashboard-icon", {active: this.props.filterBy.indexOf(6) >= 0 ? true : false})}
                                        x="0px" y="0px" viewBox="0 0 480 480">
                                        <path className="st0" d="M426.01,91.39c0,0-130.31,16.22-130.31-64.89h-55.85H184c0,81.11-130.31,64.89-130.31,64.89
		C-2.17,399.61,239.84,464.5,239.84,464.5S481.86,399.61,426.01,91.39z"/>
                                        <polyline className="st0"
                                                  points="358.48,181.71 191.9,309.29 121.21,239.21 	"/>
                                    </svg>
                                    <span className="hopsti-green text-xs">{this.props.t('icons.security')}</span>
                                </div>
                            </Col>
                            <Col xs={"auto"} className="my-2 ">
                                <div
                                    className="icon-dashboard-container"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        this.handleFilterClick(7);
                                    }}
                                >
                                    <svg
                                        className={classnames("hopsti-dashboard-icon", {active: this.props.filterBy.indexOf(7) >= 0 ? true : false})}
                                        x="0px" y="0px" viewBox="0 0 480 480">
                                        <path className="st0" d="M121.7,345.22L121.7,345.22c-31.19,0-56.48-25.29-56.48-56.48v0c0-31.19,25.29-56.48,56.48-56.48h0
		c31.19,0,56.48,25.29,56.48,56.48v0C178.18,319.93,152.89,345.22,121.7,345.22z"/>
                                        <polyline className="st0"
                                                  points="150.76,337.15 222.56,385.56 222.56,425.9 20.84,425.9 20.84,385.56 92.61,337.15 	"/>
                                        <path className="st0" d="M357.99,345.22L357.99,345.22c-31.19,0-56.48-25.29-56.48-56.48v0c0-31.19,25.29-56.48,56.48-56.48h0
			c31.19,0,56.48,25.29,56.48,56.48v0C414.47,319.93,389.18,345.22,357.99,345.22z"/>
                                        <polyline className="st0"
                                                  points="387.05,337.15 458.84,385.56 458.84,425.9 257.13,425.9 257.13,385.56 328.9,337.15 		"/>
                                        <polygon className="st0"
                                                 points="167.81,54.1 167.81,163.6 205.56,163.6 234.08,206.82 262.61,163.6 363.75,163.6 363.75,54.1 	"/>
                                    </svg>
                                    <span className="hopsti-green text-xs">{this.props.t('icons.consultancy')}</span>
                                </div>
                            </Col>
                            <Col xs={"auto"} className="my-2 ">
                                <div
                                    className="icon-dashboard-container"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        this.handleFilterClick(8);
                                    }}
                                >
                                    <svg
                                        className={classnames("hopsti-dashboard-icon", {active: this.props.filterBy.indexOf(8) >= 0 ? true : false})}
                                        x="0px" y="0px" viewBox="0 0 480 480">
                                        <polyline className="st0"
                                                  points="407.64,90.47 460.5,90.47 460.5,421.46 19.18,421.46 19.18,90.47 85.84,90.47 	"/>
                                        <path className="st0" d="M239.84,90.47c0,0-22.99-45.97-160.9-27.58v275.83c108.03-36.78,160.9,36.78,160.9,36.78
		s57.46-73.55,160.9-36.78V62.88C262.83,44.5,239.84,90.47,239.84,90.47z"/>
                                        <line className="st0" x1="239.84" y1="81.27" x2="239.84" y2="246.77"/>
                                    </svg>
                                    <span className="hopsti-green text-xs">{this.props.t('icons.study')}</span>
                                </div>
                            </Col>
                            <Col xs={"auto"} className="my-2 ">
                                <div
                                    className="icon-dashboard-container"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        this.handleFilterClick(9);
                                    }}
                                >
                                    <svg
                                        className={classnames("hopsti-dashboard-icon", {active: this.props.filterBy.indexOf(9) >= 0 ? true : false})}
                                        x="0px" y="0px" viewBox="0 0 480 480">
                                        <path className="st0" d="M291.83,254.14c0,0-22.28,89.11-133.67,89.11S24.49,254.14,24.49,254.14V75.92c0,0,66.83-22.28,137.92-22.28
		c77.43,0,129.41,22.28,129.41,22.28V254.14z"/>
                                        <path className="st0" d="M162.04,291.27h-7.76c-22.46,0-40.67-18.21-40.67-40.67v-3.88h89.11v3.88
		C202.72,273.06,184.51,291.27,162.04,291.27z"/>
                                        <polygon className="st0"
                                                 points="132.17,173.6 80.19,173.6 80.19,173.6 132.17,173.6 		"/>
                                        <polygon className="st0"
                                                 points="236.13,172.45 184.15,172.45 184.15,172.45 236.13,172.45 		"/>
                                        <path className="st0" d="M325.78,142.75c77.43,0,129.41,22.28,129.41,22.28v178.22c0,0-22.28,89.11-133.67,89.11
		c-61.12,0-95.42-26.83-113.92-51.05"/>
                                        <path className="st0"
                                              d="M366.08,361.64c0-22.46-30.94-25.82-40.67-25.82h-7.76c-12.21,0-40.67,3.36-40.67,25.82"/>
                                        <polygon className="st0"
                                                 points="399.5,261.56 347.52,261.56 347.52,261.56 399.5,261.56 		"/>
                                    </svg>
                                    <span className="hopsti-green text-xs">{this.props.t('icons.leisure')}</span>
                                </div>
                            </Col>
                            <Col xs={"auto"} className="my-2">
                                <div
                                    className="icon-dashboard-container"
                                    rel="icon"
                                    onClick={() => {
                                        this.handleFilterClick(10);
                                    }}
                                >
                                    <svg
                                        className={classnames("hopsti-dashboard-icon", {active: this.props.filterBy.indexOf(10) >= 0 ? true : false})}
                                        x="0px" y="0px" viewBox="0 0 480 480">
                                        <polyline className="st0"
                                                  points="388.4,268.09 388.4,447.74 29.1,447.74 29.1,88.44 208.75,88.44 	"/>
                                        <polygon className="st0"
                                                 points="215.72,336.77 114.2,362.65 140.08,261.13 378.95,22.26 454.59,97.9 	"/>
                                        <line className="st0" x1="315.25" y1="85.96" x2="390.89" y2="161.6"/>
                                    </svg>
                                    <span className="hopsti-green text-xs">{this.props.t('icons.other-services')}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default withTranslation('common')(ServiceGroupsFilter);
