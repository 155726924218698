/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';

class AuthFooter extends React.Component {
    render() {
        return (
            <>
                <footer className="pt-3 pb-3" id="footer-main">
                    <Container>
                        <Row className="align-items-center justify-content-xl-between">
                            <Col xl="10">
                                <Nav
                                    className="nav-footer align-items-center">
                                    <Row>
                                        <Col md="auto" className="footer-item">
                                            <NavItem>
                                                <NavLink
                                                    href="/"
                                                >
                                                    <img
                                                        className="hopsti-logo"
                                                        alt="..."
                                                        src={require("assets/img/brand/hopsti_logo-01.svg")}
                                                    />
                                                </NavLink>
                                            </NavItem>
                                        </Col>
                                        <Col md="auto" className="footer-item">
                                            <NavItem>
                                                <NavLink
                                                    href="/kontaktid"
                                                    className="hopsti-green"
                                                    target="_blank"
                                                >
                                                    {this.props.t('links.contacts')}
                                                </NavLink>
                                            </NavItem>
                                        </Col>
                                        <Col md="auto" className="footer-item">
                                            <NavItem>
                                                <NavLink
                                                    className="hopsti-green"
                                                    href="/kuidas"
                                                    target="_blank"
                                                >
                                                    {this.props.t('links.about-hopsti')}
                                                </NavLink>
                                            </NavItem>
                                        </Col>
                                        <Col md="auto" className="footer-item">
                                            <NavItem>
                                                <NavLink
                                                    className="hopsti-green"
                                                    href="/privaatsus"
                                                    target="_blank"
                                                >
                                                    {this.props.t('links.privacy')}
                                                </NavLink>
                                            </NavItem>
                                        </Col>
                                        {/*<Col md="auto" className="footer-item">*/}
                                        {/*    <NavItem>*/}
                                        {/*        <NavLink*/}
                                        {/*            className="hopsti-green"*/}
                                        {/*            href="http://localhost:3000/licence-agreement"*/}
                                        {/*            target="_blank"*/}
                                        {/*        >*/}
                                        {/*            Kasutustingimused*/}
                                        {/*        </NavLink>*/}
                                        {/*    </NavItem>*/}
                                        {/*</Col>*/}
                                    </Row>
                                </Nav>
                            </Col>
                            <Col xl="2">
                                <Nav
                                    className="nav-footer justify-content-center align-items-center justify-content-xl-end">
                                    <Row>
                                        <Col md="auto" className="footer-item">
                                            <NavItem>
                                                <NavLink>
                                                    <img
                                                        className="el-fund-logo"
                                                        alt="..."
                                                        src={require("assets/img/brand/el_sotsiaalfond_horisontaalne.png")}
                                                    />
                                                </NavLink>
                                            </NavItem>
                                        </Col>
                                    </Row>
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </>
        );
    }
}

export default withTranslation('common')(AuthFooter);
