import React from "react";
import ReactGA from 'react-ga';
import {
    Alert,
    Button,
    Card,
    CardBody,
    Row,
    Col, Container, FormGroup, InputGroup, Input, Form
} from "reactstrap";
import axios from "../../axios";

class HopstiForgotPassword extends React.Component {
    state = {
        email: ""
    }

    componentDidMount() {
        ReactGA.initialize('UA-150997639-1');
        ReactGA.pageview('/forgot-password');
    }

    handleChange = async event => {
        const { target } = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const { name } = target;
        this.setState({
            [name]: value
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        if (this.state.email === "") {
            this.setState({
                showSuccessMessage: false,
                showErrorMessage: true
            });
            return;
        }

        axios
            .post("/rest-auth/password/reset/" + this.state.email + "/", {}, {
                headers: {
                    'Authorization': null
                }
            })
            .then(response => {
                this.setState({
                    showSuccessMessage: true,
                    showErrorMessage: false
                });
            })
            .catch(err => {
                console.log("HopstiForgotPassword.js - " + err);
                this.setState({
                    showErrorMessage: true,
                    showSuccessMessage: false
                });
            });
    };

    render() {
        let successMessage = null;
        let errorMessage = null;

        if (this.state.showSuccessMessage) {
            successMessage = (
                <Alert color="success">
                    <strong>Super!</strong> Edasine info parooli vahetuse kohta saadeti
                    meilile! Võid selle vahelehe sulgeda.
                </Alert>
            );
        }

        if (this.state.showErrorMessage) {
            errorMessage = (
                <Alert color="danger">
                    <strong>Viga!</strong> Tekkis viga, palun kontrolli sisestatud emaili!
                </Alert>
            );
        }
        return (
            <>
                <div className="main-content">
                    <Container className="mt-6 pb-5">
                        <Row className="card-wrapper justify-content-center">
                            <Col lg="6">
                                <Card>
                                    <CardBody>
                                        {successMessage}
                                        {errorMessage}
                                        <Row className="align-items-center pb-2">
                                            <div className="col">
                                                <h2 className="text-lg">
                                                    Unustasid parooli?
                                        </h2>
                                            </div>
                                        </Row>
                                        <Row className="align-items-center pb-2">
                                            <div className="col">
                                                <span className="text-sm text-muted">
                                                    Pole probleemi! Täida allolev vorm ja me saadame sulle parooli taastamise juhendi registreerumisel kasutatud eposti aadressile.
                                        </span>
                                            </div>
                                        </Row>
                                        <Row className="align-items-center">
                                            <Col className="col-6">
                                                <Form role="form">
                                                    <FormGroup>
                                                        <InputGroup className="input-group-merge input-group-alternative">
                                                            <Input
                                                                placeholder="Eposti aadress"
                                                                type="text"
                                                                name="email"
                                                                value={this.state.eamil}
                                                                onFocus={() => this.setState({ focusedEmail: true })}
                                                                onBlur={() => this.setState({ focusedEmail: false })}
                                                                onChange={e => {
                                                                    this.handleChange(e);
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center pb-2">
                                            <div className="col-6">
                                                <Button className="btn-hopsti-green" color="info" type="button" onClick={this.handleSubmit}>
                                                    Saada juhend
                                        </Button>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default HopstiForgotPassword;
