import {combineReducers} from "redux";
import auth from './auth';
import location from './location';
import services from "./services";
import helpRequests from "./helpRequests";
import offers from "./offers";

const appReducer = combineReducers({
    auth,
    services,
    helpRequests,
    offers,
    location,
});

export default appReducer;