import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    spServices: null,
    allServices: null,
};

const setSPServices = (state, action) => {
    return updateObject(state, {
        spServices: action.services
    })
};

const setAllServices = (state, action) => {
    return updateObject(state, {
        allServices: action.services
    })
};

const services = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SP_SERVICES:
            return setSPServices(state, action);
        case actionTypes.GET_ALL_SERVICES:
            return setAllServices(state, action);
        default:
            return state;
    }
};

export default services;