import React from "react";
import { Modal, Button } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import axios from "../../../../axios";
import YourServices from "../../../Service/YourServices/YourServices";
import AllServices from "../../../Service/AllServices/AllServices";
import AllHelpRequests from "../../../HelpRequest/AllHelpRequests/AllHelpRequests";
import HopstiAddHelpRequest from "components/HelpRequest/AddHelpRequest/HopstiAddHelpRequest";
import EditHelpRequest from "components/HelpRequest/EditHelpRequest/EditHelpRequest";
import CategorySelect from "components/CategorySelect/CategorySelect";

import * as services from "utils/services.js";
import MyJobs from "../../../MyJobs/MyJobs";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router";
import HelpRequestsTable from "components/KOV/Tables/HelpRequestsTable";

class PersoninNeedDashboard extends React.Component {
    state = {
        selectedService: null,
        services: [],
        userServices: [],
        helpRequests: [],
        kovHelpRequests: [],
        kovServices: [],
        serviceRemoved: false,
        serviceEdited: false,
        helpRequestRemoved: false,
        helpRequestEdited: false,
        editServiceModal: false,
        serviceDetailModal: false,
        addHelpRequestModal: false,
        editHelpRequesteModal: false,
        filterBy: [],
        helpRequestfilterBy: [],
        tabs: 2,
        alert: null,
    };


    componentDidMount() {
        console.log("User on mount ", this.props.user);
        this.getCategory(this.props.match.params.category);
        if (this.props.user) {
            this.getDashboardContent();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("User on update ", this.props.user);
        // Update services list only if props have changed

        if (this.props.user && this.props !== prevProps) {
            this.getCategory(this.props.match.params.category);
            this.getDashboardContent();
        }
        // If help request gets removed then update services list
        if (this.state.helpRequestRemoved || this.state.helpRequestEdited) {
            services.getUserHelpRequests(this.props.user.user_id).then(resp => {
                this.setState({
                    userHelpRequests: resp,
                    helpRequestRemoved: false,
                    helpRequestEdited: false
                });
            });
        }
    }

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        {title}
                    </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    showDeleteAlert = (id, removeHandler, confirmationMSG) => {
        this.setState({
            alert: (<ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title={confirmationMSG}
                onConfirm={() => {
                    removeHandler(id);
                    this.hideDeleteAlert();
                }}
                onCancel={() => this.hideDeleteAlert()}
                showCancel
                confirmBtnBsStyle="danger"
                confirmBtnText="Jah, kustuta!"
                cancelBtnBsStyle="secondary"
                cancelBtnText="Loobu"
                btnSize=""
            >
            </ReactBSAlert>)
        });
    };

    hideDeleteAlert = () => {
        this.setState({
            alert: null
        });
    };

    getCategory = (category) => {
        if (category) {
            if (category === "minu-abihõiked") this.setState({ tabs: 2 });
            if (category === "teenused") this.setState({ tabs: 3 });
            if (category === "minu-pakkumised") this.setState({ tabs: 6 });
        } else {
            this.setState({ tabs: 2 })
        }
    };

    getDashboardContent = () => {
        services.getUserHelpRequests(this.props.user.user_id).then(resp => {
            this.setState({ userHelpRequests: resp });
        });

        if (this.props.match.params.category === "minu-teenused") {
            services.getUserServices(this.state.myServicesPageNumber).then(resp => {
                this.setState({ userServices: resp });
            })
        }

        if (this.props.match.params.category === "minu-abihõiked") {
            services.getUserHelpRequests(this.props.user.user_id, this.state.myHelpRequestsPageNumber).then(resp => {
                this.setState({ userHelpRequests: resp });
            })
        }

        if (this.props.match.params.category === "teenused") {
            services.getAllServices(this.state.filterBy, this.props.user.user_id, this.state.servicesPageNumber).then(resp => {
                this.setState({ services: resp });
            })
        }
        if (this.props.match.params.category === "help-requests") {
            services.getAllHelpRequests(this.state.helpRequestfilterBy, this.state.helpRequestsPageNumber).then(resp => {
                this.setState({ helpRequests: resp });
            })
        }

        if (this.props.match.params.category === "minu-pakkumised" || "minu-tööd") {
            services.getOffers(false, this.props.match.params.itemId, this.props.user.user_id).then(offers =>
                this.setState({ offers: offers }))
        }
    };



    getOffers = async (isServiceProvider, id) => {
        services.getOffers(isServiceProvider, id, this.props.user.user_id).then((offers) => {
            this.setState({ offers: offers });
            this.setState({ tabs: 6 });
            this.props.history.push("/töölaud/minu-pakkumised/");
        });
    };

    getAllServicesWithSubFilter = (selectedSubCategory) => {
        if (selectedSubCategory !== undefined && selectedSubCategory.length !== 0) {
            this.setState({ filterBy: [] });
            services.getAllServicesWithSubFilter(selectedSubCategory.id, this.props.user.user_id).then(services => this.setState({ services: services }))
        } else {
            this.getDashboardContent()
        }
    };

    getAllHelpRequestsWithSubFilter = (selectedSubCategory) => {
        if (selectedSubCategory !== undefined && selectedSubCategory.length !== 0) {
            this.setState({ helpRequestfilterBy: [] });
            services.getAllHelpRequestsWithSubFilter(selectedSubCategory.id).then(helpRequests => this.setState({ helpRequests: helpRequests }))
        } else {
            this.getDashboardContent()
        }
    };

    getServicesOnFilterChange = () => {
        services.getAllServices(this.state.filterBy, this.props.user.user_id).then(resp => {
            this.setState({ services: resp });
        });
    };

    getHelpRequestsOnFilterChange = () => {
        services.getAllHelpRequests(this.state.helpRequestfilterBy).then(resp => {
            this.setState({ helpRequests: resp });
        });
    };

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    editServiceHandler = serviceId => {
        this.setState({
            selectedService: serviceId
        });
        this.toggleModal("editServiceModal");
    };

    editHelpRequestHandler = helpRequestId => {
        this.setState({
            helpRequestId: helpRequestId
        });
        this.toggleModal("editHelpRequesteModal");
    };

    serviceDetailHandler = serviceId => {
        this.setState({
            selectedService: serviceId
        });
        this.toggleModal("serviceDetailModal");
    };


    removeHelpRequestHandler = helpRequestId => {
        axios
            .delete("/help-requests/delete/" + helpRequestId)
            .then(response => {
                this.setState({
                    showSuccessMessage: true,
                    showErrorMessage: false,
                    helpRequestRemoved: true
                });
                this.notify("success", "Abihõike kustutamine õnnestus");
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    showErrorMessage: true,
                    showSuccessMessage: false,
                    helpRequestRemoved: false
                });
                this.notify("danger", "Abihõike kustutamine ebaõnnestus");
            });
    };
    handleHelpRequestAdded = () => {
        this.toggleModal("addHelpRequestModal");
        services.getUserHelpRequests(this.props.user.user_id).then(resp => {
            this.setState(
                { userHelpRequests: resp },
                this.notify(
                    "success",
                    "Lisamine õnnestus",
                    "Abipalve on edukalt lisatud!"
                )
            );
        });
    };

    handleEditService = actionTaken => {
        this.toggleModal("editServiceModal");
        if (actionTaken === "UPDATE") {
            this.setState({ serviceEdited: true });
            this.notify(
                "success",
                "Uuendamine õnnestus",
                "Teenus on edukalt uuendatud!"
            );
        }

        if (actionTaken === "DELETE") {
            this.setState({ serviceRemoved: true });
            this.notify(
                "danger",
                "Teenus kustutatud",
                "Teenuse kustutamine õnnestus!"
            );
        }
    };

    handleEditHelpRequest = actionTaken => {
        this.toggleModal("editHelpRequesteModal");
        if (actionTaken === "UPDATE") {
            this.setState({ helpRequestEdited: true });
            this.notify(
                "success",
                "Uuendamine õnnestus",
                "Abihõige on edukalt uuendatud!"
            );
        }

        if (actionTaken === "DELETE") {
            this.setState({ helpRequestRemoved: true });
            this.notify(
                "danger",
                "Abihõige kustutatud",
                "Abihõike kustutamine õnnestus!"
            );
        }
    };

    handleMatchClick = item => {
        this.setState({ tabs: 6 });
        this.props.history.push("/töölaud/minu-pakkumised/" + item);
    };

    handlePaginationClicked = pageNumber => {
        this.getDashboardContent(pageNumber);
    };

    toggleNavs = (e, state, index) => {
        this.setState({
            [state]: index
        });
    };

    modalCloseButton = (elementToClose) => {
        return <div className="modal-header">
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal(elementToClose)}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>
    }

    offerStatusNotificationHandler = (id) => {
        let statusText = "";
        switch (id) {
            case 1:
                statusText = "Pakkumine saadetud";
                break;
            case 2:
                statusText = "Pakkumine kinnitatud";
                break;
            case 3:
                statusText = "Pakkumine kinnitatud";
                break;
            case 4:
                statusText = "Teenus osutatud";
                break;
            case 5:
                statusText = "Tühistatud";
                break;
            case 6:
                statusText = "Arhiveeritud";
                break;

            default:
                break;
        }
        return statusText !== "" ?
            this.notify(
                "success",
                statusText,
                ""
            ) : null
    }

    render() {
        return (
            <>
                {this.state.alert}
                <div className="container-fluid pb-5">
                    <div className="rna-wrapper">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <div className="text-center mb-4">
                        <Button
                            onClick={this.props.closeHandler}
                            className="mt-4 btn-hopsti-pink"
                            type="button"
                        >
                            Tagasi abivajajate nimekirja
                        </Button>
                    </div>

                    {this.props.user ?
                        <h1 className="text-center mb-4">Abivajaja {this.props.user.first_name} {this.props.user.last_name}  ({this.props.user.code}) töölaud</h1> : null}

                    <CategorySelect
                        role={"ABIVAJAJA"}
                        toggleNavs={this.toggleNavs}
                        tabs={this.state.tabs}
                    />

                    {this.state.tabs === 1 ?
                        <YourServices
                            yourServices={this.state.userServices}
                            handleMatchClick={this.handleMatchClick}
                            editServiceHandler={this.editServiceHandler}
                            removeServiceHandler={(id) => {
                                this.showDeleteAlert(id, this.removeServiceHandler, "Kustuta teenus jäädavalt?")
                            }}
                            addServiceHandler={() =>
                                this.toggleModal("addServiceModal")
                            }
                        /> : null}

                    {this.state.tabs === 2 ?
                        <HelpRequestsTable
                            items={this.state.userHelpRequests}
                            handleMatchClick={this.handleMatchClick}
                            editHelpRequestHandler={this.editHelpRequestHandler}
                            removeHelpRequestHandler={(id) => {
                                this.showDeleteAlert(id, this.removeHelpRequestHandler, "kas soovid abihõike jäädavalt kustutada?")
                            }}
                            addHelpRequestHandler={() =>
                                this.toggleModal("addHelpRequestModal")
                            }
                        /> : null}

                    {this.state.tabs === 3 ?
                        <AllServices
                            services={this.state.services}
                            filterBy={this.state.filterBy}
                            getAllServicesWithSubFilter={this.getAllServicesWithSubFilter}
                            onFilterChange={e => {
                                this.setState({ filterBy: e }, this.getServicesOnFilterChange);
                            }}
                        /> : null}

                    {this.state.tabs === 4 ?
                        <AllHelpRequests
                            helpRequests={this.state.helpRequests}
                            filterBy={this.state.helpRequestfilterBy}
                            getAllHelpRequestsWithSubFilter={this.getAllHelpRequestsWithSubFilter}
                            onFilterChange={e => {
                                this.setState(
                                    { helpRequestfilterBy: e },
                                    this.getHelpRequestsOnFilterChange
                                );
                            }}
                        /> : null}

                    {this.state.tabs === 7 || this.state.tabs === 6 ?
                        <MyJobs
                            {...this.props}
                            getOffers={this.getOffers}
                            offers={this.state.offers}
                            offerStatusNotificationHandler={this.offerStatusNotificationHandler}
                            isKovSession={true}
                            userIdFromKov={this.props.user.user_id}
                        /> : null}

                    <Modal
                        size="lg"
                        className="modal-dialog-centered"
                        isOpen={this.state.editHelpRequesteModal}
                        toggle={() => this.toggleModal("editHelpRequesteModal")}
                    >
                        {this.modalCloseButton("editHelpRequesteModal")}
                        <EditHelpRequest
                            helpRequestId={this.state.helpRequestId}
                            handleEditHelpRequest={this.handleEditHelpRequest}
                            isKovSession={true}
                            userIdFromKov={this.props.user.user_id}
                        />
                    </Modal>

                    <Modal
                        size="lg"
                        className="modal-dialog-centered"
                        isOpen={this.state.addHelpRequestModal}
                        toggle={() => this.toggleModal("addHelpRequestModal")}
                    >
                        {this.modalCloseButton("addHelpRequestModal")}
                        <HopstiAddHelpRequest
                            handleHelpRequestAdded={this.handleHelpRequestAdded}
                            isKovSession={true}
                            userIdFromKov={this.props.user.user_id}
                        />
                    </Modal>
                </div>
            </>
        );
    }
}

export default withRouter(PersoninNeedDashboard);
