import React from "react";
import {
    Button, Container, Col, Row, Card, CardBody, Input
} from "reactstrap";
import axios from "../../../../axios";

class AssessPinHelpRequest extends React.Component {
    state = {
        data: [],
        description: "",
        descriptionSectionVisible: false,
        selectedId: null,
        selectedValue: null
    };

    componentDidMount = () => {
        axios
            .get("statuses/")
            .then(response => {
                const dataItems = response.data.filter(item => item.id > 10)
                this.setState({
                    data: dataItems
                })
            })
            .catch(err => {
                console.log(err);
            });
    }

    onDescriptionNeedeHandler = (id, value) => {
        this.setState({
            selectedId: id,
            selectedValue: value,
            descriptionSectionVisible: true
        })

    }

    onStatusClick = (id, value) => {
        if (id === 12) {
            this.onDescriptionNeedeHandler(id, value);
        } else {
            this.sendStatusUpdate(id, value);
        }
    }

    sendStatusUpdate = (id, value) => {
        axios
            .post("kov-help-request-status/", {
                description: this.state.description,
                help_request: this.props.helprequestId,
                status_id: id
            })
            .then(response => {
                this.props.closeHandler(this.props.helprequestId, id, value);
            })
            .catch(err => {
                console.log(err);
                this.props.closeHandler(this.props.helprequestId, null, null);
            });
    }
    handleChange = async event => {
        const { target } = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [name]: value
        });
    };

    render() {
        return (
            <Container className="">
                <Row className="justify-content-center">
                    <Col lg="12" md="12">
                        <Card className="bg-secondary border-0 mb-0">
                            <CardBody className="text-center">
                                {!this.state.descriptionSectionVisible ?
                                    <>
                                        <p>Hinda abihõiget.</p>
                                        <Row className="text-center d-block">
                                            <div className="justify-content-center mb-4">
                                                {this.state.data.map(status => {
                                                    return <Button
                                                        key={status.id}
                                                        onClick={() => this.onStatusClick(status.id, status.value)}
                                                        className="mt-4 btn-hopsti-pink assess-btn"
                                                        type="button">
                                                        {status.value}
                                                    </Button>
                                                })}
                                            </div>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <p>Lisa kontaktide andmed:</p>
                                        <Row className="text-center d-block">
                                            <Input
                                                placeholder="Info"
                                                type="textarea"
                                                name="description"
                                                value={this.state.description}
                                                onFocus={() => this.setState({ focusedInfo: true })}
                                                onBlur={() => this.setState({ focusedInfo: false })}
                                                onChange={e => {
                                                    this.handleChange(e);
                                                }}

                                            />
                                            <Button
                                                onClick={() => this.sendStatusUpdate(this.state.selectedId, this.state.selectedValue)}
                                                className="mt-4 btn-hopsti-pink assess-btn"
                                                type="button">
                                                Saada
                                            </Button>
                                        </Row>
                                    </>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container >
        );
    }
}

export default AssessPinHelpRequest;