import React from "react";
import {
    Badge,
    Col,
    Modal, Row, Button, Card, Container, CardBody
} from "reactstrap";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { sortCaret, renderAvatar } from "../../Table/TableContainer/Helpers/TableUtil"
import PersoninNeedDashboard from "../Views/PersoninNeedDashboard/PersoninNeedDashboard";


class PersonsInNeedTable extends React.Component {

    state = {
        pinConfirmmodal: false,
        isLoading: true,
        data: this.props.items ? this.props.items : [],
        selectedPinUser: null,
        showuserDetailViev: false
    };

    componentDidMount() {
        this.setState({
            data: this.props.items ? this.props.items : []
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.items !== this.props.items) {
            this.setState({
                isLoading: this.props.items === undefined,
                data: this.props.items ? this.props.items : []
            })
        }
    }

    toggleModal = (state, pin) => {
        console.log(pin);
        this.setState({
            [state]: !this.state.pinConfirmmodal
        });
    };

    render() {
        const renderButtons = (cell, row) => {
            return (
                <div className="d-flex align-items-center">
                    <Badge onClick={() => {
                        this.toggleModal("pinConfirmmodal");
                        this.setState({ selectedPinUser: row });
                    }}
                        className="badge badge-md pill btn-hopsti-pink clickable ml-2">{cell} abihõiget</Badge>
                </div>
            );
        };

        const ssidFilter = textFilter({
            placeholder: 'Otsi isikukoodi',
            className: 'form-control-sm mt-2',
        });

        const firstNameFilter = textFilter({
            placeholder: 'Otsi eesnime',
            className: 'form-control-sm mt-2',
        });

        const lastNameFilter = textFilter({
            placeholder: 'Otsi perenime',
            className: 'form-control-sm mt-2',
        });

        const columns = [{
            dataField: 'avatar',
            text: '',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("pinConfirmmodal");
                    this.setState({ selectedPinUser: row });
                }
            },
            formatter: renderAvatar,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            headerStyle: (column, colIndex) => {
                return { width: '65px', textAlign: 'center' };
            }
        }, {
            dataField: 'first_name',
            text: 'Eesnimi',
            sort: true,
            classes: 'clickable',
            sortCaret: (order, column) => sortCaret(order, column),
            filter: firstNameFilter,
            headerStyle: (column, colIndex) => {
                return { width: '200px' };
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("pinConfirmmodal");
                    this.setState({ selectedPinUser: row });
                }
            }
        }, {
            dataField: 'last_name',
            text: 'Perenimi',
            sort: true,
            classes: 'clickable',
            sortCaret: (order, column) => sortCaret(order, column),
            filter: lastNameFilter,
            headerStyle: (column, colIndex) => {
                return { width: '200px' };
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("pinConfirmmodal");
                    this.setState({ selectedPinUser: row });
                }
            }
        }, {
            dataField: 'code',
            text: 'Isikukood',
            sort: true,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            sortCaret: (order) => sortCaret(order),
            filter: ssidFilter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("pinConfirmmodal");
                    this.setState({ selectedPinUser: row });
                }
            },
        }, {
            dataField: 'status',
            text: 'Staatus',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell align-text-top',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("pinConfirmmodal");
                    this.setState({ selectedPinUser: row });
                }
            }
        }, {
            dataField: 'count',
            text: 'Abihõiked',
            headerClasses: 'align-text-top',
            formatter: renderButtons,
        }];

        const NoDataIndication = () =>
            <div className="spinner pt-4 pb-4">
                <span className="hopsti-pink">Sinu piirkonnas pole abivajajaid!</span>
            </div>;

        return (
            <>
                {!this.state.showuserDetailViev ?
                    <Row className="justify-content-center pt-2 pb-2">
                        <Col className="col-12">
                            <BootstrapTable
                                striped
                                hover
                                wrapperClasses="table-responsive"
                                bordered={false}
                                keyField='id'
                                data={this.state.data}
                                filter={filterFactory()}
                                columns={columns}
                                pagination={paginationFactory({ hideSizePerPage: true })}
                                noDataIndication={() => <NoDataIndication />}
                            />
                        </Col>
                    </Row>
                    :
                    <PersoninNeedDashboard user={this.state.selectedPinUser} closeHandler={() => this.setState({ showuserDetailViev: false })} />}

                <Modal
                    size="md"
                    className={"modal-dialog-centered"}
                    isOpen={this.state.pinConfirmmodal}
                    toggle={() => this.toggleModal("pinConfirmmodal")}
                ><Container className="">
                        <Row className="justify-content-center">
                            <Col lg="12" md="12">
                                <Card className="bg-secondary border-0 mb-0">
                                    <CardBody className="text-center">
                                        <p>Kas soovid siseneda selle kasutaja vaatesse?</p>
                                        <Row className="text-center d-block">
                                            <div className="justify-content-center mb-4">
                                                <Button
                                                    onClick={() => this.toggleModal("pinConfirmmodal")}
                                                    className="mt-4 btn-hopsti-pink"
                                                    type="button"
                                                >
                                                    Tagasi abivajajate nimekirja
                                                </Button>
                                                <Button
                                                    onClick={() => { this.setState({ showuserDetailViev: true }); this.toggleModal("pinConfirmmodal") }}
                                                    className="mt-4 btn-hopsti-green"
                                                    type="button"
                                                >
                                                    Sisene
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardBody>


                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </>
        );
    }
}

export default PersonsInNeedTable;