export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const FB_AUTH_START = 'FB_AUTH_START';
export const FB_AUTH_SUCCESS = 'FB_AUTH_SUCCESS';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_USER = 'SET_USER';
export const SET_ADDRESS = 'SET_ADDRESS';
export const GET_SP_SERVICES = 'GET_SP_SERVICES';
export const GET_ALL_SERVICES = 'GET_ALL_SERVICES';
export const GET_PIN_HELP_REQUESTS = 'GET_PIN_HELP_REQUESTS';
export const GET_ALL_HELP_REQUESTS = 'GET_ALL_HELP_REQUESTS';
export const GET_OFFERS = 'GET_OFFERS';