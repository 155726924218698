import React from "react";
import {
    Col,
    Row,
} from "reactstrap";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {withTranslation} from 'react-i18next';

// React bootstrap table 2
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';

import {sortCaret, renderAvatar} from "./Helpers/TableUtil"

class PublicServicesTable extends React.Component {

    state = {
        serviceModal: false,
        data: []
    };

    componentDidMount() {
        this.setState({
            data: this.props.items ? this.props.items : []
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.items !== this.props.items) {
            this.setState({
                data: this.props.items ? this.props.items : []
            })
        }
    }

    toggleModal = (state, service) => {
        this.setState({
            [state]: !this.state.serviceModal,
            selectedService: service
        });
    };

    render() {
        const renderButtons = (cell, row) => {
            return (
                <div className="d-flex align-items-center">
                    <i className="fa fa-2x fa-search hopsti-pink pl-2 pr-2 clickable"
                       onClick={() => this.props.toggle()}
                       title={this.props.t('service-table.actions.detail-view')}
                       aria-hidden="true"/>
                </div>
            );
        };

        const renderPrice = (cell, row) => {
            return (
                row.cost + " €/" + row.unit.unit
            );
        };

        const NoDataIndication = () =>
            <div className="spinner pt-4 pb-4">
                {!this.props.items ? <i className="fas fa-3x fa-spinner fa-pulse hopsti-green"/> :
                    <span className="hopsti-pink">{this.props.t('service-table.no-data')}</span>}
            </div>;

        const serviceFilter = textFilter({
            placeholder: this.props.t('service-table.headers.search-for-service'),  // custom the input placeholder
            className: 'form-control-sm mt-2', // custom classname on input
        });

        const descriptionFilter = textFilter({
            placeholder: this.props.t('service-table.headers.search-for-description'),  // custom the input placeholder
            className: 'form-control-sm mt-2', // custom classname on input
        });

        const columns = [{
            dataField: 'avatar',
            text: '',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.props.toggle()
                }
            },
            formatter: renderAvatar,
            classes: "blurred-image",
            headerStyle: (column, colIndex) => {
                return {width: '70px', textAlign: 'center'};
            }
        }, {
            dataField: 'user.first_name',
            text: this.props.t('service-table.headers.added-by'),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell align-text-top',
            headerStyle: (column, colIndex) => {
                return {width: '100px'};
            },
            sort: true,
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.props.toggle()
                }
            }
        }, {
            dataField: 'created_at',
            text: this.props.t('service-table.headers.added-date'),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell align-text-top',
            headerStyle: (column, colIndex) => {
                return {width: '100px'};
            },
            sort: true,
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.props.toggle()
                }
            }
        }, {
            dataField: 'name',
            text: this.props.t('service-table.headers.service-name'),
            sort: true,
            classes: 'clickable hopsti-green',
            headerClasses: 'align-text-top',
            sortCaret: (order, column) => sortCaret(order, column),
            filter: serviceFilter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.props.toggle()
                }
            },
            headerStyle: (column, colIndex) => {
                return {width: '200px'};
            },
        }, {
            dataField: 'description_ee',
            text: this.props.t('service-table.headers.service-description'),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell align-text-top',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            filter: descriptionFilter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.props.toggle()
                }
            },
        }, {
            dataField: 'cost',
            text: this.props.t('service-table.headers.price'),
            classes: 'clickable',
            headerClasses: 'align-text-top',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            headerStyle: (column, colIndex) => {
                return {width: '100px'};
            },
            formatter: renderPrice,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.props.toggle()
                }
            }
        }, {
            dataField: '',
            text: this.props.t('service-table.headers.buttons'),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell align-text-top',
            formatter: renderButtons,
            headerStyle: (column, colIndex) => {
                return {width: '90px'};
            },
        }];

        return (
            <>
                <Row className="justify-content-center pt-2 pb-2">
                    <Col className="col-12">
                        <BootstrapTable
                            striped
                            hover
                            bordered={false}
                            wrapperClasses="table-responsive"
                            keyField='id'
                            data={this.state.data}
                            columns={columns}
                            filter={filterFactory()}
                            pagination={paginationFactory({hideSizePerPage: true})}
                            noDataIndication={() => <NoDataIndication/>}
                            loading={!this.props.items}
                        />
                    </Col>
                </Row>
                {/*<Modal*/}
                {/*    size="lg"*/}
                {/*    className={this.state.selectedService && this.state.selectedService.files && this.state.selectedService.files.length > 0 ? "modal-dialog-centered matches-modal" : "modal-dialog-centered matches-modal-sm"}*/}
                {/*    isOpen={!!(this.state.serviceModal && this.state.selectedService)}*/}
                {/*    toggle={() => this.toggleModal("serviceModal")}*/}
                {/*>*/}
                {/*    <DetailView isService={true} service={this.state.selectedService}/>*/}
                {/*</Modal>*/}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        user: state.auth.user
    };
};

export default withRouter(connect(mapStateToProps, null)(withTranslation('common')(PublicServicesTable)));