import React from "react";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import {connect} from "react-redux";
import axios from "../../axios";
import {withRouter} from "react-router";
import OffersTableServiceProvider from "../Table/TableContainer/OffersTableServiceProvider";
import OffersTablePersonInNeed from "../Table/TableContainer/OffersTablePersonInNeed";

import {withTranslation} from 'react-i18next';

class MyJobs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            offers: [],
            items: [],
            activeItem: this.props.match.params.itemId
        };
    }

    componentDidMount() {
        if (this.props.isServiceProvider) {
            this.getMyServices();
        } else {
            this.getMyHelpRequests();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (!Array.isArray(this.props.offers)) {
                this.setState({isLoading: false})
            }
        }

        if (prevState.activeItem !== this.state.activeItem) {
            this.props.getOffers(this.props.isServiceProvider, this.props.match.params.itemId);
        }
    }

    getOfferDetails = async (offerId) => {
        const UserId = this.props.isKovSession ? this.props.userIdFromKov : this.props.user.pk;
        return axios
            .get("offers/" + offerId + "/" + UserId + "/")
            .then(response => {
                if (response.data) {
                    this.setState({
                        selectedOffer: response.data
                    });
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    getMyServices = () => {
        return axios
            .get("my-services/")
            .then(response => {
                if (response.data) {
                    this.setState({
                        items: response.data
                    }, () => {
                        if (this.props.match.params.itemId) {
                            // eslint-disable-next-line array-callback-return
                            this.state.items.map(item => {
                                if (item.id.toString() === this.props.match.params.itemId) {
                                    this.setState({
                                        activeItem: item.name
                                    });
                                }
                            })
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    getMyHelpRequests = () => {
        const hrUrl = this.props.isKovSession ? "/help-requests/user/" + this.props.userIdFromKov + "/" : "my-help-requests/";
        return axios
            .get(hrUrl)
            .then(response => {
                if (response.data) {
                    this.setState({
                        items: response.data
                    }, () => {
                        if (this.props.match.params.itemId) {
                            // eslint-disable-next-line array-callback-return
                            this.state.items.map(item => {
                                console.log(item.id.toString());
                                console.log(this.props.match.params.itemId);
                                if (item.id.toString() === this.props.match.params.itemId) {
                                    this.setState({
                                        activeItem: this.props.isKovSession ? item.title : item.name
                                    });
                                }
                            })
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState !== this.state || this.props !== nextProps
    }

    toggleModal = async (state, offer) => {
        if (offer) await this.getOfferDetails(offer.offer_id);
        this.setState({
            [state]: !this.state[state]
        });
    };

    render() {
        const items = this.props.offers ? this.props.offers : [];

        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xl={10}>
                        <Card>
                            <CardBody>
                                {this.props.isServiceProvider ?
                                    <div className="mb-3">
                                        <h3>{this.props.t('offers.service-provider.heading')}.</h3>
                                        <span>{this.props.t('offers.service-provider.sub-heading')}.</span>
                                    </div>
                                    :
                                    <div className="mb-3">
                                        <h3>{this.props.t('offers.person-in-need.heading')}.</h3>
                                        <span>{this.props.t('offers.person-in-need.sub-heading')}.</span>
                                    </div>}
                                <div>
                                    <UncontrolledDropdown>
                                        <DropdownToggle className="btn-sm btn-hopsti-green mt-2" caret>
                                            {this.state.activeItem ? this.state.activeItem : this.props.isServiceProvider ? this.props.t('offers.service-provider.select-offer') : this.props.t('offers.person-in-need.select-offer')}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => {
                                                this.props.getOffers(this.props.isServiceProvider);
                                                this.props.isServiceProvider ? this.props.history.push("/töölaud/minu-tööd/") :
                                                    this.props.history.push("/töölaud/minu-pakkumised/");
                                            }}>
                                                Vaata kõiki
                                            </DropdownItem>
                                            {this.state.items.map((item) => {
                                                return (<DropdownItem key={item.id} onClick={() => {
                                                    this.props.getOffers(this.props.isServiceProvider, item.id);
                                                    this.props.isServiceProvider ? this.props.history.push("/töölaud/minu-tööd/" + item.id) :
                                                        this.props.history.push("/töölaud/minu-pakkumised/" + item.id);
                                                }}>
                                                    {this.props.isKovSession ? item.title : item.name}
                                                </DropdownItem>)
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </CardBody>
                        </Card>
                        {this.props.isServiceProvider ?
                            <OffersTableServiceProvider items={items} getOffers={this.props.getOffers}
                                                        showNotificationHandler={this.props.offerStatusNotificationHandler}/>
                            :
                            <OffersTablePersonInNeed items={items} getOffers={this.props.getOffers}
                                                     showNotificationHandler={this.props.offerStatusNotificationHandler}
                                                     isKovSession={this.props.isKovSession}
                                                     userIdFromKov={this.props.userIdFromKov}/>
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        user: state.auth.user,
        isServiceProvider: state.auth.user
            ? state.auth.user.is_service_provider
            : false
    }
};

export default withRouter(connect(mapStateToProps, null)(withTranslation('common')(MyJobs)));