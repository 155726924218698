import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {connect} from 'react-redux';

import * as actions from './store/actions/auth';

import CustomLayout from "./components/Layout/Layout";
import HopstiRegister from "./components/Activate/HopstiRegister";
import HopstiForgotPassword from "./components/ForgotPassword/HopstiForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import HopstiDashboard from 'components/Dashboard/HopstiDashboard';
import HopstiPublicDasboard from 'components/Dashboard/HopstiPublicDasboard';
import KovLanding from "./components/LandingPage/KovLanding";
import Landing from "./components/LandingPage/Landing";
import ContentPage from "./components/ContentPage/ContentPage";
import ContactForm from "./components/ContactForm/ContactForm";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        this.props.onTryAutoSignup();
    }

    render() {
        return (
            <BrowserRouter>
                <CustomLayout {...this.props}>
                    {this.props.isAuthenticated ?
                        <Switch>
                            <Route exact path="/" component={HopstiDashboard}/>
                            <Route exact path="/kov" component={HopstiDashboard}/>
                            <Route path="/töölaud/:category?/:itemId?" component={HopstiDashboard}/>
                            <Route path="/register/:uidb64/:token" component={HopstiRegister}/>
                            <Route path="/kontaktid" component={ContactForm}/>
                            <Route path="/kuidas" render={(props) => <ContentPage {...props} url="kkk"/>}/>
                            <Route path="/privaatsus" render={(props) => <ContentPage {...props} url="privacy"/>}/>
                            <Route path="/licence-agreement" render={(props) => <ContentPage {...props} url="kkk"/>}/>
                            <Route path="/change-password"
                                   render={(props) => <ResetPassword {...props} renderPage="change"/>}/>
                        </Switch>
                        :
                        <Switch>
                            <Route path="/forgot-password" component={HopstiForgotPassword}/>
                            <Route path="/meist" render={(props) => <ContentPage {...props} url="meist"/>}/>
                            <Route path="/kontaktid" component={ContactForm}/>
                            <Route path="/kuidas" render={(props) => <ContentPage {...props} url="kkk"/>}/>
                            <Route path="/privaatsus" render={(props) => <ContentPage {...props} url="privacy"/>}/>
                            <Route path="/licence-agreement" render={(props) => <ContentPage {...props} url="kkk"/>}/>
                            <Route path="/reset-password/:uidb64/:token" component={ResetPassword}/>
                            <Route path="/public-dashboard" component={HopstiPublicDasboard}/>
                            <Route path="/register/:uidb64/:token" component={HopstiRegister}/>
                            <Route path="/registreeri-abivajajaks"
                                   render={(props) => <Landing {...props} isServiceProvider={false}/>}/>
                            <Route path="/registreeri-teenusepakkujaks"
                                   render={(props) => <Landing {...props} isServiceProvider={true}/>}/>
                            <Route path="/kov" component={KovLanding}/>
                            <Route path="/" component={Landing}/>
                        </Switch>}
                </CustomLayout>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.checkAuthState())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);