import React from "react";
import ReactDOM from "react-dom";
import {createStore, compose, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import {hotjar} from 'react-hotjar';
import thunk from 'redux-thunk';
import App from './App';
import * as serviceWorker from './serviceWorker';

import appReducer from './store/reducers/index';

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-pro-react.scss";
import "assets/css/hopsti-custom.css"
import "react-notification-alert/dist/animate.css";

import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";

import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import common_et from "./i18n/et/resource.json";

i18next.init({
    interpolation: {escapeValue: false},
    lng: 'et',
    resources: {
        et: {
            common: common_et
        },
    },
});

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(appReducer, composeEnhances(
    applyMiddleware(thunk)
));
hotjar.initialize(1595754);

const app = (
    <Provider store={store}>
        <I18nextProvider i18n={i18next}>
            <App/>
        </I18nextProvider>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

