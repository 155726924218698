import React from "react";
import TableContainer from "../../../Table/TableContainer/TableContainer";
import OurServiceProvidersTable from "../../Tables/OurServiceProvidersTable"

class ServiceProviders extends React.Component {
    render() {
        return (
            <TableContainer
                addServiceHandler={this.props.addServiceHandler}
            >
                <OurServiceProvidersTable
                    items={this.props.ourServiceProviders}
                    removeHandler={this.props.removeServiceHandler}
                    editServiceHandler={this.props.editServiceHandler}
                    handleMatchClick={this.props.handleMatchClick}/>
            </TableContainer>
        );
    }
}

export default ServiceProviders;