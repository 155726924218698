import React from "react";
import {
    Button,
    Col,
    Container,
    Row,
} from "reactstrap";
import { withTranslation } from 'react-i18next';


class TableContainer extends React.Component {
    render() {
        return (
            <>
                <Container fluid className="pl-0 pr-0">
                    <Row className="justify-content-center">
                        <Col className="col-12 col-sm-10">
                            {this.props.addServiceHandler ?
                                <div className="header-body text-center pt-2 mb-4">
                                    <Row className="justify-content-center">
                                        <Col className="px-5" lg="6" md="8" xl="5">
                                            <Button
                                                className="btn btn-hopsti-pink btn-hopsti-nav"
                                                onClick={this.props.addServiceHandler}
                                            >
                                                {this.props.t('buttons.add-new-service')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div> : null}
                            {this.props.addHelpRequestHandler ?
                                <div className="header-body text-center pt-2 mb-4">
                                    <Row className="justify-content-center">
                                        <Col className="px-5" lg="6" md="8" xl="5">
                                            <Button
                                                to="/add-helprequest"
                                                className="btn btn-hopsti-pink btn-hopsti-nav"
                                                onClick={this.props.addHelpRequestHandler}
                                            >
                                                {this.props.t('buttons.add-new-help-request')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div> : null}
                            {this.props.children}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withTranslation('common')(TableContainer);