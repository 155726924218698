import React from "react";
import {
    Col,
    Modal, Row, Button
} from "reactstrap";
import DetailView from "../../DetailView/DetailView";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { sortCaret, renderAvatar } from "../../Table/TableContainer/Helpers/TableUtil"
import overlayFactory from "react-bootstrap-table2-overlay";
import axios from "../../../axios";



class ServicesTable extends React.Component {
    state = {
        serviceModal: false,
        isLoading: true,
        data: [],
        assessModal: false
    };

    componentDidMount() {
        axios
            .get("/services/user/" + this.props.serviceProvider.created_by + "/")
            .then(response => {
                if (response.data) {
                    console.log("Sp service list ", response.data)
                    this.setState({ data: response.data, isLoading: false });
                }
            })
            .catch(err => {
                console.log(err);
            });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    toggleModal = (state, service) => {
        this.setState({
            [state]: !this.state.serviceModal,
            selectedService: service
        });
    };


    render() {
        const renderButtons = (cell, row) => {
            return (
                <div className="d-flex align-items-center">
                    <i className="fa fa-2x fa-search hopsti-pink pl-2 clickable"
                        onClick={() => this.toggleModal("serviceModal", row)}
                        title="Vaata detailvaadet"
                        aria-hidden="true" />
                </div>
            );
        };

        const renderPrice = (cell, row) => {
            return (
                row.cost + " €/" + row.unit.unit
            );
        };

        const columns = [{
            dataField: 'avatar',
            text: '',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            },
            formatter: renderAvatar,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            headerStyle: (column, colIndex) => {
                return { width: '65px', textAlign: 'center' };
            }
        }, {
            dataField: 'created_at',
            text: 'Lisatud',
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            headerStyle: (column, colIndex) => {
                return { width: '100px' };
            },
            sort: true,
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'name',
            text: 'Teenus',
            sort: true,
            classes: 'clickable',
            sortCaret: (order, column) => sortCaret(order, column),
            headerStyle: (column, colIndex) => {
                return { width: '200px' };
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'description_ee',
            text: 'Kirjeldus',
            sort: true,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            },
        }, {
            dataField: 'cost',
            text: 'Hind',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            headerStyle: (column, colIndex) => {
                return { width: '90px' };
            },
            formatter: renderPrice,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: '',
            text: 'Tegevused',
            formatter: renderButtons,
            headerStyle: (column, colIndex) => {
                return { width: '200px' };
            },
        }];

        const NoDataIndication = () =>
            <div className="spinner pt-4 pb-4">
                {this.state.isLoading ?
                    <></>
                    :
                    <span className="hopsti-pink">Sa pole ühtegi teenust lisanud</span>
                }
            </div>;

        return (
            <>
                <div className="text-center mb-4">
                    <Button
                        onClick={this.props.backHandler}
                        className="mt-4 btn-hopsti-green"
                        type="button"
                    >
                        Tagasi teenusepakkujate nimekirja
                    </Button>
                </div>

                <h1 className="text-center mb-4">{this.props.serviceProvider.first_name} teenused:</h1>

                <Row className="justify-content-center pt-2 pb-2">
                    <Col className="col-12">
                        <BootstrapTable
                            striped
                            hover
                            bordered={false}
                            wrapperClasses="table-responsive"
                            keyField='id'
                            data={this.state.data}
                            columns={columns}
                            pagination={paginationFactory({ hideSizePerPage: true })}
                            noDataIndication={() => <NoDataIndication />}
                            loading={this.state.isLoading}
                            overlay={overlayFactory({ spinner: true })}
                        />
                    </Col>
                </Row>
                <Modal
                    size="lg"
                    className={this.state.selectedService && this.state.selectedService.files && this.state.selectedService.files.length > 0 ? "modal-dialog-centered matches-modal" : "modal-dialog-centered matches-modal-sm"}
                    isOpen={!!(this.state.serviceModal && this.state.selectedService)}
                    toggle={() => this.toggleModal("serviceModal")}
                >
                    <DetailView isService={true} service={this.state.selectedService} />
                </Modal>
            </>
        );
    }
}

export default ServicesTable;