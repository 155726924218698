import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { connect } from "react-redux";
import {
    Input
} from "reactstrap";
import Geocode from "react-geocode";

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: "",
            addressValid: true
        };

    }

    componentDidMount() {
        this.setState({ address: this.props.address });
        this.props.address ? this.setState({ addressValid: true }) : this.setState({ addressValid: false });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({ address: this.props.address });
        }
    }

    handleChange = address => {
        this.setState({ address });
        address ? this.setState({ addressValid: true }) : this.setState({ addressValid: false });
    };

    handleSelect = address => {
        this.setState({ address });
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                if (this.props.handleAddress) {
                    this.getAddressSAdminLevels(latLng).then((adminLevels) =>
                        this.props.handleAddress(latLng.lat, latLng.lng, address, adminLevels.adminlevel1, adminLevels.adminlevel2)
                    );
                }
            })
            .catch(error => console.error('Error', error));
    };

    getAddressSAdminLevels = (latLng) => {
        return new Promise(adminLevels => {
            Geocode.setApiKey("AIzaSyBQfDL-vxWsw3CQO_WA1cV8Gxv0eeZb4hk");
            Geocode.setLanguage("et");
            Geocode.fromLatLng(latLng.lat, latLng.lng).then(
                response => {
                    const adminlevel1 = this.getAdminAreaLevel1(response.results);
                    const adminlevel2 = this.getAdminLevel2(response.results);
                    return adminLevels({ adminlevel1: adminlevel1, adminlevel2: adminlevel2 });
                },
                error => ({ error: error })
            );
        })
    }

    getAdminAreaLevel1 = (addressList) => {
        const address = addressList.find(el => el.types[0] === "administrative_area_level_1");
        return address.address_components[0].long_name;
    }

    getAdminLevel2 = (addressList) => {
        const address = addressList.find(el => el.types[0] === "administrative_area_level_2");
        return address.address_components[0].long_name;
    }

    render() {
        const searchOptions = {
            componentRestrictions: { country: "ee" }

        };

        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={searchOptions}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="autocomplete-wrapper">
                        <Input invalid={!this.state.addressValid}
                            {...getInputProps({
                                placeholder: "Sisesta aadress",
                                className: 'location-search-input form-control',
                            })}
                        />
                        {this.state.address ?
                            <div className="autocomplete-dropdown-container">
                                {loading && <div className="autocomplete-loading-container">Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                            : null}
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token,
        user: state.auth.user
    }
};



export default connect(mapStateToProps)(LocationSearchInput);