import * as actionTypes from './actionTypes';

export const onGetSPServices = (services) => {
    return {
        type: actionTypes.GET_SP_SERVICES,
        services: services
    }
};

export const onGetAllServices = (services) => {
    return {
        type: actionTypes.GET_ALL_SERVICES,
        services: services
    }
};