/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Button, Container, Row, Col, Modal} from "reactstrap";
import HopstiRegisterUser from "../Register/HopstiRegisterUser";
import ReactGA from 'react-ga';
import {withTranslation} from 'react-i18next';


class IndexHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            registerModal: false,
            isServiceProvider: false
        };
    }

    registerUserHandler = isServiceProvider => {
        this.setState({
            isServiceProvider: isServiceProvider
        });

        if (isServiceProvider) {
            ReactGA.ga('send', {
                hitType: 'event',
                eventCategory: 'Registration',
                eventAction: 'registerStep1TP',
                eventLabel: 'Service provider registration'
            });
        } else {
            ReactGA.ga('send', {
                hitType: 'event',
                eventCategory: 'Registration',
                eventAction: 'registerStep1AV',
                eventLabel: 'Person in need registration'
            });
        }

        this.toggleModal("registerModal");
    };

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    render() {
        return (
            <>
                <div className="hopsti-jumbotron header pt-5 pb-5">
                    <Container>
                        <div className="header-body">
                            <Row className="align-items-center">
                                <Col lg="6">
                                    <div className="pr-sm-6">
                                        <h1 className="display-2 hopsti-pink font-weight-bold mb-0">
                                            {this.props.t('landing.heading')}
                                        </h1>
                                        <h2 className="display-4 mt-5 hopsti-green font-weight-light jumbotron-sub-heading">
                                            {this.props.t('landing.sub-heading')}
                                        </h2>
                                        <div className="mt-5 jumbotron-btn-container">
                                            <Button
                                                size="lg"
                                                className="btn btn-hopsti-pink btn-jumbotron my-2"
                                                onClick={() => this.registerUserHandler(false)}
                                            >
                                                {this.props.t('buttons.register-as-pin')}
                                            </Button>
                                            <Button
                                                size="lg"
                                                className="btn btn-hopsti-green btn-jumbotron my-2"
                                                onClick={() => this.registerUserHandler(true)}
                                            >
                                                {this.props.t('buttons.register-as-sp')}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <Modal
                    size="md"
                    className="modal-dialog-centered"
                    isOpen={this.state.registerModal}
                    toggle={() => this.toggleModal("registerModal")}
                >
                    <HopstiRegisterUser
                        isServiceProvider={this.state.isServiceProvider}
                    />
                </Modal>
            </>
        );
    }
}

export default withTranslation('common')(IndexHeader);
