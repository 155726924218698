import React from "react";
import {
    Badge,
    Col,
    Row
} from "reactstrap";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { sortCaret, renderAvatar } from "../../Table/TableContainer/Helpers/TableUtil"
import axios from "../../../axios";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ServicesTable from "./ServicesTable";


class OurServiceProvidersTable extends React.Component {

    state = {
        selectedServiceProvider: null,
        serviceModal: false,
        isLoading: true,
        data: this.props.items ? this.props.items : []
    };

    componentDidMount() {
        this.setState({
            data: this.props.items ? this.props.items : []
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.items !== this.props.items) {
            this.setState({
                isLoading: this.props.items === undefined,
                data: this.props.items ? this.props.items : []
            })
        }
    }

    toggleModal = (state, serviceProvider) => {
        this.setState({
            [state]: !this.state.serviceModal,
            selectedServiceProvider: serviceProvider
        });
    };

    handleRecommendClicked = async (row) => {
        if (row.pennant.is_user_set) {
            return axios
                .put("/pennant/", {
                    description: "Added pennant",
                    sp_user: row.created_by
                })
                .then(resp => {
                    console.log(resp);
                })
        } else {
            return axios
                .post("/pennant/", {
                    description: "-",
                    sp_user: row.created_by
                })
        }
    };

    handleWarnClicked = async (row) => {
        if (!row.problem_notification) {
            return axios
                .post("/problem-notification/", {
                    description: "-",
                    sp_user: row.created_by
                })
        }
    };

    render() {
        const renderButtons = (cell, row) => {
            return (
                <div className="d-flex align-items-center">
                    <Badge onClick={() => {
                        this.toggleModal("pinConfirmmodal");
                        this.setState({ selectedPinUser: row });
                    }}
                        className="badge badge-md pill btn-hopsti-pink clickable ml-2">{cell} teenust</Badge>
                </div>
            );
        };

        const renderName = (cell, row) => {
            if (row.first_name && row.last_name) {
                return (row.first_name + " " + row.last_name);
            } else {
                return row.first_name
            }

        };

        const renderTopLevels = (cell, row) => {
            return (
                Array.prototype.map.call(row.top_levels, s => " " + s).toString()
            );
        };

        const renderRecommend = (cell, row) => {
            return (
                <i className={cell.is_user_set ? "fas fa-2x fa-medal active" : "fas fa-2x fa-medal"} />
            );
        };

        const renderWarn = (cell, row) => {
            return (
                <i className={cell ? "fas fa-2x fa-exclamation-circle active" : "fas fa-2x fa-exclamation-circle clickable"} />
            );
        };

        const priceFilter = textFilter({
            placeholder: 'Otsi kategooriat',  // custom the input placeholder
            className: 'form-control-sm mt-2', // custom classname on input
            style: {}, // your custom styles on input
        });

        const nameFilter = textFilter({
            placeholder: 'Otsi teenusepakkujat',  // custom the input placeholder
            className: 'form-control-sm mt-2', // custom classname on input
            style: {}, // your custom styles on input
        });

        const columns = [{
            dataField: 'avatar',
            text: '',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            },
            formatter: renderAvatar,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell kov-sp-th',
            headerStyle: (column, colIndex) => {
                return { width: '85px', textAlign: 'center' };
            }
        }, {
            dataField: 'first_name',
            text: 'Teenusepakkuja',
            sort: true,
            classes: 'clickable',
            headerClasses: 'kov-sp-th',
            sortCaret: (order, column) => sortCaret(order, column),
            formatter: renderName,
            filter: nameFilter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'first_service_date',
            text: 'Esimene teenus lisatud',
            sort: true,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell kov-sp-th',
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'top_levels',
            text: 'Tegevusalad',
            sort: true,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell kov-sp-th',
            sortCaret: (order) => sortCaret(order),
            formatter: renderTopLevels,
            filter: priceFilter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            },
            headerStyle: (column, colIndex) => {
                return { width: '350px' };
            }
        }, {
            dataField: 'count',
            text: 'Teenused',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell kov-sp-th',
            formatter: renderButtons,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            },
            headerStyle: (column, colIndex) => {
                return { width: '120px' };
            }
        }, {
            dataField: 'pennant',
            text: 'Soovita',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            classes: 'clickable d-none d-md-table-cell text-center',
            headerClasses: 'd-none d-md-table-cell kov-sp-th',
            formatter: renderRecommend,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.handleRecommendClicked(row).then(() => {
                        let newData = [...this.state.data];
                        newData = newData.map((d) => {
                            if (d.created_by === row.created_by) {
                                return {
                                    ...d,
                                    pennant: { is_user_set: !d.pennant.is_user_set }
                                };
                            }
                            return d;
                        });
                        this.setState(state => ({ ...state, data: newData }));
                    });
                }
            },
            headerStyle: (column, colIndex) => {
                return { width: '120px' };
            }
        }, {
            dataField: 'problem_notification',
            text: 'Hoiata',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            classes: 'd-none d-md-table-cell text-center',
            headerClasses: 'd-none d-md-table-cell kov-sp-th',
            formatter: renderWarn,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.handleWarnClicked(row).then(() => {
                        let newData = [...this.state.data];
                        newData = newData.map((d) => {
                            if (d.created_by === row.created_by && !d.problem_notification) {
                                return {
                                    ...d,
                                    problem_notification: !d.problem_notification
                                };
                            }
                            return d;
                        });
                        this.setState(state => ({ ...state, data: newData }));
                    });
                }
            },
            headerStyle: (column, colIndex) => {
                return { width: '120px' };
            }
        }];

        const NoDataIndication = () =>
            <div className="spinner pt-4 pb-4">
                <span className="hopsti-pink">Sinu piirkonnas pole teenusepakkujaid!</span>
            </div>;

        return (
            <>
                {this.state.selectedServiceProvider === null ?
                    <Row className="justify-content-center pt-2 pb-2">
                        <Col className="col-12">
                            <BootstrapTable
                                striped
                                hover
                                bordered={false}
                                wrapperClasses="table-responsive"
                                keyField='created_by'
                                data={this.state.data}
                                columns={columns}
                                filter={filterFactory()}
                                pagination={paginationFactory({ hideSizePerPage: true })}
                                noDataIndication={() => <NoDataIndication />}
                            />
                        </Col>
                    </Row>
                    :
                    <ServicesTable serviceProvider={this.state.selectedServiceProvider} backHandler={() => this.setState({ selectedServiceProvider: null })} />

                }
            </>
        );
    }
}

export default OurServiceProvidersTable;