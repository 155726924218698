import {Badge, Col, Row, UncontrolledTooltip} from "reactstrap";
import {Link} from "react-router-dom";
import React from "react";
import {withTranslation} from 'react-i18next';


class CategorySelect extends React.Component {
    render() {

        return (
            <div className="container pb-4">
                <Row className="justify-content-center">
                    <Col className="col-12 d-flex justify-content-center">
                        {this.props.role === "TEENUSEPAKKUJA" ?
                            <>
                                <Link to={`/töölaud/minu-teenused/`} id="tooltip-my-services"
                                      onClick={e => this.props.toggleNavs(e, "tabs", 1)}
                                      className={this.props.tabs === 1 ? "btn btn-hopsti-green-outline btn-lg btn-hopsti-nav active" : "btn btn-hopsti-green-outline btn-lg btn-hopsti-nav"}>
                                    {this.props.t('category.my-services')}
                                </Link>
                                <UncontrolledTooltip placement="bottom" delay={0} target="tooltip-my-services">
                                    Siin saad sisestada oma teenuseid ja neid muuta.
                                </UncontrolledTooltip>
                                <Link to={`/töölaud/abihõiked/`} id="tooltip-services"
                                      onClick={e => this.props.toggleNavs(e, "tabs", 4)}
                                      className={this.props.tabs === 4 ? "btn btn-hopsti-green-outline btn-lg btn-hopsti-nav active" : "btn btn-hopsti-green-outline btn-lg btn-hopsti-nav"}>
                                    {this.props.t('category.help-requests')}
                                </Link>
                                <UncontrolledTooltip placement="bottom" delay={0} target="tooltip-services">
                                   Siin näed kõiki abihõikeid.
                                </UncontrolledTooltip>
                                <Link to={`/töölaud/minu-tööd/`} id="tooltip-my-jobs" onClick={e => this.props.toggleNavs(e, "tabs", 7)}
                                      className={this.props.tabs === 7 ? "btn btn-hopsti-green-outline btn-lg btn-hopsti-nav active" : "btn btn-hopsti-green-outline btn-lg btn-hopsti-nav"}>
                                    <span className="">{this.props.t('category.my-jobs')}</span>
                                    {this.props.offers ?
                                        <Badge
                                            className="badge-md badge-circle bg-hopsti-pink text-white">{this.props.offers.length}</Badge> : null}
                                </Link>
                                <UncontrolledTooltip placement="bottom" delay={0} target="tooltip-my-jobs">
                                    Siin näed oma pakkumisi ja kokkulepitud tööotsi.
                                </UncontrolledTooltip>
                            </>
                            : this.props.role === "ABIVAJAJA" ?
                                <>
                                    <Link to={`/töölaud/minu-abihõiked/`} id="tooltip-my-help-requests"
                                          onClick={e => this.props.toggleNavs(e, "tabs", 2)}
                                          className={this.props.tabs === 2 ? "btn btn-category-hopsti-pink btn-lg btn-hopsti-nav active" : "btn btn-category-hopsti-pink btn-lg btn-hopsti-nav"}>
                                        {this.props.t('category.my-help-requests')}
                                    </Link>
                                    <UncontrolledTooltip placement="bottom" delay={0} target="tooltip-my-help-requests">
                                        Siin saad sisestada oma abihõikeid ja neid muuta.
                                    </UncontrolledTooltip>
                                    <Link to={`/töölaud/teenused/`} id="tooltip-help-requests" onClick={e => this.props.toggleNavs(e, "tabs", 3)}
                                          className={this.props.tabs === 3 ? "btn btn-category-hopsti-pink btn-lg btn-hopsti-nav active" : "btn btn-category-hopsti-pink btn-lg btn-hopsti-nav"}>
                                        {this.props.t('category.services')}
                                    </Link>
                                    <UncontrolledTooltip placement="bottom" delay={0} target="tooltip-help-requests">
                                        Siin saad otsida endale sobivaid teenuseid.
                                    </UncontrolledTooltip>
                                    <Link to={`/töölaud/minu-tööd/`} id="tooltip-my-offers"
                                          onClick={e => this.props.toggleNavs(e, "tabs", 6)}
                                          className={this.props.tabs === 6 ? "btn btn-category-hopsti-pink btn-lg btn-hopsti-nav active" : "btn btn-category-hopsti-pink btn-lg btn-hopsti-nav"}>
                                        <span className="mr-1">{this.props.t('category.my-offers')}</span>
                                        {this.props.offers ?
                                            <Badge
                                                className="badge-md badge-circle bg-hopsti-green text-white">{this.props.offers.length}</Badge> : null}
                                    </Link>
                                    <UncontrolledTooltip placement="bottom" delay={0} target="tooltip-my-offers">
                                        Siin näed kõiki Sulle tehtud pakkumisi ja kokkulepitud töid.
                                    </UncontrolledTooltip>
                                </>
                                : this.props.role === "KOV" ?
                                    <>
                                        <Link to={`/töölaud/meie-abivajajad/`}
                                              onClick={e => this.props.toggleNavs(e, "tabs", 8)}
                                              className={this.props.tabs === 8 ? "btn btn-hopsti-green-outline btn-lg btn-hopsti-nav active" : "btn btn-hopsti-green-outline btn-lg btn-hopsti-nav"}>
                                            Meie abivajajad
                                        </Link>
                                        <Link to={`/töölaud/meie-teenusepakkujad/`}
                                              onClick={e => this.props.toggleNavs(e, "tabs", 9)}
                                              className={this.props.tabs === 9 ? "btn btn-hopsti-green-outline btn-lg btn-hopsti-nav active" : "btn btn-hopsti-green-outline btn-lg btn-hopsti-nav"}>
                                            Meie teenusepakkujad
                                        </Link>
                                        <Link to={`/töölaud/statistika/`}
                                              onClick={e => this.props.toggleNavs(e, "tabs", 10)}
                                              className={this.props.tabs === 10 ? "btn btn-hopsti-green-outline btn-lg btn-hopsti-nav active" : "btn btn-hopsti-green-outline btn-lg btn-hopsti-nav"}>
                                            Statistika
                                        </Link>
                                    </> : null
                        }
                    </Col>
                </Row>
            </div>)
    }
}

export default withTranslation('common')(CategorySelect);