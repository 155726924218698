import React from "react";
import {
    Button, Card,
    Col, Container,
    FormGroup, Input,
    InputGroup, Modal, Progress,
    Row,
} from "reactstrap";
import Rating from "react-rating";
import classnames from "classnames";
import {withRouter} from "react-router";
import {withTranslation} from 'react-i18next';

import axios from "../../axios";
import ImageGallery from "react-image-gallery";

class OfferDetailView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayMatches: false,
            matchedHelpRequests: [],
            emailModal: false,
            phoneModal: false,
            serviceModal: false,
            quantity: this.props.service ? this.props.service.quantity : 0,
            commentModal: false,
            customStyles: {
                commentState: null,
            },
            comment: "",
            price: "",
            myRating: null,
            offerLines: this.props.service ? this.props.service.offer_lines : null,
            offerSum: 0,
            service_info: this.props.service ? this.props.service.service_info : null
        };
    }

    componentDidMount() {
        this.getMyRating();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props !== nextProps || this.state !== nextState;
    }

    handleChange = async event => {
        const {target} = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const {name} = target;
        await this.setState({
            [name]: value
        }, () => {
            this.validate()
        });
    };

    validate = () => {
        let newState = {...this.state.customStyles};
        if (this.state.comment.length < 50) {
            newState.commentState = "invalid";
        } else {
            newState.commentState = "valid";
        }
        this.setState({customStyles: newState})
    };

    getMyRating = () => {
        axios
            .get("ratings/my/" + this.props.service.service_info.created_by + "/")
            .then(response => {
                if (response.data) {
                    if (response.data.score) {
                        this.setState({
                            myRating: response.data.score
                        })
                    }
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    addComment = (selectedRating) => {
        this.setState({commentModal: true, myTempRating: selectedRating})
    };

    addRating = () => {
        if (this.state.customStyles.commentState === "valid") {
            if (this.state.myRating) {
                axios
                    .put("rating/" + this.props.service.offer_id + "/", {
                        "score": this.state.myTempRating,
                        "comment": this.state.comment,
                        "price": this.state.price
                    })
                    .then(response => {
                        this.setState({
                            commentModal: false,
                            price: "",
                            comment: "",
                            myRating: this.state.myTempRating
                        }, () => this.props.closeModalHandler(4))
                    })
                    .catch(err => {
                        console.log(err);
                    })
            } else {
                axios
                    .post("rating/" + this.props.service.offer_id + "/", {
                        "score": this.state.myTempRating,
                        "comment": this.state.comment,
                        "price": this.state.price
                    })
                    .then(response => {
                        this.setState({
                            commentModal: false,
                            price: "",
                            comment: "",
                            myRating: this.state.myTempRating
                        }, () => this.props.closeModalHandler(4))
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }
    };

    decreaseOfferLineQuantity = (offerLineId) => {
        this.setState({
            offerLines: this.state.offerLines.map(ol => (ol.id === offerLineId && ol.quantity > 0 ? {
                ...ol,
                quantity: ol.quantity -= 1
            } : ol))
        });
    };

    incrementOfferLineQuantity = (offerLineId) => {
        this.setState({
            offerLines: this.state.offerLines.map(ol => (ol.id === offerLineId ? {
                ...ol,
                quantity: ol.quantity += 1
            } : ol))
        });
    };

    sendOffer = () => {
        axios
            .put('/offers/update/' + this.props.service.offer_id + "/" + this.props.userId + "/", {
                "status": {"id": 1},
                "is_help_request_agreement": false,
                "is_service_agreement": false,
            })
            .then(response => {
                this.props.closeModalHandler(1)
            })
            .catch(err => {
                console.log(err);
            })
    };

    submitOffer = () => {

        // update offer lines (quantity of additional service)
        this.state.offerLines.map(ol => {
            return axios
                .put('/offer-lines/offer/' + ol.id + "/", {
                    service_id: this.props.service.service_info.id,
                    extra_id: ol.id,
                    quantity: ol.quantity
                })
                .catch(err => {
                    console.log(err);
                })
        });

        axios
            .put('/offers/update/' + this.props.service.offer_id + "/" + this.props.userId + "/", {
                "status": {"id": 2},
                "is_help_request_agreement": true,
                "is_service_agreement": false,
                "quantity": this.state.quantity
            })
            .then(response => {
                this.props.closeModalHandler(2)
            })
            .catch(err => {
                console.log(err);
            })
    };

    acceptOffer = () => {
        axios
            .put('/offers/update/' + this.props.service.offer_id + "/" + this.props.userId + "/", {
                "status": {"id": 3},
                "is_help_request_agreement": true,
                "is_service_agreement": true,
            })
            .then(response => {
                this.props.closeModalHandler(3)
            })
            .catch(err => {
                console.log(err);
            })
    };

    offerFinished = () => {
        axios
            .put('/offers/update/' + this.props.service.offer_id + "/" + this.props.userId + "/", {
                "status": {"id": 4},
                "is_help_request_agreement": true,
                "is_service_agreement": true,
            })
            .then(response => {
                if (this.props.isServiceProvider) {
                    this.props.closeModalHandler(4)
                } else {
                    this.setState({commentModal: true})
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    offerArchived = () => {
        axios
            .put('/offers/update/' + this.props.service.offer_id + "/" + this.props.userId + "/", {
                "status": {"id": 6},
                "is_help_request_agreement": true,
                "is_service_agreement": true,
            })
            .then(response => {
                this.props.closeModalHandler(6)
            })
            .catch(err => {
                console.log(err);
            })
    };

    cancelOffer = () => {
        const data = {
            "status": {"id": 5},
            "is_help_request_agreement": false,
            "is_service_agreement": false,
        };

        axios
            .put('/offers/update/' + this.props.service.offer_id + "/" + this.props.userId + "/", data)
            .then(response => {
                this.props.closeModalHandler(5)
            })
            .catch(err => {
                console.log(err);
            })
    };

    render() {

        // if (this.props.service.offer_lines.length > 0) {
        //     console.log(this.state.offerLines.map(ol => (ol.quantity > 0 ? ol.quantity * ol.cost : 0))
        //     this.setState({offerSum: this.state.quantity * this.props.service.service_info.cost + this.state.offerLines.map(ol => (ol.quantity > 0 ? ol.quantity * ol.cost : 0))});
        // }

        let additionalServicesSum = 0;
        let offerSum = 0;

        if (this.state.offerLines) {
            this.state.offerLines.map(ol => (ol.quantity > 0 ? additionalServicesSum += ol.quantity * ol.cost : additionalServicesSum += 0));
            offerSum = this.state.quantity * this.props.service.service_info.cost + additionalServicesSum;
        }

        if (this.props.service && this.props.service.service_info) {
            return (
                <Container>
                    <Row className="ml-0 mr-0">
                        <Col className="col-12">
                            <div className="progress-wrapper">
                                <div className="progress-info">
                                    <div className="progress-label">
                                        <span className="hopsti-pink">{this.props.service.status.value}</span>
                                    </div>
                                    <div className="progress-percentage">
                                        <span>{this.props.service.status.id === 1 ? "25" : this.props.service.status.id === 2 ? "50" : this.props.service.status.id === 3 ? "75" : this.props.service.status.id === 4 ? "100" : null}%</span>
                                    </div>
                                </div>
                                <Progress max="100"
                                          value={this.props.service.status.id === 1 ? "25" : this.props.service.status.id === 2 ? "50" : this.props.service.status.id === 3 ? "75" : this.props.service.status.id === 4 ? "100" : null}
                                          color="danger"/>
                            </div>
                        </Col>
                        <Col className="col-12 col-md-auto text-center mt-2 mb-md-5">
                            {/*eslint-disable-next-line */}
                            <a className="avatar rounded-circle service-detail-img">
                                <img className="service-detail-img" alt="..."
                                     src={this.props.isServiceProvider ? this.props.service.help_info.avatar : this.props.service.service_info.avatar}/>
                            </a>
                            <h4 className="mb-0 hopsti-pink">
                                {this.props.service.user ? this.props.service.user.first_name : null}
                            </h4>
                            <small
                                className="hopsti-green">{this.props.service ? this.props.service.created_at : null}</small>
                            {!this.props.isServiceProvider ?
                                <>
                                    <div>
                                        <Rating
                                            initialRating={this.state.myRating ? this.state.myRating : null}
                                            onClick={this.addComment}
                                            emptySymbol="fa fa-star hopsti-star-outline" // btn-hopsti-pink-outline
                                            fullSymbol="fa fa-star hopsti-star"/>
                                    </div>
                                    <div>{this.state.myRating ?
                                        <small>{this.props.t('offer-detail.your-rating')}: {this.state.myRating}</small> : null}</div>
                                    <div className="pb-4">
                                        <small>{this.props.service.rating ? <strong
                                            className="hopsti-pink">{this.props.service.rating.average_rating}</strong> : null}/5
                                            ({this.props.service.rating ? this.props.service.rating.rating_count : null} {this.props.service.rating && this.props.service.rating.rating_count === 1 ? "hinnang" : "hinnangut"})</small>
                                    </div>
                                </> : null}
                        </Col>
                        <Col className="col-12 col-md mt-md-2 mb-md-5">
                            {this.props.isServiceProvider ?
                                <>
                                    <h4 className="mb-0 hopsti-pink">
                                        {this.props.service.help_info.name}
                                    </h4>
                                    <br/>
                                    <div>
                                        <small className="">
                                            {this.props.t('detail-view.category')}: <strong>{this.props.service.help_info.top_level_name}</strong>
                                        </small>
                                    </div>
                                    <div>
                                        <small className="">
                                            {this.props.t('detail-view.sub-category')}: <strong>{this.props.service.help_info.sub_level_name}</strong>
                                        </small>
                                    </div>
                                    <div>
                                        <small className="">
                                            {this.props.t('detail-view.distance-from-you')}: <strong>{this.props.service.google_response ? this.props.service.google_response.distance.distance.text : null}</strong>
                                        </small>
                                    </div>
                                    <br/>
                                    <div>
                                        <small>{this.props.t('detail-view.description')}: </small>
                                        <small className="">
                                            {this.props.service.help_info.info}
                                        </small>
                                    </div>
                                    <hr className="mt-2 mb-2"/>
                                </> :
                                <>
                                    <h4 className="mb-0 hopsti-pink">
                                        {this.props.service.service_info.name}
                                    </h4>
                                    <br/>
                                    <div>
                                        <small className="">
                                            {this.props.t('detail-view.category')}: <strong>{this.props.service.service_info.top_level_name}</strong>
                                        </small>
                                    </div>
                                    <div>
                                        <small className="">
                                            {this.props.t('detail-view.sub-category')}: <strong>{this.props.service.service_info.sub_level_name}</strong>
                                        </small>
                                    </div>
                                    <div>
                                        <small className="">
                                            {this.props.t('detail-view.distance-from-you')}: <strong>{this.props.service.google_response ? this.props.service.google_response.distance.distance.text : null}</strong>
                                        </small>
                                    </div>
                                    <div className="">
                                        <small className="">
                                            {this.props.t('detail-view.price')}: <strong>{this.props.service && this.props.service.service_info.cost && this.props.service.service_info.unit ? this.props.service.service_info.cost + "€/" + this.props.service.service_info.unit : null}</strong>
                                        </small>
                                    </div>
                                    <br/>
                                    <div>
                                        <small>{this.props.t('detail-view.description')}: </small>
                                        <small className="">
                                            {this.props.service.service_info.service_desc_ee}
                                        </small>
                                    </div>
                                    <hr className="mt-2 mb-2"/>
                                </>}
                            <div>
                                <Row>
                                    <Col className="col-6">
                                        <label className="form-control-label mb-0">{this.props.t('detail-view.service')}</label>
                                    </Col>
                                    <Col className="col-3">
                                        <label className="form-control-label mb-0">{this.props.t('detail-view.amount')}</label>
                                    </Col>
                                    <Col className="col-3">
                                        <label className="form-control-label mb-0">{this.props.t('detail-view.price')}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-6">
                                        <p><small>{this.props.service.help_info.name}</small></p>
                                    </Col>
                                    <Col className="col-3">
                                        <div className="quantity-container">
                                            {this.props.service.status.id === 1 && !this.props.isServiceProvider ?
                                                <i className="fas fa-minus hopsti-pink clickable"
                                                   onClick={() => {
                                                       this.state.quantity ? this.setState({quantity: this.state.quantity - 1}) : this.setState({quantity: this.state.quantity})
                                                   }
                                                   }/> : null}
                                            <small className="pl-2 pr-2"><strong>{this.state.quantity}</strong></small>
                                            {this.props.service.status.id === 1 && !this.props.isServiceProvider ?
                                                <i className="fas fa-plus hopsti-green clickable"
                                                   onClick={() => this.setState({quantity: this.state.quantity + 1})}/> : null
                                            }
                                        </div>
                                    </Col>
                                    <Col className="col-3">
                                        <small className="">
                                            <strong>{this.props.service && this.props.service.service_info.cost && this.props.service.service_info.unit ? this.props.service.service_info.cost + "€/" + this.props.service.service_info.unit : null}</strong>
                                        </small>
                                    </Col>
                                </Row>
                                {this.props.service.offer_lines.length > 0 ?
                                    <>
                                        <Row>
                                            <Col className="col-6">
                                                <label className="form-control-label mb-0">{this.props.t('detail-view.additional-services')}</label>
                                            </Col>
                                        </Row>
                                        {
                                            this.props.service.offer_lines.map((offerLine, index) => {
                                                return (
                                                    <Row key={offerLine.id}>
                                                        <Col className="col-6">
                                                            <small>{offerLine.description}</small>
                                                        </Col>
                                                        <Col className="col-3">
                                                            <div>
                                                                {this.props.service.status.id === 1 && !this.props.isServiceProvider ?
                                                                    <i className="fas fa-minus hopsti-pink clickable"
                                                                       onClick={() => this.decreaseOfferLineQuantity(offerLine.id)}/> : null}
                                                                <small
                                                                    className="pl-2 pr-2"><strong>{this.state.offerLines.map(ol => (ol.id === offerLine.id ? ol.quantity : null))}</strong></small>
                                                                {this.props.service.status.id === 1 && !this.props.isServiceProvider ?
                                                                    <i className="fas fa-plus hopsti-green clickable"
                                                                       onClick={() => this.incrementOfferLineQuantity(offerLine.id)}/> : null}
                                                            </div>
                                                        </Col>
                                                        <Col className="col-3">
                                                            <small className="">
                                                                <strong>{offerLine.cost}€/{offerLine.unit}</strong>
                                                            </small>
                                                        </Col>
                                                    </Row>)
                                            })}
                                    </>
                                    : null}
                                <hr className="mt-2 mb-2"/>
                                <Row>
                                    <Col className="col-9">
                                        <label className="form-control-label mb-0">{this.props.t('detail-view.total')}</label>
                                    </Col>
                                    <Col className="col-3">
                                        <small><strong>{offerSum}€</strong></small>
                                    </Col>
                                </Row>
                            </div>
                            <Row className="pt-4 justify-content-center justify-content-md-start">
                                {this.props.service.status.id === 8 && this.props.isServiceProvider ?
                                    <Col className="col-auto mb-2 mb-md-0">
                                        <Button className="btn-hopsti-green" onClick={() => this.sendOffer()}>{this.props.t('buttons.make-offer')}</Button></Col> : null}
                                {this.props.service.status.id === 1 && !this.props.isServiceProvider ?
                                    <Col className="col-auto mb-2 mb-md-0">
                                        <Button className="btn-hopsti-green" onClick={() => this.submitOffer()}>{this.props.t('buttons.accept-offer')}</Button></Col> : null}
                                {this.props.service.status.id === 2 && this.props.isServiceProvider ?
                                    <Col className="col-auto mb-2 mb-md-0">
                                        <Button className="btn-hopsti-green" onClick={() => this.acceptOffer()}>{this.props.t('buttons.accept-offer')}</Button></Col> : null}
                                {this.props.service.status.id === 3 ?
                                    <Col className="col-auto mb-2 mb-md-0">
                                        <Button className="btn-hopsti-green" onClick={() => this.offerFinished()}>{this.props.t('buttons.service-done')}</Button></Col> : null}
                                {this.props.service.status.id === 4 ?
                                    <Col className="col-auto mb-2 mb-md-0">
                                        <Button className="btn-hopsti-green"
                                                onClick={() => this.offerArchived()}>{this.props.t('buttons.delete')}</Button></Col> : null}
                                {this.props.service.status.id === 1 || this.props.service.status.id === 2 || this.props.service.status.id === 3 ?
                                    <Col className="col-auto mb-2 mb-md-0">
                                        <Button className="btn-hopsti-pink"
                                                onClick={() => this.cancelOffer()}>{this.props.t('buttons.cancel-offer')}</Button></Col> : null}
                            </Row>
                        </Col>
                        {(this.props.isServiceProvider && this.props.service.help_info.files.length > 0) || (!this.props.isServiceProvider && this.props.service.service_info.files.length > 0) ?
                            <Col className="col-sm-12 col-lg-5 ml-auto mt-2 mb-5">
                                <div className="galleryFix">
                                    <ImageGallery showPlayButton={false} showThumbnails={true}
                                                  items={this.props.isServiceProvider ? this.props.service.help_info.files : this.props.service.service_info.files}/>
                                </div>
                                <div className="d-flex align-items-center pt-2">
                                    <i className="fas fa-2x fa-map-marker-alt hopsti-green"/>
                                    <small className="pl-2">
                                        <strong>{[3, 4].includes(this.props.service.status.id) ? this.props.service.google_response.destination_addresses : this.props.t('offer-detail.accept-to-see-contact-info')}</strong>
                                    </small>
                                </div>
                                <div className="d-flex align-items-center pt-2">
                                    <i className="fa fa-2x fa-phone-square hopsti-green" aria-hidden="true"/>
                                    <small className="pl-2">
                                        <strong>{this.props.service.contacts.length > 0 ? this.props.service.contacts[0].phones[0] : this.props.t('offer-detail.accept-to-see-contact-info')}</strong>
                                    </small>
                                </div>
                                <div className="d-flex align-items-center pt-2">
                                    <i className="fas fa-2x fa-envelope hopsti-green"/>
                                    <small className="pl-2">
                                        <strong>{this.props.service.contacts.length > 0 ? this.props.service.contacts[0].emails[0] : this.props.t('offer-detail.accept-to-see-contact-info')}</strong>
                                    </small>
                                </div>
                            </Col> : <Col className="col-sm-12 col-lg-5 ml-auto mt-2 mb-5">
                                <div className="d-flex align-items-center pt-2">
                                    <i className="fas fa-2x fa-map-marker-alt hopsti-green"/>
                                    <small className="pl-2">
                                        <strong>{[3, 4].includes(this.props.service.status.id) ? this.props.service.google_response.destination_addresses : this.props.t('offer-detail.accept-to-see-contact-info')}</strong>
                                    </small>
                                </div>
                                <div className="d-flex align-items-center pt-2">
                                    <i className="fa fa-2x fa-phone-square hopsti-green" aria-hidden="true"/>
                                    <small className="pl-2">
                                        <strong>{this.props.service.contacts.length > 0 ? this.props.service.contacts[0].phones[0] : this.props.t('offer-detail.accept-to-see-contact-info')}</strong>
                                    </small>
                                </div>
                                <div className="d-flex align-items-center pt-2">
                                    <i className="fas fa-2x fa-envelope hopsti-green"/>
                                    <small className="pl-2">
                                        <strong>{this.props.service.contacts.length > 0 ? this.props.service.contacts[0].emails[0] : this.props.t('offer-detail.accept-to-see-contact-info')}</strong>
                                    </small>
                                </div>
                            </Col>}
                    </Row>
                    <Modal
                        size="md"
                        className="modal-dialog-centered"
                        isOpen={this.state.commentModal}
                        toggle={() => this.setState({commentModal: !this.state.commentModal})}
                    >
                        <Card className="mb-0 p-4">
                            <Row className="justify-content-center">
                                <Col className="col-12 text-center justify-content-center">
                                    <div className="pb-2">
                                        <div>
                                            <label className="form-control-label small hopsti-green mb-0">
                                                {this.props.t('offer-detail.your-rating')}
                                            </label>
                                        </div>
                                        <div>
                                            <Rating
                                                onClick={(selectedRating) => this.setState({myTempRating: selectedRating})}
                                                initialRating={this.state.myTempRating ? this.state.myTempRating : null}
                                                emptySymbol="fa fa-star hopsti-star-outline" // btn-hopsti-pink-outline
                                                fullSymbol="fa fa-star hopsti-star"/>
                                        </div>
                                    </div>
                                    <div className="">
                                        <label className="form-control-label small hopsti-green">
                                            {this.props.t('offer-detail.what-was-price')}
                                        </label>
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.focusedName
                                            })}
                                        >
                                            <InputGroup className="mb-3">
                                                <Input
                                                    placeholder={this.props.t('offer-detail.price')}
                                                    type="text"
                                                    name="price"
                                                    value={this.state.price}
                                                    onChange={e => {
                                                        this.handleChange(e);
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <label className="form-control-label small hopsti-green">
                                            {this.props.t('offer-detail.comment-at-least-50')}
                                        </label>
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.focusedComment
                                            })}
                                        >
                                            <InputGroup className="mb-3">
                                                <Input
                                                    placeholder={this.props.t('offer-detail.comment')}
                                                    type="textarea"
                                                    name="comment"
                                                    value={this.state.comment}
                                                    onChange={e => {
                                                        this.handleChange(e);
                                                    }}
                                                    valid={
                                                        this.state.customStyles.commentState === "valid"
                                                    }
                                                    invalid={
                                                        this.state.customStyles.commentState ===
                                                        "invalid"
                                                    }
                                                />
                                                <div className="invalid-feedback">
                                                    {this.props.t('offer-detail.comment-at-least-50-error')}
                                                </div>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>
                                    <div className="ml-auto mr-auto">
                                        <Button onClick={this.addRating} className="btn-hopsti-green">{this.props.t('offer-detail.send-feedback')}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Modal>
                </Container>
            );
        } else return (<></>)
    }
}

export default withRouter((withTranslation('common')(OfferDetailView)));