import React from "react";
import TableContainer from "../../Table/TableContainer/TableContainer";
import MyServicesTable from "../../Table/TableContainer/MyServicesTable";


class YourServices extends React.Component {
    render() {
        return (
            <TableContainer
                addServiceHandler={this.props.addServiceHandler}
            >
                <MyServicesTable
                    items={this.props.yourServices}
                    removeHandler={this.props.removeServiceHandler}
                    editServiceHandler={this.props.editServiceHandler}
                    handleMatchClick={this.props.handleMatchClick}/>
            </TableContainer>
        );
    }
}

export default YourServices;