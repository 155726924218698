import React from "react";
import {
    Badge,
    Col,
    Modal,
    Row,
    Container,
    Button
} from "reactstrap";
import DetailView from "../../DetailView/DetailView";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { sortCaret, renderAvatar } from "../../Table/TableContainer/Helpers/TableUtil"

import {withTranslation} from 'react-i18next';

// import overlayFactory from "react-bootstrap-table2-overlay";
import AssessPinHelpRequest from "../Views/PersoninNeedDashboard/AssessPinHelpRequest";


class HelpRequestsTable extends React.Component {
    state = {
        serviceModal: false,
        isLoading: true,
        data: [],
        assessModal: false
    };

    componentDidMount() {
        this.setState({
            data: this.props.items ? this.props.items : []
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.items !== this.props.items) {
            this.setState({
                isLoading: this.props.items === undefined,
                data: this.props.items ? this.props.items : []
            });
        }
    }

    toggleModal = (state, service) => {
        this.setState({
            [state]: !this.state[state],
            selectedService: service
        });
    };

    render() {
        const renderButtons = (cell, row) => {
            return (
                <div className="d-flex align-items-center">
                    <i className="fa fa-2x fa-search hopsti-pink pl-2 clickable"
                        onClick={() => this.toggleModal("serviceModal", row)}
                        title="Vaata detailvaadet"
                        aria-hidden="true" />
                    <i onClick={() => this.props.editHelpRequestHandler(row.id)}
                        className="fa fa-2x fa-edit hopsti-green pl-2 clickable" id="tooltip1"
                        title="Muuda"
                        aria-hidden="true" />
                    <i onClick={() => this.props.removeHelpRequestHandler(row.id)}
                        className="fa fa-2x fa-trash-alt hopsti-pink pl-2 clickable"
                        id="tooltip2"
                        title="Kustuta"
                        aria-hidden="true" />
                    <Badge onClick={() => this.props.handleMatchClick(row.id)}
                        className="badge badge-md pill btn-hopsti-pink clickable ml-2">{row.matches_count === 1 ? <>{row.matches_count} vaste</> : row.matches_count > 0 ? <>{row.matches_count} vastet</> : null}</Badge>
                </div>
            );
        };

        const renderNeed = (cell, row) => {
            return (
                row.quantity + " " + row.unit.unit
            );
        };

        const renderAssess = (cell, row) => {
            return (
                row.kov_status_id === null ? <span className="assess">H</span> : <span className="assess active">H</span>
            );
        };

        const columns = [{
            dataField: 'kov_status_id',
            text: 'Hinda',
            formatter: renderAssess,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    if (row.kov_status_id === null || row.kov_status_id === "") {
                        this.toggleModal("assessModal", row)
                    }
                }
            },
            classes: 'clickable d-none d-md-table-cell text-center',
            headerClasses: 'd-none d-md-table-cell',
            headerStyle: (column, colIndex) => {
                return { width: '65px', textAlign: 'center' };
            }
        }, {
            dataField: 'avatar',
            text: '',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            },
            formatter: renderAvatar,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            headerStyle: (column, colIndex) => {
                return { width: '65px', textAlign: 'center' };
            }
        }, {
            dataField: 'created_at',
            text: 'Lisatud',
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            headerStyle: (column, colIndex) => {
                return { width: '100px' };
            },
            sort: true,
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'title',
            text: 'Abihõige',
            sort: true,
            classes: 'clickable',
            sortCaret: (order, column) => sortCaret(order, column),
            headerStyle: (column, colIndex) => {
                return { width: '200px' };
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'info',
            text: 'Kirjeldus',
            sort: true,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            },
        }, {
            dataField: 'unit.id',
            text: 'Vajadus',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            headerStyle: (column, colIndex) => {
                return { width: '90px' };
            },
            formatter: renderNeed,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: '',
            text: 'Tegevused',
            formatter: renderButtons,
            headerStyle: (column, colIndex) => {
                return { width: '200px' };
            },
        }];

        const NoDataIndication = () =>
            <div className="spinner pt-4 pb-4">
                {this.state.isLoading ?
                    <></>
                    :
                    <span className="hopsti-pink">Sa pole ühtegi abihõiget lisanud</span>
                }
            </div>;

        return (
            <>
                <Container fluid className="pl-0 pr-0">
                    <Row className="justify-content-center">
                        <Col className="col-12 col-sm-10">
                            <div className="header-body text-center pt-2 mb-4">
                                <Row className="justify-content-center">
                                    <Col className="px-5" lg="6" md="8" xl="5">
                                        <Button
                                            to="/add-helprequest"
                                            className="btn btn-hopsti-pink"
                                            onClick={this.props.addHelpRequestHandler}
                                        >
                                            {this.props.t('buttons.add-new-help-request')}
                                    </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center pt-2 pb-2">
                        <Col className="col-12">
                            <BootstrapTable
                                striped
                                hover
                                bordered={false}
                                keyField='id'
                                wrapperClasses="table-responsive"
                                data={this.state.data}
                                columns={columns}
                                pagination={paginationFactory({ hideSizePerPage: true })}
                                noDataIndication={() => <NoDataIndication />}
                                loading={this.state.isLoading}
                                // overlay={overlayFactory({ spinner: true })}
                            />
                        </Col>
                    </Row>
                    <Modal
                        size="lg"
                        className={this.state.selectedService && this.state.selectedService.files && this.state.selectedService.files.length > 0 ? "modal-dialog-centered matches-modal" : "modal-dialog-centered matches-modal-sm"}
                        isOpen={!!(this.state.serviceModal && this.state.selectedService)}
                        toggle={() => this.toggleModal("serviceModal")}
                    >
                        <DetailView isService={false} service={this.state.selectedService} />
                    </Modal>
                    <Modal
                        size="md"
                        className={"modal-dialog-centered"}
                        isOpen={this.state.assessModal}
                        toggle={() => this.toggleModal("assessModal")}
                    >
                        <AssessPinHelpRequest
                            closeHandler={(hrId, statusId, status) => {
                                this.toggleModal("assessModal");
                                let newData = [...this.state.data];
                                newData = newData.map((d) => {
                                    if (d.id === hrId) {
                                        return {
                                            ...d,
                                            kov_status_id: statusId,
                                            kov_status_value: status
                                        };
                                    }
                                    return d;
                                });
                                this.setState(state => ({ ...state, data: newData }));

                            }}
                            helprequestId={this.state.selectedService ? this.state.selectedService.id : ""} />
                    </Modal>
                </Container>
            </>
        );
    }
}

export default withTranslation('common')(HelpRequestsTable);