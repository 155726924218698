import React from "react";
import ServiceItem from "../ServiceItem/ServiceItem";
import { connect } from "react-redux";

class EditService extends React.Component {
    render() {
        return (<>
            <ServiceItem  {...this.props} userId={this.props.user.pk} />
        </>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(EditService);