let axios = require('axios');

let axiosInstance = axios.create({
    baseURL: 'https://hopsti.ee/api/v1/',
});

axiosInstance.interceptors.response.use((response) => {
    return response
}, function (error) {

    if (error.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
        window.location.href = '/';
        return Promise.reject(error);
    }

    return Promise.reject(error);
});

module.exports = axiosInstance;