import React from "react";
import {Row, Col, Modal, Table} from "reactstrap";
import {connect} from "react-redux";
import ReactGA from 'react-ga';
import ServiceGroupsFilter from "../Filters/ServiceGroupsFilter";
import HopstiLogin from "components/Login/HopstiLogin";
import AuthFooter from "../Footers/AuthFooter";
import IndexNavbar from "../Navbars/IndexNavbar";
import {Typeahead} from "react-bootstrap-typeahead";
import {createServicesFilterUrl, getAllPublicServices, getSuggestionsForSubGroupFilter} from "../../utils/services";
import TableRow from "../Table/TableRow/TableRow";

class HopstiPublicDasboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            services: [],
            options: [],
            topGroupFilter: this.props.location.state ? [this.props.location.state.selectedTopServiceCategory] : [],
            subGroupFilter: this.props.location.state ? [this.props.location.state.selectedSubServiceId] : [],
            topGroupFilterString: this.topGroupFilter ? this.topGroupFilter.join() : "",
            selectedServiceId: null,
        };
    }

    componentDidMount() {
        this.getAllPublicServices();
        ReactGA.initialize('UA-150997639-1');
        ReactGA.pageview('/public-dashboard');
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // If subgroup filter has changed, top group filter has changed or services have changed
        return (this.state.subGroupFilter !== nextState.subGroupFilter ||
            this.state.topGroupFilter !== nextState.topGroupFilter ||
            this.state.services !== nextState.services)
    }

    toggleModal = state => {
        this.setState({[state]: !this.state[state]});
    };

    // Get all services according to set filters
    getAllPublicServices = () => {
        const servicesFilter = createServicesFilterUrl(this.state.topGroupFilter, this.state.subGroupFilter);
        getAllPublicServices(servicesFilter).then(services => this.setState({services: services}));
    };

    // Open modal when not logged in user wants to see service details
    handleShowServiceDetails = (serviceId) => {
        this.setState({selectedServiceId: serviceId}, this.toggleModal("loginModal"))
    };

    // Give user subgroup suggestions
    handleSearchSubGroup = (searchTerm) => {
        let servicesFilter = "";

        if (searchTerm.length === 0) {
            this.setState({subGroupFilter: []}, () => {
                this.getAllPublicServices();
            });
        } else servicesFilter = "?sub_service_name=" + searchTerm;

        // Query subgroups and give user suggestions
        getSuggestionsForSubGroupFilter(servicesFilter).then(options => {
            let subGroupFilter = [];
            options.map((option) =>
                subGroupFilter.push(option.id)
            );
            this.setState({options: options, subGroupFilter: subGroupFilter});
        });
    };

    // Get all services in new subgroup after selecting result
    handleSearchResultSelected = (selectedResult) => {
        if (selectedResult[0]) {
            this.setState({
                subGroupFilter: [selectedResult[0].id]
            }, () => this.getAllPublicServices())
        }
    };

    render() {
        const Services = ({services}) => (
            <>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light hopsti-table-th">
                    <tr>
                        <th/>
                        <th className="d-none d-md-table-cell">Lisaja</th>
                        <th className="d-none d-xl-table-cell">Lisatud</th>
                        <th>Teenus</th>
                        <th className="d-none d-lg-table-cell">Kirjeldus</th>
                        <th>Hind</th>
                        <th className="d-none d-md-table-cell">Tegevused</th>
                    </tr>
                    </thead>
                    <tbody>
                    {services.map((item, index) => (
                        <TableRow
                            isPublicView={true}
                            isOffer={false}
                            isUserService={false}
                            isService={true}
                            item={item}
                            handleMatchClick={() => this.props.handleMatchClick()}
                            removeServiceHandler={this.props.removeServiceHandler}
                            editServiceHandler={this.props.editServiceHandler}
                            serviceDetailHandler={this.props.serviceDetailHandler}
                            toggleModal={(modal, service) => this.toggleModal(modal, service)}
                        />
                    ))}
                    </tbody>
                </Table>
            </>
        );

        return (
            <>
                <IndexNavbar/>
                <div className="container-fluid mt-6 pb-5">
                    <div className="hopsti-services">
                        <div className="header-body text-center mb-4">
                            <Row className="justify-content-center">
                                <Col className="px-5" lg="6" md="8" xl="5">
                                    <h1 className="justify-content-center">
                                        Saadaval olevad teenused
                                    </h1>
                                </Col>
                            </Row>
                        </div>
                        <ServiceGroupsFilter
                            filterBy={this.state.topGroupFilter}
                            onFilterChange={e => {
                                this.setState({
                                    topGroupFilter: e,
                                    topGroupFilterString: e.join()
                                }, this.getAllPublicServices);
                            }}
                        />
                        <Row className="justify-content-center pb-4">
                            <Col md={3}>
                                <Typeahead
                                    id="hopsti-typehead"
                                    minLength={1}
                                    className="search-container"
                                    highlightOnlyResult
                                    labelKey={option => `${option.name} (${option.found_services} vastet)`}
                                    filterBy={['name', 'synonyms']}
                                    emptyLabel="Ei leidnud vastet"
                                    onInputChange={(searchTerm) => {
                                        this.handleSearchSubGroup(searchTerm)
                                    }}
                                    onChange={(selected) => this.handleSearchResultSelected(selected)}
                                    options={this.state.options}
                                    placeholder="Sisesta märksõna..."
                                />
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xl={10}>
                                <Services services={this.state.services}/>
                            </Col>
                        </Row>
                    </div>
                    <Modal
                        size="md"
                        isOpen={this.state.loginModal}
                        toggle={() => this.toggleModal("loginModal")}
                    >
                        <HopstiLogin selectedServiceID={this.state.selectedServiceId}/>
                    </Modal>
                </div>
                <AuthFooter/>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error
    };
};

export default connect(mapStateToProps)(HopstiPublicDasboard);
