// TODO move to external js file
import axios from "../axios";

export const getUserServices = async () => {
    return axios
        .get("user-services/")
        .then(response => {
            return response.data ? response.data : [];
        })
        .catch(err => {
            console.log(err);
        });
};

export const getPublicServices = async (filterBy) => {
    let servicesFilter = "";

    if (filterBy.length > 0) {
        servicesFilter += "?top_service_id=";
        let itemsToAdd = [];
        filterBy.forEach(function (element) {
            itemsToAdd.push(element);
        });
        servicesFilter += itemsToAdd.join(",");
    }

    return axios
        .get("/services/public/" + servicesFilter, {
            headers: {
                'Authorization': null
            }
        })
        .then(response => {
            if (response.data) {
                return response.data ? response.data : [];
            }
        })
        .catch(err => {
            console.log(err);
        });
};

export const getAllServices = async (filterBy, userId) => {
    let servicesFilter = "";

    if (filterBy.length > 0) {
        servicesFilter += "?top_service_id=";
        let itemsToAdd = [];
        filterBy.forEach(function (element) {
            itemsToAdd.push(element);
        });
        servicesFilter += itemsToAdd.join(",");
    }

    return axios
        .get("/services/auth/" + userId + "/" + servicesFilter, {asd: "asd"})
        .then(response => {
            if (response.data) {
                return response.data ? response.data : [];
            }
        })
        .catch(err => {
            console.log(err);
        });
};

export const getAllServicesWithSubFilter = async (subLevelId, userId) => {
    let servicesFilter = "";

    servicesFilter += "?sub_service_id=" + subLevelId;
    console.log("Sub filter id", subLevelId)
    return axios
        .get("/services/auth/" + userId + "/" + servicesFilter)
        .then(response => {
            if (response.data) {
                return response.data ? response.data : [];
            }
        })
        .catch(err => {
            console.log(err);
        });
};

export const getAllHelpRequestsWithSubFilter = async (subLevelId) => {
    let servicesFilter = "";

    servicesFilter += "?sub_service_id=" + subLevelId;

    return axios
        .get("/help-requests/auth/" + servicesFilter)
        .then(response => {
            if (response.data) {
                return response.data ? response.data : [];
            }
        })
        .catch(err => {
            console.log(err);
        });
};

export const getAllHelpRequests = async (filterBy = null) => {
    let helpRequestFilter = "";

    if (filterBy.length > 0) {
        helpRequestFilter += "?top_service_id=";
        let itemsToAdd = [];
        filterBy.forEach(function (element) {
            itemsToAdd.push(element);
        });
        helpRequestFilter += itemsToAdd.join(",");
    }

    return axios
        .get("help-requests/auth/" + helpRequestFilter, {asd: "asd"})
        .then(response => {
            if (response.data) {
                return response.data ? response.data : [];
            }
        })
        .catch(err => {
            console.log(err);
        });
};

export const getFavouriteHelpRequests = async () => {
    return axios
        .get("favorites")
        .then(resp => {
            if (resp.data.help_requests) {
                return axios
                    .get("help-requests/auth/?id=" + resp.data.help_requests.join(","))
                    .then(response => {
                        if (response.data) {
                            console.log(response.data);
                            return response.data ? response.data : [];
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                return []
            }
        });
};

export const getFavouriteServices = async (userId) => {
    return axios
        .get("favorites")
        .then(resp => {
            if (resp.data.services) {
                return axios
                    .get("services/auth/" + userId + " /? id = " + resp.data.services.join(", "))
                    .then(response => {
                        console.log(response.data);
                        if (response.data) {
                            return response.data ? response.data : [];
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else return []
        });
};

export const getUserHelpRequests = async (userId) => {
    return axios
        .get("help-requests/user/" + userId + "/")
        .then(response => {
            if (response.data) {
                return response.data ? response.data : [];
            }
        })
        .catch(err => {
            console.log(err);
        });
};

export const getAllServiceGroups = () => {
    return axios
        .get("/service-groups/", {asd: "asd"})
        .then(response => {
            if (response.data) {
                return response.data ? response.data : [];
            }
        })
        .catch(err => {
            console.log("getAllServiceGroups error: ", err);
        });
};

export const getServiceSubGroups = groupID => {
    return axios
        .get("service-sub-groups/" + groupID + "/", {asd: "asd"})
        .then(response => {
            if (response.data) {
                return response.data ? response.data : [];
            }
        })
        .catch(err => {
            console.log("getServiceSubGroups error: " + err);
        });
};

export const getServiceGroups = () => {
    axios
        .get("user-service-top-groups/")
        .then(response => {
            if (response.data) {
                const serviceGroups = response.data;
                this.setState({
                    serviceGroups: serviceGroups
                });
            }
        })
        .catch(err => {
            console.log(err);
            this.setState({
                loading: false
            });
        });
};

export const getServiceSubGroupsForStatistics = groupID => {
    return axios
        .get("service-sub-groups-for-statistics/" + groupID + "/", {asd: "asd"})
        .then(response => {
            if (response.data) {
                return response.data ? response.data : [];
            }
        })
        .catch(err => {
            console.log("getServiceSubGroups error: " + err);
        });
};

export const getAllServiceGroupsForStatistics = () => {
    return axios
        .get("/service-groups-for-statistics/", {asd: "asd"})
        .then(response => {
            if (response.data) {
                return response.data ? response.data : [];
            }
        })
        .catch(err => {
            console.log("getAllServiceGroups error: ", err);
        });
};

/**
 * Construct url for making services query with filter parameters.
 *
 * @param {array} topGroupFilter
 * @param {array} subGroupFilter
 * @public
 */
export const createServicesFilterUrl = (topGroupFilter, subGroupFilter) => {
    let servicesFilter = "";
    if (topGroupFilter.length > 0) {
        servicesFilter += "?top_service_id=";
        let itemsToAdd = [];
        let subServicesToAdd = [];

        // Top group filter
        topGroupFilter.forEach(function (element) {
            itemsToAdd.push(element);
        });

        // Sub group filter
        subGroupFilter.forEach(function (element) {
            subServicesToAdd.push(element);
        });

        servicesFilter += itemsToAdd.join(",");
        if (subGroupFilter.length > 0) {
            servicesFilter += "&sub_service_id=" + subServicesToAdd.join(",");
        }
    } else if (subGroupFilter.length > 0) {
        let subServicesToAdd = [];

        // Sub services filter
        subGroupFilter.forEach(function (element) {
            subServicesToAdd.push(element);
        });
        servicesFilter += "?sub_service_id=" + subServicesToAdd.join(",");
    }
    return servicesFilter
};

/**
 * Get suggestions for sub groups according to passed filters
 *
 * @param {string} servicesFilter
 * @public
 */
export const getSuggestionsForSubGroupFilter = (servicesFilter) => {
    // Query subgroups and give user suggestions
    return axios
        .get("/all-service-sub-groups-filter" + servicesFilter)
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log(err)
        });
};

/**
 * Queries all publicly available services with passed filters.
 *
 * @param {string} servicesFilter
 * @public
 */
export const getAllPublicServices = async (servicesFilter) => {
    return axios
        .get("/services/public" + servicesFilter)
        .then(response => {
            if (response.data) {
                return response.data;
            }
        })
        .catch(err => {
            console.log(err);
        });
};

export const postFileToServer = async (file, userId) => {
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("name", file.name);
    formdata.append("created_by", userId);
    return axios
        .post("/file-upload/", formdata)
        .then(response => {
            if (response.data) {
                return response.data.id;
            }

        })
        .catch(err => {
            console.log("Error on picture submit", err);
        });
};

export const deleteFileFromServer = async (id) => {
    return axios
        .delete("/files/delete/" + id + "/")
        .then(response => {
            if (response.data) {
                return response.data;
            }
        })
        .catch(err => {
            console.log("Error on picture submit", err);
        });
};

export const handleSearchSubGroup = async (searchTerm) => {
    return axios
        .get("/all-service-sub-groups-filter?sub_service_name=" + searchTerm)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.log(err)
        });
};

export const handleSearchHelpRequestSubGroup = async (searchTerm) => {
    return axios
        .get("/all-help-requests-sub-groups-filter?sub_service_name=" + searchTerm)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.log(err)
        });
};

export const getOffers = async (isServiceProvider, id, userId) => {
    let url = "offers/" + userId + "/";

    if (isServiceProvider && id) {
        url = "offers/" + userId + "/?service=" + id;
    }

    if (!isServiceProvider && id) {
        url = "offers/" + userId + "/?help=" + id;
    }

    return axios
        .get(url, {asd: "asd"})
        .then(response => {
            if (response.data) {
                return response.data;
            }
        })
        .catch(err => {
            console.log(err);
        })
};

export const getUnits = () => {
    return axios
        .get("unit/", {asd: "asd"})
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log("getUnits error: " + err);
        });
};

export const getServiceProviderDashboard = (props = null, filter = null, offerId = null, userId = null) => {
    getUserServices().then(services => {
        props.onGetSPServices(services);
    });

    getAllHelpRequests(filter).then(helpRequests => {
        props.onGetAllHelpRequests(helpRequests);
    });

    getOffers(true, offerId, userId).then(offers => {
        props.onGetOffers(offers)
    });
};

export const getPersonInNeedDashboard = (props = null, filter = null, offerId = null, userId = null) => {
    getUserHelpRequests(userId).then(helpRequests => {
        props.onGetPINHelpRequests(helpRequests);
    });

    getAllServices(filter, userId).then(services => {
        props.onGetAllServices(services);
    });

    getOffers(false, offerId, userId).then(offers => {
        props.onGetOffers(offers)
    });
};
