import React from "react";
import HelpRequestItem from "../HelpRequestItem/HelpRequestItem";
import { connect } from "react-redux";

class HopstiAddHelpRequest extends React.Component {
    render() {
        return (
            <HelpRequestItem
                handleHelpRequestAdded={this.props.handleHelpRequestAdded}
                handleCloseModal={this.props.handleCloseModal}
                userId={this.props.user.pk}
                isKovSession={this.props.isKovSession}
                userIdFromKov={this.props.userIdFromKov}
                serviceCategory={this.props.serviceCategory}
                serviceSubCategory={this.props.serviceSubCategory}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(HopstiAddHelpRequest);
