import React from "react";
import AvatarEditor from 'react-avatar-editor';
import {
    Button,
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap";
import axios from "../../axios";
import Slider from "nouislider";

class Avatar extends React.Component {
    state = {
        image: null,
        allowZoomOut: false,
        position: { x: 0.5, y: 0.5 },
        scale: 1,
        rotate: 0,
        borderRadius: 50,
        preview: null,
        width: 200,
        height: 200,
        slider1Value: "100.00"
    }

    componentDidMount = () => {
        console.log("This props avatar url", this.props.currentAvatar);
        const filePath = "https://hopsti.ee/" + this.props.currentAvatar.replace("/home/hopstiee/hopsti/", "");
        fetch(filePath)
            .then(res => res.blob()) // Gets the response and returns it as a blob
            .then(blob => {
                // Here's where you get access to the blob
                // And you can use it for whatever you want
                // Like calling ref().put(blob)

                // Here, I use it to make an image appear on the page
                let objectURL = URL.createObjectURL(blob);
                this.setState({ image: objectURL })
            });


        var slider1 = this.refs.slider1;
        Slider.create(slider1, {
            start: [1],
            connect: [true, false],
            step: 0.01,
            range: { min: 1, max: 2 }
        }).on(
            "update",
            function (values, handle) {
                this.setState({
                    slider1Value: values[0]
                }, this.handleScale(values[0]));
            }.bind(this)
        );
    }

    handleNewImage = e => {
        this.setState({ image: e.target.files[0] })
    }

    handleSave = data => {
        const img = this.editor.getImageScaledToCanvas().toDataURL("image/jpeg");
        var blobBin = atob(img.split(',')[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        var file = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });


        var formdata = new FormData();
        formdata.append("file", file, "newAvatar1.jpeg");


        const rect = this.editor.getCroppingRect();
        this.setState({
            preview: {
                img,
                rect,
                scale: this.state.scale,
                width: this.state.width,
                height: this.state.height,
                borderRadius: this.state.borderRadius,
            },
        })
        console.log("Logginf imgae", img);

        axios
            .post("/avatar-upload/sp/", formdata)
            .then(response => {
                console.log("Success, imgae send: ", response)
                this.props.toggleHandler(response.data.path)
            }).then(

            )
            .catch(err => {
                console.log("Error on picture submit", err)
            });

    }

    handleScale = e => {
        const scale = parseFloat(e)
        this.setState({ scale })
    }

    handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
        this.setState({ allowZoomOut })
    }

    rotateLeft = e => {
        e.preventDefault()

        this.setState({
            rotate: this.state.rotate - 90,
        })
    }

    rotateRight = e => {
        e.preventDefault()
        this.setState({
            rotate: this.state.rotate + 90,
        })
    }

    handleBorderRadius = e => {
        const borderRadius = parseInt(e.target.value)
        this.setState({ borderRadius })
    }

    handleXPosition = e => {
        const x = parseFloat(e.target.value)
        this.setState({ position: { ...this.state.position, x } })
    }

    handleYPosition = e => {
        const y = parseFloat(e.target.value)
        this.setState({ position: { ...this.state.position, y } })
    }

    handleWidth = e => {
        const width = parseInt(e.target.value)
        this.setState({ width })
    }

    handleHeight = e => {
        const height = parseInt(e.target.value)
        this.setState({ height })
    }

    logCallback(e) {
        // eslint-disable-next-line
        console.log('callback', e)
    }

    setEditorRef = editor => {
        if (editor) this.editor = editor
    }

    handlePositionChange = position => {
        this.setState({ position })
    }

    handleDrop = acceptedFiles => {
        this.setState({ image: acceptedFiles[0] })
    }

    render() {
        return (
            <>
                <Card className="bg-secondary border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <h2>
                            Muuda profiili pilti
                        </h2>

                        <div>
                            <AvatarEditor
                                ref={this.setEditorRef}
                                scale={parseFloat(this.state.scale)}
                                width={this.state.width}
                                height={this.state.height}
                                position={this.state.position}
                                onPositionChange={this.handlePositionChange}
                                rotate={parseFloat(this.state.rotate)}
                                borderRadius={this.state.width / (100 / this.state.borderRadius)}
                                onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
                                onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
                                onImageReady={this.logCallback.bind(this, 'onImageReady')}
                                image={this.state.image}
                                className="editor-canvas"
                            />
                        </div>
                        <div className="custom-file ml--1">
                            <input
                                className="custom-file-input"
                                id="customFileLang"
                                lang="en"
                                type="file"
                                name="newImage"
                                onChange={this.handleNewImage}
                            />
                            <label className="custom-file-label" htmlFor="customFileLang">
                                Vali pildi fail:
                            </label>
                        </div>
                        <br />
                        <label className="form-control-label mt-2">
                            Zoom:
                        </label>
                        <div className="input-slider-container ml-2">
                            <div className="input-slider" ref="slider1" />
                            <Row className="mt-3">
                                <Col xs="6">
                                    <span className="range-slider-value">
                                        {this.state.scale}
                                    </span>
                                </Col>
                            </Row>
                        </div>

                        <label className="form-control-label mr-2 mt-4">
                            Keera pilti:
                        </label>
                        <Button className="btn-sm" onClick={this.rotateLeft}>Vasakule</Button>
                        <Button className="btn-sm" onClick={this.rotateRight}>Paremale</Button>
                        <br />
                        <Button className="btn-md btn-hopsti-green mt-4" onClick={this.handleSave} value="Valmis" >Valmis</Button>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default Avatar;
