import React from "react";
import TableContainer from "../../Table/TableContainer/TableContainer";
import MyHelpRequestsTable from "../../Table/TableContainer/MyHelpRequestsTable";


class YourHelpRequests extends React.Component {

    render() {
        return (
            <TableContainer
                addHelpRequestHandler={this.props.addHelpRequestHandler}
            >
                <MyHelpRequestsTable
                    items={this.props.yourHelpRequests}
                    removeHandler={this.props.removeHelpRequestHandler}
                    editServiceHandler={this.props.editHelpRequestHandler}
                    handleMatchClick={this.props.handleMatchClick} />
            </TableContainer>
        );
    }
}

export default YourHelpRequests;