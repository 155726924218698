import React from "react";
import ReactGA from 'react-ga';

// reactstrap components
import {
    Alert,
    Button,
    Card,
    CardBody,
    Row,
    Col, Container, FormGroup, Input
} from "reactstrap";
import axios from "../../axios";
import classnames from "classnames";

class ResetPassword extends React.Component {
    state = {
        email: "",
        uid: this.props.match.params.uidb64,
        token: this.props.match.params.token,
        password: '',
        passwordState: null,
        confirmPassword: '',
        confirmPasswordState: null,
        renderPage: this.props.renderPage
    };

    componentDidMount() {
        ReactGA.initialize('UA-150997639-1');
        ReactGA.pageview('/forgot-password');
    }

    handleChange = async event => {
        const { target } = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const { name } = target;
        this.setState({
            [name]: value
        }, () => { this.validate() });
    };
    validate = () => {
        let allValid = true;

        let newState = { ...this.state };

        if (newState.password === "" || newState.password.length < 8) {
            newState.passwordState = "invalid";
            allValid = false;
        } else {
            newState.passwordState = "valid";
        }
        if (newState.confirmPassword === "") {
            newState.confirmPasswordState = "invalid";

        } else {
            newState.confirmPasswordState = "valid";
        }

        if (newState.password !== newState.confirmPassword) {
            allValid = false;
        }

        this.setState({ ...newState })
        return allValid;
    }

    handleSubmit = e => {
        e.preventDefault();

        if (!this.validate()) {
            this.setState({
                showSuccessMessage: false,
                showErrorMessage: true
            });
            return;
        }
        if (this.props.renderPage && this.props.renderPage === "change") {
            const data = {
                new_password1: this.state.password,
                new_password2: this.state.confirmPassword,

            }
            axios
                .post("/rest-auth/password/change/", data)
                .then(response => {
                    this.setState({
                        showSuccessMessage: true,
                        showErrorMessage: false
                    });
                })
                .catch(err => {
                    console.log("HopstiForgotPassword.js - " + err);
                    this.setState({
                        showErrorMessage: true,
                        showSuccessMessage: false
                    });
                });
        } else {
            const data = {
                new_password1: this.state.password,
                new_password2: this.state.confirmPassword,
                uidb: this.state.uid,
                token: this.state.token

            }
            axios
                .post("/password-reset-confirm/", data, {
                    headers: {
                        'Authorization': null
                    }
                })
                .then(response => {
                    this.setState({
                        showSuccessMessage: true,
                        showErrorMessage: false
                    });
                })
                .catch(err => {
                    console.log("HopstiForgotPassword.js - " + err);
                    this.setState({
                        showErrorMessage: true,
                        showSuccessMessage: false
                    });
                });
        }

    };

    render() {
        let successMessage = null;
        let errorMessage = null;

        if (this.state.showSuccessMessage) {
            successMessage = (
                <Alert color="success">
                    <strong>Super!</strong> {this.props.renderPage && this.props.renderPage === "change" ? "Parooli uuendamine õnnestus!" : "Uuendamine õnnestus! Proovi uuesti sisse logida"}
                </Alert>
            );
        }

        if (this.state.showErrorMessage) {
            errorMessage = (
                <Alert color="danger">
                    <strong>Viga!</strong> Tekkis viga, palun kontrolli sisestatud parooli!
                </Alert>
            );
        }
        return (
            <>
                <div className="main-content">
                    <Container className="mt-6 pb-5">
                        <Row className="card-wrapper justify-content-center">
                            <Col lg="6">
                                <Card>
                                    <CardBody>
                                        {successMessage}
                                        {errorMessage}
                                        <Row className="align-items-center pb-2">
                                            <div className="col">
                                                <h2 className="text-lg">
                                                    Salasõne uuendamine
                                        </h2>
                                            </div>
                                        </Row>
                                        <Row className="align-items-center">
                                            <Col className="col-6">
                                                <label className="form-control-label">Sisesta uus salasõna</label>
                                                <FormGroup
                                                    className={classnames({
                                                        focused: this.state.focusedPassword
                                                    })}
                                                >


                                                    <Input
                                                        placeholder="Salasõna"
                                                        type="password"
                                                        name="password"
                                                        value={this.state.password}
                                                        onFocus={() =>
                                                            this.setState({ focusedPassword: true })
                                                        }
                                                        onBlur={() =>
                                                            this.setState({ focusedPassword: false })
                                                        }
                                                        valid={
                                                            this.state.passwordState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.passwordState ===
                                                            "invalid"
                                                        }
                                                        onChange={e => this.handleChange(e)}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Palun sisesta salasõna. Pikkus vähemalt 8 märki!
                                                    </div>
                                                </FormGroup>
                                                <label className="form-control-label">Korda salasõna</label>
                                                <FormGroup
                                                    className={classnames({
                                                        focused: this.state.focusedConfirmPassword
                                                    })}
                                                >
                                                    <Input
                                                        placeholder="Korda salasõna"
                                                        type="Password"
                                                        name="confirmPassword"
                                                        value={this.state.confirmPassword}
                                                        onFocus={() =>
                                                            this.setState({ focusedConfirmPassword: true })
                                                        }
                                                        onBlur={() =>
                                                            this.setState({ focusedConfirmPassword: false })
                                                        }
                                                        valid={
                                                            this.state.confirmPasswordState === "valid"
                                                        }
                                                        invalid={
                                                            this.state.confirmPasswordState ===
                                                            "invalid"
                                                        }
                                                        onChange={e => this.handleChange(e)}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Palun korda Salasõna.
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center pb-2">
                                            <div className="col-6">
                                                <Button className="btn-hopsti-green" color="info" type="button" onClick={this.handleSubmit}>
                                                    Uuenda
                                        </Button>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default ResetPassword;
