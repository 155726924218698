import React from "react";
import {
    Col,
    Modal, Row,
} from "reactstrap";
import DetailView from "../../DetailView/DetailView";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {withTranslation} from 'react-i18next';

import axios from "../../../axios";
import {sortCaret, renderAvatar, renderFavorite} from "./Helpers/TableUtil"


// React bootstrap table 2
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
// import overlayFactory from "react-bootstrap-table2-overlay";


class AllHelpRequestsTable extends React.Component {

    state = {
        isFavourite: false,
        serviceModal: false,
        isLoading: true,
        data: []
    };

    componentDidMount() {
        this.setState({
            data: this.props.items ? this.props.items : []
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.items !== this.props.items) {
            this.setState({
                data: this.props.items ? this.props.items : []
            })
        }
    }

    handleFavouriteClicked = async (helpRequest) => {
        let url = "/favorites/help-request/" + helpRequest.id + "/";
        this.setState({isFavourite: !this.state.isFavourite});
        return axios
            .post(url)
            .then((response) => {
                console.log(response)
            })
            .catch((err) => {
                console.log(err)
            })

    };

    toggleModal = (state, service) => {
        this.setState({
            [state]: !this.state.serviceModal,
            selectedService: service
        });
    };

    render() {
        const renderButtons = (cell, row) => {
            return (
                <div className="d-flex align-items-center">
                    <i className="fa fa-2x fa-search hopsti-pink pl-2 pr-2 clickable"
                       onClick={() => this.toggleModal("serviceModal", row)}
                       title={this.props.t('help-request-table.actions.detail-view')}
                       aria-hidden="true"/>
                </div>
            );
        };

        const renderNeed = (cell, row) => {
            return (
                row.quantity + " " + row.unit.unit
            );
        };

        const helpRequestFilter = textFilter({
            placeholder: this.props.t('help-request-table.headers.search-for-help-request'),  // custom the input placeholder
            className: 'form-control-sm mt-2', // custom classname on input
            style: {}, // your custom styles on input
        });

        const descriptionFilter = textFilter({
            placeholder: this.props.t('help-request-table.headers.search-for-description'),  // custom the input placeholder
            className: 'form-control-sm mt-2', // custom classname on input
            style: {}, // your custom styles on input
        });

        const columns = [{
            dataField: 'is_favorite',
            text: '',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell align-text-top',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.handleFavouriteClicked(row).then(() => {
                        let newData = [...this.state.data];
                        newData = newData.map((d) => {
                            if (d.id === row.id) {
                                return {
                                    ...d,
                                    is_favorite: !d.is_favorite
                                };
                            }
                            return d;
                        });
                        this.setState(state => ({...state, data: newData}));
                    });
                }
            },
            formatter: renderFavorite,
            headerStyle: (column, colIndex) => {
                return {width: '50px', textAlign: 'center'};
            }
        }, {
            dataField: 'avatar',
            text: '',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            },
            formatter: renderAvatar,
            headerStyle: (column, colIndex) => {
                return {width: '70px', textAlign: 'center'};
            }
        }, {
            dataField: 'user.first_name',
            text: this.props.t('help-request-table.headers.added_by'),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell align-text-top',
            headerStyle: (column, colIndex) => {
                return {width: '100px'};
            },
            sort: true,
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'created_at',
            text: this.props.t('help-request-table.headers.added-date'),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell align-text-top',
            headerStyle: (column, colIndex) => {
                return {width: '100px'};
            },
            sort: true,
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'title',
            text: this.props.t('help-request-table.headers.title'),
            sort: true,
            filter: helpRequestFilter,
            classes: 'clickable hopsti-pink',
            sortCaret: (order, column) => sortCaret(order, column),
            headerStyle: (column, colIndex) => {
                return {width: '200px'};
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'info',
            text: this.props.t('help-request-table.headers.description'),
            sort: true,
            filter: descriptionFilter,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell align-text-top',
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            },
        }, {
            dataField: 'unit.id',
            text: this.props.t('help-request-table.headers.need'),
            classes: 'clickable',
            headerClasses: 'align-text-top',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            formatter: renderNeed,
            headerStyle: (column, colIndex) => {
                return {width: '90px'};
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'google_response.distance.distance.text',
            text: this.props.t('help-request-table.headers.distance'),
            classes: 'clickable',
            headerClasses: 'align-text-top',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            headerStyle: (column, colIndex) => {
                return {width: '90px'};
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: '',
            text: this.props.t('help-request-table.headers.buttons'),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell align-text-top',
            formatter: renderButtons,
            headerStyle: (column, colIndex) => {
                return {width: '90px'};
            },
        }];

        const NoDataIndication = () =>
            <div className="spinner pt-4 pb-4">
                {!this.props.items ? <i className="fas fa-3x fa-spinner fa-pulse hopsti-green"/> :
                    <span className="hopsti-pink">{this.props.t('help-request-table.no-data')}</span>}
            </div>;

        return (
            <>
                <Row className="justify-content-center pt-2 pb-2">
                    <Col className="col-12">
                        <BootstrapTable
                            striped
                            hover
                            bordered={false}
                            wrapperClasses="table-responsive"
                            keyField='id'
                            data={this.state.data}
                            columns={columns}
                            filter={filterFactory()}
                            // filterPosition="bottom"
                            pagination={paginationFactory({hideSizePerPage: true})}
                            noDataIndication={() => <NoDataIndication/>}
                            loading={!this.props.items}
                            // overlay={overlayFactory()}
                        />
                    </Col>
                </Row>
                <Modal
                    size="lg"
                    className={this.state.selectedService && this.state.selectedService.files && this.state.selectedService.files.length > 0 ? "modal-dialog-centered matches-modal" : "modal-dialog-centered matches-modal-sm"}
                    isOpen={!!(this.state.serviceModal && this.state.selectedService)}
                    toggle={() => this.toggleModal("serviceModal")}
                >
                    <DetailView isService={false} service={this.state.selectedService}/>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        user: state.auth.user
    };
};

export default withRouter(connect(mapStateToProps, null)(withTranslation('common')(AllHelpRequestsTable)));