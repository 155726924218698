import React from "react";

export const notify = (type, title, message, currentScope) => {
    let options = {
        place: "tc",
        message: (
            <div className="alert-text">
                <span className="alert-title" data-notify="title">
                    {" "}
                    {title}
                </span>
                <span data-notify="message">{message}</span>
            </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 5
    };
    currentScope.refs.notificationAlert.notificationAlert(options);
};