import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    pinHelpRequests: null,
    allHelpRequests: null,
};

const setPINHelpRequests = (state, action) => {
    return updateObject(state, {
        pinHelpRequests: action.helpRequests
    })
};

const setAllHelpRequests = (state, action) => {
    return updateObject(state, {
        allHelpRequests: action.helpRequests
    })
};

const helpRequests = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PIN_HELP_REQUESTS:
            return setPINHelpRequests(state, action);
        case actionTypes.GET_ALL_HELP_REQUESTS:
            return setAllHelpRequests(state, action);
        default:
            return state;
    }
};

export default helpRequests;