import axios from "../../../axios";

export const getHelpRequests = async () => {
    return axios
        .get("kov-help-requests/")
        .then(response => {
            return response.data ? response.data : [];
        })
        .catch(err => {
            console.log(err);
        });
};

export const getServices = async () => {
    return axios
        .get("kov-services/")
        .then(response => {
            return response.data ? response.data : [];
        })
        .catch(err => {
            console.log(err);
        });
};