import React from "react";
import {
    Badge,
    Col,
    Modal, Row,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {withTranslation} from 'react-i18next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from "../../../axios";
import { sortCaret } from "./Helpers/TableUtil"
import OfferDetailView from "../../DetailView/OfferDetailView";
// import overlayFactory from "react-bootstrap-table2-overlay";


class OffersTableServiceProvider extends React.Component {
    state = {
        serviceModal: false,
        data: []
    };

    componentDidMount() {
        this.setState({
            data: this.props.items ? this.props.items : []
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.items !== this.props.items) {
            this.setState({
                data: this.props.items ? this.props.items : []
            })
        }
    }

    closeModalUpdateOffers = async (state) => {
        this.setState({
            [state]: !this.state[state]
        });
        this.props.getOffers()
    };

    getOfferDetails = async (offerId) => {
        return axios
            .get("offers/" + offerId + "/" + this.props.user.pk + "/")
            .then(response => {
                if (response.data) {
                    this.setState({
                        selectedOffer: response.data
                    });
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    toggleModal = async (state, offer) => {
        if (offer) await this.getOfferDetails(offer.offer_id);
        this.setState({
            [state]: !this.state.serviceModal
        });
    };

    offerSent = (offerId) => {
        axios
            .put('/offers/update/' + offerId + "/" + this.props.user.pk + "/", {
                "status": { "id": 1 },
                "is_help_request_agreement": false,
                "is_service_agreement": false,
            })
            .then(() => {
                this.props.showNotificationHandler(1);
                this.props.getOffers(true)
            })
            .catch(err => {
                console.log(err);
            })
    };

    render() {
        const renderButtons = (cell, row) => {
            return (
                <div className="d-flex align-items-center">
                    <i className="fa fa-2x fa-search hopsti-pink pl-2 pr-2 clickable"
                        onClick={() => this.toggleModal("serviceModal", row)}
                        title="Vaata detailvaadet"
                        aria-hidden="true" />
                    {row.service_status === "Esitamata" ?
                        <Badge onClick={() => this.offerSent(row.offer_id)}
                            className="badge badge-md pill btn-hopsti-pink clickable ml-2">{this.props.t('offer-detail.send-offer')}</Badge> : null
                    }
                </div>
            );
        };

        const renderNeed = (cell, row) => {
            return (
                row.help_request_quantity + " " + row.help_request_unit
            );
        };

        const columns = [{
            dataField: 'service_name',
            text: this.props.t('offer-table.headers.your-service'),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'help_request_name',
            text: this.props.t('offer-table.headers.help-request'),
            classes: 'clickable hopsti-pink',
            sort: true,
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'created_at',
            text: this.props.t('offer-table.headers.added-date'),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            headerStyle: (column, colIndex) => {
                return { width: '100px' };
            },
            sort: true,
            sortCaret: (order) => sortCaret(order),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'help_request_quantity',
            text: this.props.t('offer-table.headers.need'),
            sort: true,
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            sortCaret: (order) => sortCaret(order),
            formatter: renderNeed,
            headerStyle: (column, colIndex) => {
                return { width: '100px' };
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: 'service_status',
            text: this.props.t('offer-table.headers.status'),
            sort: true,
            classes: 'clickable',
            sortCaret: (order) => sortCaret(order),
            headerStyle: (column, colIndex) => {
                return { width: '90px' };
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.toggleModal("serviceModal", row)
                }
            }
        }, {
            dataField: '',
            text: this.props.t('offer-table.headers.buttons'),
            classes: 'clickable d-none d-md-table-cell',
            headerClasses: 'd-none d-md-table-cell',
            formatter: renderButtons,
            headerStyle: (column, colIndex) => {
                return { width: '200px' };
            },
        }];

        const NoDataIndication = () =>
            <div className="spinner pt-4 pb-4">
                {!this.props.items ? <i className="fas fa-3x fa-spinner fa-pulse hopsti-green"/> :
                    <span className="hopsti-pink">{this.props.t('offer-table.person-in-need.no-data')}</span>}
            </div>;

        return (
            <>
                <Row className="justify-content-center pt-2 pb-2">
                    <Col className="col-12">
                        <BootstrapTable
                            striped
                            hover
                            bordered={false}
                            wrapperClasses="table-responsive"
                            keyField='offer_id'
                            data={this.state.data}
                            columns={columns}
                            pagination={paginationFactory({ hideSizePerPage: true })}
                            noDataIndication={() => <NoDataIndication />}
                            loading={!this.props.items}
                            // overlay={overlayFactory()}
                        />
                    </Col>
                </Row>
                <Modal
                    size="lg"
                    className="modal-dialog-centered matches-modal"
                    isOpen={this.state.serviceModal && this.state.selectedOffer !== null}
                    toggle={() => this.toggleModal("serviceModal")}
                >
                    <OfferDetailView isService={true}
                        service={this.state.selectedOffer}
                        isAutomaticOffer={true}
                        isServiceProvider={true}
                        closeModalHandler={(id) => {
                            this.closeModalUpdateOffers("serviceModal");
                            this.props.showNotificationHandler(id)
                        }}
                        userId={this.props.user.pk}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        user: state.auth.user
    };
};

export default withRouter(connect(mapStateToProps, null)(withTranslation('common')(OffersTableServiceProvider)));